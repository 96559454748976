import React, { type CSSProperties } from 'react';
import styles from './LabelText.module.css';

export interface LabelProps {
	children: React.ReactNode;
	style?: CSSProperties;
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
	return (
		<b className={styles.label} style={props.style ?? {}}>
			{props.children}
		</b>
	);
};

export default Label;
