import React from 'react';
import styles from './index.module.css';
import { type CourseOfferred, type SessionType, type UserProfile } from '../../../types';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../../Common/ContentHeader';
import { ProfileLogo } from '../../Common/Icons/ProfileLogo';
import { Theme } from '../../Common/Toggle';
import { FormatString } from '../../../utils/strings';

export interface CourseSelectedProps {
	course?: CourseOfferred;
	onClick?: (sessionType: SessionType) => void;
	theme: Theme;
	profile: UserProfile;
}

export const CourseSelected: React.FC<CourseSelectedProps> = (props: CourseSelectedProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.courseSelectedContainer}>
		<ContentHeader style={{ maxWidth: '850px', width: '100%' }} title={props.course?.description ?? ''} message={props.course?.title ?? ''}>
			<div className={styles.botChatBubble}>
				<div className={styles.bubbleTitleContainer}>
					<ProfileLogo className={styles.accountcircleoutlinedIcon} fillColor={'var(--ion-color-primary)'}/>
					<b className={styles.profAi}>PROF AI</b>
				</div>
				<div className={styles.theHypothesisDrivenModelContainer}>
					<p className={styles.alrightEdHow}>
						{FormatString('Alright {0}, how do you want to interact today? ', props.profile.nickname ??'')}
					</p>
					<p className={styles.alrightEdHow}>
						<b className={styles.askProfai}>{'Ask ProfAI '}</b>
						<span>{'let’s you discuss course content. '}</span>
					</p>
					<p className={styles.alrightEdHow}>
						<b className={styles.askProfai}>Course Quiz</b>
						<span> asks you 10 questions to test your knowledge about the course.</span>
					</p>
					<p className={styles.alrightEdHow}>
						<b className={styles.askProfai}>Project Assistant</b>
						<span>{' walks you through completing your final project. '}</span>
					</p>
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<ButtonWithIcons
					style={props.theme === Theme.DARK ?{border: '1px solid var(--nav-header-button-border-color)',  width: '128px'}:{}}
					type={props.theme === Theme.DARK ? ButtonType.action : ButtonType.tertiary}
					text={t('Ask PROFAI')}
					onClick={()=>props.onClick?.('ask_profai')}
				/>
				<ButtonWithIcons
					style={props.theme === Theme.DARK ?{border: '1px solid var(--nav-header-button-border-color)',  width: '138px'}:{}}
					type={props.theme === Theme.DARK ? ButtonType.action : ButtonType.tertiary}

					text={t('Course Quiz')}
					onClick={()=>props.onClick?.('quiz')}
				/>
				<ButtonWithIcons
					style={props.theme === Theme.DARK ?{border: '1px solid var(--nav-header-button-border-color)',  width: '190px'}:{}}
					type={props.theme === Theme.DARK ? ButtonType.action : ButtonType.tertiary}
					text={t('Project Assistant')}
					onClick={()=>props.onClick?.('project_assistant')}
				/>
			</div>
		</ContentHeader>
		</div>
	);
};
