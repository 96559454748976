import { type ChatMessage, ChatSource, type ProfileElementType } from '../types';

export type ProfileQAChatMessage = ChatMessage & {
	type: ProfileElementType;
};
export const ProfileQATemplate: ProfileQAChatMessage[] = [
	{
		messageId: '1',
		type: 'nickname',
		messageSource: ChatSource.Assistant,
		message: 'Great, should I call you {0}, or do you prefer to go by a different name?',
	},
	{
		messageId: '2',
		messageSource: ChatSource.Assistant,
		type: 'title',
		message: 'Great, what is your current job? If you’re unemployed or a student, tell me that here.',
	},

	{
		messageId: '3',
		type: 'employmentStatus',
		messageSource: ChatSource.Assistant,
		message: 'What company do you work for? If you’re self-employed, feel free to mention that here.',
	},
	{
		messageId: '4',
		type: 'communicationStyle',
		messageSource: ChatSource.Assistant,
		message:
			'How would you describe your communication style in professional settings? (Options: Formal, Informal, Direct, Indirect, Terse, Elaborate, etc.)',
	},
];
