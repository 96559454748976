import React, { type CSSProperties } from 'react';
import styles from './index.module.css';
import { CheckmarkIcon } from '../Icons/CheckmarkIcon';

export interface ContentHeaderProps {
	title: string;
	message: string | React.ReactNode;
	icon?: React.ReactNode | undefined | 'default';
	children?: React.ReactNode;
	style?: CSSProperties;
	border?: boolean;
}

export const ContentHeader: React.FC<ContentHeaderProps> = (props: ContentHeaderProps) => {
	const border = props.border ?? false;
	return (
		<div
			className={`${styles.widgetContainer} ${border ? styles.widgetContainerBorder : ''}`}
			style={props.style ?? {}}
		>
			<div className={styles.topContent}>
				<div className={styles.sideline} />
				<div className={styles.textContainer}>
					<div className={styles.heading}>
						{typeof props.icon !== 'undefined' && (
							<>
								{props.icon === 'default' && (
									<CheckmarkIcon className={styles.iconCredentialsWhite1} fillColor={'var(--content-header-default-icon-fill)'}/>
								)}
								{props.icon !== 'default' && <>{props.icon}</>}
							</>
						)}
						<div className={styles.title}>{props.title}</div>
					</div>
					<div className={styles.message}>{props.message}</div>
				</div>
			</div>
			{props.children}
		</div>
	);
};
