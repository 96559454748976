/* eslint-disable @typescript-eslint/ban-types */
export interface Registry {
	unregister: () => void;
}


export type Callable = Record<string, Function>;

export type Subscriber = Record<string, Callable>;

export interface IEventBus {
	dispatch: <T>(event: string, arg?: T) => void;
	register: (event: string, callback: Function) => Registry;
}

export class EventBus implements IEventBus {
	private subscribers: Subscriber;
	private static nextId = 0;
	private static instance?: EventBus = undefined;

	private constructor() {
		this.subscribers = {};
	}

	public static getInstance(): EventBus {
		if (this.instance === undefined) {
			this.instance = new EventBus();
		}

		return this.instance;
	}

	public dispatch<T>(event: string, arg?: T): void {
		const subscriber = this.subscribers[event];

		if (subscriber === undefined) {
			return;
		}

		Object.keys(subscriber).forEach((key) => subscriber[key](arg));
	}

	public register(event: string, callback: Function): Registry {
		const id = this.getNextId();
		if (this.subscribers[event] === undefined) this.subscribers[event] = {};

		this.subscribers[event][id] = callback;

		return {
			unregister: () => {
				const eventHandlers = this.subscribers[event];
				if (eventHandlers !== undefined) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete eventHandlers[id];
				}
				if (Object.keys(eventHandlers).length === 0)
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete this.subscribers[event];
			},
		};
	}

	private getNextId(): number {
		return EventBus.nextId++;
	}
}

/* eslint-enable @typescript-eslint/ban-types */