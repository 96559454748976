import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { UpArrowButton } from '../../UpArrowButton';
import { ProfileLogo } from '../../Icons/ProfileLogo';

export interface ChatInputProps {
	onClick: (input: string) => void;
	disabled?: boolean;
	userInput?: string;
}

const ChatInput: React.FC<ChatInputProps> = (props: ChatInputProps) => {
	const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
	const [userInput, setUserInput] = useState(props.userInput ?? '');
	
	const disabled = props.disabled ?? false;

	useEffect(() => {
		setButtonEnabled(userInput.trim().length > 0);
	}, [userInput]);



	return (
		<div className={styles.chatInput} aria-disabled={disabled}>
			<div className={styles.accountcircleoutlinedParent}>
				<ProfileLogo className={styles.accountcircleoutlinedIcon} fillColor={'var(--ion-color-primary)'}/>
				<input
					disabled={disabled}
					type="text"
					className={styles.yourAnswer}
					placeholder={'Your answer..'}
					value={userInput}
					onChange={(event) => {
						setUserInput(event.target.value);
					}}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
						if (buttonEnabled && event.key === 'Enter') {
							props.onClick?.(userInput);
							setUserInput('');
						}
					}}
				/>
				<UpArrowButton
					enabled={buttonEnabled}
					onClick={() => {
						props.onClick?.(userInput);
						setUserInput('');
					}}
				/>
			</div>
		</div>
	);
};

export default ChatInput;
