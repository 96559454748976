import React from 'react';
import { type IconProps } from './types';

export const CopyIcon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="14"
			height="16"
			viewBox="0 0 14 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<path
				d="M4.54804 12.5833C4.17293 12.5833 3.85587 12.4538 3.59685 12.1948C3.33783 11.9357 3.20831 11.6187 3.20831 11.2436V1.75644C3.20831 1.38133 3.33783 1.06426 3.59685 0.805229C3.85587 0.546201 4.17293 0.416687 4.54804 0.416687H12.0352C12.4103 0.416687 12.7273 0.546201 12.9864 0.805229C13.2454 1.06426 13.3749 1.38133 13.3749 1.75644V11.2436C13.3749 11.6187 13.2454 11.9357 12.9864 12.1948C12.7273 12.4538 12.4103 12.5833 12.0352 12.5833H4.54804ZM4.54804 11.5H12.0352C12.0993 11.5 12.158 11.4733 12.2115 11.4199C12.2649 11.3664 12.2916 11.3077 12.2916 11.2436V1.75644C12.2916 1.69233 12.2649 1.63356 12.2115 1.58013C12.158 1.52671 12.0993 1.5 12.0352 1.5H4.54804C4.48393 1.5 4.42517 1.52671 4.37175 1.58013C4.31832 1.63356 4.2916 1.69233 4.2916 1.75644V11.2436C4.2916 11.3077 4.31832 11.3664 4.37175 11.4199C4.42517 11.4733 4.48393 11.5 4.54804 11.5ZM1.96473 15.1666C1.58963 15.1666 1.27257 15.0371 1.01354 14.7781C0.754514 14.519 0.625 14.202 0.625 13.8269V3.25644H1.70831V13.8269C1.70831 13.891 1.73502 13.9497 1.78844 14.0032C1.84187 14.0566 1.90063 14.0833 1.96473 14.0833H10.5352V15.1666H1.96473Z"
				fill={props.fillColor ?? 'white'}
			/>
		</svg>
	);
};
