/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { IonApp, IonButton, IonContent, IonPage, IonRouterOutlet, setupIonicReact } from '@ionic/react'; /* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'; /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css'; /* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'; /* Theme variables */
import './theme/variables.css';
import './index.css';
import { TestPage } from './pages/TestPage';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';
import { HomePage } from './pages/Home';
import { NotFoundPage } from './pages/NotFoundPage';
import { UserOnboardingState, useStore } from './store/profAiStore';
import { Theme } from './components/Common/Toggle';
import { FeedbackFish } from '@feedback-fish/react';
import { configureAmplify } from './utils/authUtils';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './connector/section/apolloClient';
import useMembershipAuthHandler from './hooks/useMembershipAuthHandler';
import { buildLoginRedirectRoute } from './utils/routeUtils';

setupIonicReact();

export const AppRoutes = {
	home: '/home',
	test: '/test',
	profile: '/profile',
	newSession: '/session/new',
	continueSession: '/session/continue',
	tos: '/tos',
	root: '/',
};

export interface AppProps {
	container: HTMLElement | null;
}

configureAmplify();

export const App: React.FC<AppProps> = (props: AppProps) => {
	const onboardingState = useStore((state) => state.onboardingState);
	const setOnboardingState = useStore((state) => state.setOnboardingState);
	const theme = useStore((state) => state.theme);
	const { data, loading } = useMembershipAuthHandler(false);

	useEffect(() => {
		if (theme === Theme.DARK) {
			props.container?.classList.add('dark');
		} else {
			props.container?.classList.remove('dark');
		}
	}, [theme]);

	useEffect(() => {
		if (!loading) {
			if (data?.getMember !== null) {
				if (onboardingState === UserOnboardingState.RESOLVING) {
					setOnboardingState(UserOnboardingState.FTU);
				}
				/* 
				The follow code resets the onboarding state when the user closes the browser, when they
				just entered the profile screen, wherein, in the past the user profile was complete.
				 */
				if (onboardingState === UserOnboardingState.FTU_COMPLETE) {
					setOnboardingState(UserOnboardingState.ONBOARDED);
				}
				return;
			}

			window.location.href = buildLoginRedirectRoute(window.location.href);
		}
	}, [data, loading, onboardingState]);

	return (
		<ApolloProvider client={apolloClient}>
			<IonApp>
				<IonPage>
					<IonContent fullscreen>
						<IonReactRouter>
							<IonRouterOutlet>
								<Route exact path={AppRoutes.test} component={TestPage} />
								<Route
									exact
									path={AppRoutes.home}
									render={(props) => (
										<HomePage
											showProfile={false}
											continueSession={false}
											tos={false}
										/>
									)}
								/>
								<Route
									exact
									path={AppRoutes.profile}
									render={(props) => <HomePage {...props} showProfile={true} />}
								/>
								<Route
									exact
									path={AppRoutes.newSession}
									render={(props) => <HomePage {...props} newSession={true} />}
								/>
								<Route
									exact
									path={AppRoutes.continueSession}
									render={(props) => (
										<HomePage {...props} continueSession={true} />
									)}
								/>
								<Route
									exact
									path={AppRoutes.tos}
									render={(props) => <HomePage {...props} tos={true} />}
								/>
								<Redirect exact from={AppRoutes.root} to="/home" />
								<Route component={NotFoundPage} />
							</IonRouterOutlet>
						</IonReactRouter>
						<FeedbackFish projectId="f6a112ba994a5e">
							<IonButton
								style={{ position: 'absolute', bottom: '5px', right: '4px' }}
							>
								Send feedback
							</IonButton>
						</FeedbackFish>
					</IonContent>
				</IonPage>
			</IonApp>
		</ApolloProvider>
	);
};
