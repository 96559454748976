import React, { type CSSProperties, type PropsWithChildren} from 'react';
import styles from './index.module.css';
import AddIcon from '../../../assets/add-filled.svg?react';
import { ProfileLogo } from '../Icons/ProfileLogo';

export enum ButtonIconTypes {
	None,
	IconTypeAdd,
	IconTypeProfile,
}

export enum ButtonType {
	primary,
	secondary,
	tertiary,
	action,
	textOnly,
}

export interface ButtonWithIconsProps {
	iconLeft?: ButtonIconTypes |  React.ReactNode;
	iconRight?: ButtonIconTypes | React.ReactNode;
	text?: string;
	type?: ButtonType;
	style?: CSSProperties;
	onClick?: () => void;
	labelClassName?: string;
}

export const ButtonWithIcons: React.FC<PropsWithChildren<ButtonWithIconsProps>> = (
	props: PropsWithChildren<ButtonWithIconsProps>,
) => {
	const iconLeft = props.iconLeft ?? ButtonIconTypes.None;
	const iconRight = props.iconRight ?? ButtonIconTypes.None;
	const buttonType = props.type ?? ButtonType.primary;

	const typeToIcon = (iconType: ButtonIconTypes | React.ReactNode): React.ReactNode => {
		if (React.isValidElement(iconType)){
			return iconType;
		}
		switch (iconType) {
			case ButtonIconTypes.IconTypeProfile:
				return <ProfileLogo className="icon" fillColor={fillClassForButtonType(buttonType)} />;
			case ButtonIconTypes.IconTypeAdd:
				return <AddIcon className="icon" fill={fillClassForButtonType(buttonType)} />;
			default:
				return null;
		}
	};

	const classForButtonType = (type: ButtonType): string => {
		switch (type) {
			case ButtonType.secondary:
				return `${styles.secondaryColor} ${styles.secondaryBorder}`;
			case ButtonType.tertiary:
				return `${styles.tertiaryColor} ${styles.tertiaryBorder}`;
			case ButtonType.action:
				return `${styles.actionColor} ${styles.actionBorder}`;
			case ButtonType.textOnly:
				return `${styles.textOnlyColor} ${styles.textOnlyBorder}`;
			default:
				return `${styles.primaryColor} ${styles.primaryBorder}`;
		}
	};

	const textClassForButtonType = (type: ButtonType): string => {
		switch (type) {
			case ButtonType.secondary:
				return styles.primaryTextColor;
			case ButtonType.tertiary:
				return styles.primaryTextColor;
			case ButtonType.action:
				return styles.actionTextColor;
			case ButtonType.textOnly:
				return styles.textOnlyTextColor;
			default:
				return styles.primaryTextColor;
		}
	};

	const fillClassForButtonType = (type: ButtonType): string => {
		switch (type) {
			case ButtonType.secondary:
				return 'var(--text-primary)';
			case ButtonType.tertiary:
				return 'var(--text-primary)';
			case ButtonType.action:
				return 'var(--text-action)';
			case ButtonType.textOnly:
				return 'var(--text-primary)';
			default:
				return 'var(--text-primary)';
		}
	};

	const IconLeftFromType = typeToIcon(iconLeft);
	const IconRightFromType = typeToIcon(iconRight);
	return (
		<div
			className={`${styles.buttonContainer} ${classForButtonType(
				buttonType,
			)} ${textClassForButtonType(buttonType)}`}
			style={props.style ?? {}}
			onClick={(event) => {
				event.preventDefault();
				if (typeof props.onClick !== 'undefined') {
					props.onClick();
				}
			}}
		>
			{iconLeft !== ButtonIconTypes.None && (
				<div className={styles.iconContainer}>
					<>{IconLeftFromType}</>
				</div>
			)}
			{typeof props.text !== 'undefined' && <b className={`${styles.label} ${props.labelClassName ?? ''}`}>{props.text}</b>}
			{props.children}
			{iconRight !== ButtonIconTypes.None && (
				<div className={styles.iconContainer}>
					<>{IconRightFromType}</>
				</div>
			)}
		</div>
	);
};
