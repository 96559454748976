import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './i18n/config';



const container = document.getElementById('root');
if (container != null) {
	const root = createRoot(container);
	root.render(<App container={container}/>);
}
