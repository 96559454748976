import { ListPane } from '../../Common/ListPane';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import {
	type ChatSession,
	type InSessionQuizSummaryEntry,
	type QuizQuestion,
} from '../../../types';
import { QuizPaneEntry } from './QuizPaneEntry';
import React, { useEffect, useState } from 'react';
import { FormatString } from '../../../utils/strings';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { HeaderIconJustification } from '../../Common/ListPane/Header';
import { Virtuoso } from 'react-virtuoso';

export interface QuizProgressPaneProps {
	toggleEnabled?: boolean;
	paneExpanded: boolean;
	onPaneExpanded: (expanded: boolean) => void;
	session: ChatSession;
	onSaveAndQuitClicked: () => void;
}

export const QuizProgressPane: React.FC<QuizProgressPaneProps> = (props: QuizProgressPaneProps) => {
	const { t } = useTranslation();
	const [header, setHeader] = useState('');
	const [entries, setEntries] = useState<InSessionQuizSummaryEntry[]>([]);

	useEffect(() => {
		const format = t('quiz_progress');
		setHeader(
			FormatString(
				format,
				`${props.session.quizProgress?.correct ?? 0}`,
				`${props.session.quizProgress?.total ?? 0}`,
			),
		);
		if (props.session.quizSummary !== undefined && props.session.quizProgress !== undefined) {
			setEntries(props.session.quizSummary.slice(0, props.session.quizProgress.maxSeen));
		}
	}, [props.session]);
	return (
		<ListPane
			title={header}
			expanded={props.paneExpanded}
			onToggle={(expanded) => {
				props.onPaneExpanded(expanded);
			}}
			headerIconJustification={HeaderIconJustification.Right}
			toggleEnabled={props.toggleEnabled}
		>
			<Virtuoso
				className={`${styles.content} ion-content-scroll-host`}
				totalCount={entries.length}
				followOutput={(isAtBottom: boolean) => {
					if (isAtBottom) {
						return false;
					}
					return 'auto';
				}}
				itemContent={(index) => {
					const entry = entries[index];
					let questionEntry: QuizQuestion = { question: '', answer: undefined };
					if (
						props.session.quizQuestions !== undefined &&
						props.session.quizQuestions?.length > index
					) {
						questionEntry = props.session.quizQuestions[index];
					}
					return (
						<QuizPaneEntry
							key={`qa_${index}`}
							question={questionEntry.question}
							correct={entry.correct}
							answer={entry.userProvidedAnswer}
						/>
					);
				}}
			/>

			<div className={styles.bottomButtonContainer}>
				<ButtonWithIcons
					type={ButtonType.tertiary}
					text={t('save_and_quit')}
					onClick={props.onSaveAndQuitClicked}
				/>
			</div>
		</ListPane>
	);
};
