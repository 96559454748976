import { format as dateFnsFormat } from 'date-fns-tz';
import { parseISO, startOfDay } from 'date-fns';

// date-fns formats: https://date-fns.org/v2.17.0/docs/format
export const MONTH_NAME_SHORT = 'MMM'; // Jul
export const DAY_NUMBER = 'd'; // 1
export const LONGDAY_LONGMONTH_DAY_YEAR = 'EEEE, MMMM d, y'; // Sunday, June 13, 2021
export const TIME = 'h:mm aaa'; // 3:00 pm
export const TIME_TIMEZONE = `${TIME} zzz`; // 3:00 p.m. EDT
export const SHORT_MONTH_DAY_NUMBER = `${MONTH_NAME_SHORT} ${DAY_NUMBER}`; // Jun 13

// simple utility wrapper that might get more complicated in the future.
export const formatDate = (date: string | Date, formatStr: string): string => {
	if (typeof date === 'string') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return dateFnsFormat(parseISO(date), formatStr);
	}
	return dateFnsFormat(date, formatStr);
};

export const formatTimeAndTimezoneOffset = (date: string | Date): string =>
	formatDate(date, TIME_TIMEZONE);

export const formatDateAndTime = (date: string | Date, formatStr: string = TIME_TIMEZONE): string =>
	// return full date and tiome format - Sunday, June 13, 2021 - 3 p.m. EDT
	`${formatDate(date, LONGDAY_LONGMONTH_DAY_YEAR)} - ${formatDate(date, formatStr)}`;

// Fake the current time by changing this return value during development.
// This name leaves `now` available for local variables to hold the value.
export const getNow = (): Date => new Date();

// Use this instead of date-fns.isPast so we can fake the current time.
export const isPast = (date: Date): boolean => date < getNow();

// Use this instead of date-fns.isToday so we can fake the current time.
// Logic is copied from https://github.com/date-fns/date-fns/blob/master/src/isSameDay/index.ts
export const isToday = (date: Date): boolean =>
	startOfDay(date).getTime() === startOfDay(getNow()).getTime();
