import React from 'react';
import { ContentHeader } from '../../Common/ContentHeader';
import styles from './index.module.css';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';

export interface ProfileCompleteProps {
	onClick?: () => void;
}

export const ProfileComplete: React.FC<ProfileCompleteProps> = (props: ProfileCompleteProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.createProfileContainer}>
			<ContentHeader
				title={t('profile_complete_title')}
				message={t('profile_complete_message')}
				icon={'default'}
			>
				<div className={styles.buttonGroupHorizontal}>
					<ButtonWithIcons
						text="i'm done"
						type={ButtonType.action}
						style={{ width: '115px' }}
						onClick={() => {
							props.onClick?.();
						}}
					/>
				</div>
			</ContentHeader>
		</div>
	);
};
