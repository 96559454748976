import React from 'react';
import { type IconProps } from './types';

export const UpArrowIcon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<g id="ArrowUpwardFilled">
				<path
					id="Vector"
					d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
					fill={props.fillColor ?? 'white'}
				/>
			</g>
		</svg>
	);
};
