import React, { useState } from 'react';
import { NavHeader } from '../components/Common/NavHeader';
import { UpArrowButton } from '../components/Common/UpArrowButton';
import { ButtonIconTypes, ButtonType, ButtonWithIcons } from '../components/Common/ButtonWithIcons';
import { Theme, ToggleTheme } from '../components/Common/Toggle';
import { ProfileComplete } from '../components/Profile/ProfileComplete';
import LabelText from '../components/Common/LabelText/LabelText';
import { ChatEntry } from '../components/Common/ChatPane/ChatEntry';
import { SlidingPanes } from '../components/Common/SlidingPanes';
import { ChatPane } from '../components/Common/ChatPane';

import { HistoryPane } from '../components/HistoryPane';
import { ProfileSummaryWorkingPane } from '../components/Profile/ProfileSummaryWorkingPane';
import { Alert } from '../components/Common/Alerts';
import { WelcomeBack } from '../components/Courses/CourseSelectionPane/WelcomeBack';
import { DropDown } from '../components/Common/Dropdown';
import { ChatSource } from '../types';
import { useStore } from '../store/profAiStore';
import { useTranslation } from 'react-i18next';

export const TestPage: React.FC = () => {
	const [darkMode, setDarkMode] = useState(Theme.LIGHT);
	const [leftPaneOpen, setLeftPaneOpen] = useState(true);
	const [rightPaneOpen, setRightPaneOpen] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const me = useStore((state) => state.me);
	const { t } = useTranslation();
	const historySessions = useStore((state) => state.historySessions);
	const theme = useStore((state) => state.theme);
	const leftPane = (): React.ReactNode => {
		return (
			<HistoryPane
				onRequestNewSession={() => {}}
				showNewSessionButton={true}
				theme={theme}
				onPaneExpanded={setLeftPaneOpen}
				paneExpanded={leftPaneOpen}
				historySessions={historySessions}
				onSessionSelected={(session) => {}}
			/>
		);
	};

	const rightPane = (): React.ReactNode => {
		return (
			<ProfileSummaryWorkingPane
				onPaneExpanded={setRightPaneOpen}
				editMode={false}
				me={me}
				paneExpanded={rightPaneOpen}
				onSaveAndQuitClicked={() => {}}
			/>
		);
	};
	return (
		<div style={{ overflowY: 'auto', height: '100vh', padding: '20px' }}>
			<p>Header with prof ai</p>
			<NavHeader showSectionLogo={true} showThemeSelector={true} showProfile={true} />
			<p>Header without prof ai</p>
			<NavHeader showSectionLogo={false} showThemeSelector={true} showProfile={true} />
			<p>Header for mobile</p>
			<NavHeader showSectionLogo={false} showHamburger={true} />
			<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', height: 'auto' }}>
				<p>up arrow button</p>
				<UpArrowButton />
				<p>assistant message</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.Assistant,
						message: 'Okay! First question... What industry do you work in?',
					}}
				/>
				<p>assistant message with shortcuts</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.Assistant,
						message: 'Okay! First question... What industry do you work in?',
					}}
					showShortcuts={true}
				/>
				<p>user messages with actions</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.User,
						actions: [
							{ type: ButtonType.tertiary, title: t('SKIP') },
							{ type: ButtonType.tertiary, title: t('SKIP') },
						],
					}}
					askUserInput={true}
					userInputPlaceholder="answer_placeholder"
				/>

				<p>user messages with choices and actions</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.User,
						choices: [
							{
								option: 'a',
								choice: 'BUCATINI AL CARBONARA',
							},
							{ option: 'B', choice: 'uNI NIGIRI' },
						],
						actions: [
							{ type: ButtonType.action, title: t('i_need_a_hint') },
							{
								type: ButtonType.tertiary,
								title: t('skip_this_one'),
							},
						],
					}}
					askUserInput={true}
					userInputPlaceholder="answer_placeholder"
				/>

				<p>user messages with choices and actions</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.Assistant,
						choices: [{
							option: 'a',
							choice: 'BUCATINI AL CARBONARA',
						},
							{ option: 'B', choice: 'uNI NIGIRI' },],
						actions: [
							{ type: ButtonType.action, title: t('i_need_a_hint') },
							{
								type: ButtonType.tertiary,
								title: t('skip_this_one'),
							},
						],
					}}
					askUserInput={true}
					userInputPlaceholder="answer_placeholder"
				/>

				<p>assistant message with choices</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.Assistant,
						message:
							'Howdy, Ed. What would you like to discuss today? If you’re not sure how to start, just ask for suggestions.',
						choices: [
							{option: 'a', choice: 'GIVE ME AN OUTLINE OF THE COURSE TO JOG MY MEMORY'},
							{option: 'b', choice: 'SHOW ME THE FIRST LESSON VIDEO'},
							{option: 'c', choice: 'DESCRIBE THE MAIN FRAMEWORK'},
						],
						userInput: '',
						hideChoices: true,
						choicesTitle: 'MORE SUGGESTIONS',
					}}
					askUserInput={false}
					userInputPlaceholder="answer_placeholder"
				/>
				<p>Aleruser message with choices and text and user Input</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.User,
						choices: [{option: 'a', choice: 'Cancel'}],
						message: 'What feedback do you have about this statement?',
						userInput: '',
					}}
					askUserInput={true}
					userInputPlaceholder="answer_placeholder"
				/>
				<p>user message with choices and no text</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.User,
						choices: [{option: 'a', choice: 'CHOICE NUMBER ONE'}, {option: 'b', choice: 'CHOICE NUMBER TWO'}],
					}}
				/>
				<p>assistant message with thinking</p>
				<ChatEntry
					message={{
						messageId: '1',
						messageSource: ChatSource.Assistant,
						thinking: true,
					}}
				/>
				<p>drop down</p>
				<DropDown
					options={[
						{ id: 'nitin', value: 'nitin' },
						{ id: 'aadi', value: 'aadi' },
						{
							id: 'nitin',
							value: 'nitin',
						},
						{ id: 'aadi', value: 'aadi' },
						{ id: 'nitin', value: 'nitin' },
						{ id: 'aadi', value: 'aadi' },
						{
							id: 'nitin',
							value: 'nitin',
						},
						{ id: 'aadi', value: 'aadi' },
					]}
				/>
				<p>tertiary button with icon</p>
				<ButtonWithIcons
					iconRight={ButtonIconTypes.IconTypeProfile}
					text="Give me suggestions"
					type={ButtonType.tertiary}
				/>
				<p>action button with text</p>
				<ButtonWithIcons text="let's do it" type={ButtonType.action} />
				<p>tertiary button with text</p>
				<ButtonWithIcons text="do it later" type={ButtonType.tertiary} />
				<p>secondary button with text</p>
				<ButtonWithIcons text="save & close" type={ButtonType.secondary} />
				<p>primary button with text</p>
				<ButtonWithIcons text="give me suggestions" type={ButtonType.primary} />
				<p>primary button with one icon and text</p>
				<ButtonWithIcons
					text="more suggestions"
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.primary}
				/>
				<p>primary button with icon and text</p>
				<ButtonWithIcons
					text="more suggestions"
					iconLeft={ButtonIconTypes.IconTypeAdd}
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.primary}
				/>
				<p>secondary button with icon and text</p>
				<ButtonWithIcons
					text="more suggestions"
					iconLeft={ButtonIconTypes.IconTypeAdd}
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.secondary}
				/>
				<p>tertiary button with icon and text</p>
				<ButtonWithIcons
					text="more suggestions"
					iconLeft={ButtonIconTypes.IconTypeAdd}
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.tertiary}
				/>
				<p>action button with icon and text</p>
				<ButtonWithIcons
					text="more suggestions"
					iconLeft={ButtonIconTypes.IconTypeAdd}
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.action}
				/>
				<p>Text only button with icon & text </p>
				<ButtonWithIcons
					text="more suggestions"
					iconLeft={ButtonIconTypes.IconTypeAdd}
					iconRight={ButtonIconTypes.IconTypeAdd}
					type={ButtonType.textOnly}
				/>
				<p>Theme toggle</p>
				<ToggleTheme selectedTheme={darkMode} onChange={setDarkMode} />
				<p>Profile Complete</p>
				<ProfileComplete />
				<p>WelcomeBack</p>
				<WelcomeBack />
				<p>Label Text</p>
				<LabelText> Hello! how are you</LabelText>
				<p>Sliding Pane</p>
				<SlidingPanes
					leftPane={leftPane()}
					rightPane={rightPane()}
					isLeftOpen={leftPaneOpen}
					isRightOpen={rightPaneOpen}
					leftPaneWidth={413}
					rightPaneWidth={613}
				>
					<ChatPane messages={[]} onChatUserInput={() => {}} />
				</SlidingPanes>
				<p>Alert</p>
				<ButtonWithIcons
					text="Alert"
					type={ButtonType.action}
					onClick={() => {
						setShowAlert(!showAlert);
					}}
				/>

				<Alert
					isOpen={showAlert}
					title="Confirmation"
					description="Are you sure you want to delete that recent session?"
					buttons={[
						{ title: 'I\'M Sure', type: 'normal' },
						{ title: 'nevermind', type: 'cancel' },
					]}
					onDismissed={() => {
						setShowAlert(false);
					}}
					onClick={(button) => {
						alert(button.title);
						setShowAlert(false);
					}}
				/>
			</div>
		</div>
	);
};
