import React from 'react';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import { type ChatSession } from '../../../types';

export type HistoryEntryProps = ChatSession & {
	onClick: (sessionId: string) => void;
};

export const HistoryEntry: React.FC<HistoryEntryProps> = (props: HistoryEntryProps) => {
	const { t } = useTranslation();
	return (
		<div
			className={styles.entryContainer}
			key={props.sessionId}
			onClick={() => {
				props.onClick?.(props.sessionId);
			}}
		>
			<div className={styles.header}>
				<b className={styles.headerDate}>{props.date}</b>
				<b className={styles.header_label}>{`${
					props.title ?? t('ask_prof_ai')
				}`}</b>
			</div>

			{props.sessionTitle !== undefined && <div className={styles.sessionTitle}>{props.sessionTitle}</div>}
			{props.description !== undefined && <div className={styles.description}>{props.description}</div>}
			{props.quizProgress !== undefined && (
				<div className={styles.progress}>
					{Math.floor((props.quizProgress.correct * 100) / props.quizProgress.total)}% {t('correct')} / {Math.floor((props.quizProgress.complete *100)/props.quizProgress.total)}%{' '}
					{t('complete')}
				</div>
			)}
		</div>
	);
};
