import styles from './index.module.css';
import React, { useEffect, useState } from 'react';
import {
	type ChatMessage,
	ChatSource,
	type SolutionChoice,
	type UserAvailableActions,
} from '../../../types';
import { Virtuoso } from 'react-virtuoso';
import { ChatEntry } from './ChatEntry';
import ChatInput from './ChatInput';
import { InteractionChatEntry, type RenderableInteraction } from './InteractionEntry';
import { useElementSize } from 'usehooks-ts';

export interface ChatPaneProps {
	messages: ChatMessage[];
	onChatUserInput: (input: string) => void;
	disableInput?: boolean;
	hideInput?: boolean;
	panePerInteraction?: boolean;
	onActionClicked?: (messageId: string, action: UserAvailableActions) => void;
	onChoiceClicked?: (messageId: string, choice: SolutionChoice) => void;
}

export const ChatPane: React.FC<ChatPaneProps> = (props: ChatPaneProps) => {
	const disableInput = props.disableInput ?? false;
	const hideInput = props.hideInput ?? false;
	const panePerInteraction = props.panePerInteraction ?? false;
	const [interactions, setInteractions] = useState<RenderableInteraction[]>([]);
	const messagesToInteractions = (messages: ChatMessage[]): RenderableInteraction[] => {
		const interactions: RenderableInteraction[] = [];
		let interaction: RenderableInteraction | undefined;
		for (const message of props.messages) {
			if (message.messageSource === ChatSource.Assistant || interaction === undefined) {
				interaction = { messages: [] };
				interactions.push(interaction);
			}
			interaction.messages.push(message);
		}
		return interactions;
	};

	const [containerRef, { height }] = useElementSize();

	useEffect(() => {
		setInteractions(messagesToInteractions(props.messages));
	}, [props.messages]);

	const onUserInput = (input: string): void => {
		props.onChatUserInput?.(input);
	};

	return (
		<div className={styles.chatPane} ref={containerRef}>
			<Virtuoso
				followOutput={(isAtBottom: boolean) => {
					if (isAtBottom) {
						return false;
					}
					return 'auto';
				}}
				className={`${styles.chatContainer} ion-content-scroll-host`}
				totalCount={panePerInteraction ? interactions.length : props.messages.length}
				itemContent={(index) => {
					return (
						<>
							{!panePerInteraction && (
								<ChatEntry
									key={`ch_entry_${props.messages[index].messageId}_${index}`}
									message={props.messages[index]}
									onChoiceClicked={(messageId, choice) => {
										props.onChoiceClicked?.(messageId, choice);
									}}
									onCopyMessage={(messageId: string) => {}}
									onRedoClicked={(messageId: string) => {}}
									onFeedbackClicked={() => {}}
								/>
							)}
							{panePerInteraction && (
								<InteractionChatEntry
									choiceLabelClassName={styles.choiceLabelsTextAlign}
									height={height}
									index={index}
									key={`in_entry_${props.messages[index].messageId}_${index}`}
									interactions={interactions[index]}
									onActionClicked={(messageId, action) => {
										props.onActionClicked?.(messageId, action);
									}}
									onChoiceClicked={(messageId, choice) => {
										props.onChoiceClicked?.(messageId, choice);
									}}
								/>
							)}
						</>
					);
				}}
			></Virtuoso>
			{!hideInput && <ChatInput disabled={disableInput} onClick={onUserInput} />}
		</div>
	);
};
