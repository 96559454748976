import React from 'react';
import { type IconProps } from './types';

export const ArrowDownIcon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<g id="ArrowDropDownFilled">
				<path id="Vector" d="M7 10L12 15L17 10L7 10Z" fill={props.fillColor ?? 'white'} />
			</g>
		</svg>
	);
};
