import { IS_DEV, TOKEN_COOKIE_NAME } from '../config/config';
import { getNow } from './dateUtils';


// attempt at max time to set a cookie expiration. Some browsers don't allow this long, but will set to their own max in this case.
export const COOKIE_DEFAULT_EXPIRATION_DAYS = 3 * 365

export const convertDaysToMilliseconds = (days: number): number => days * 24 * 60 * 60 * 1000

export const buildCookieString = (name: string, value: string, days: number = COOKIE_DEFAULT_EXPIRATION_DAYS, domain?: string): string => {
	let expires = ''
	const domainAttr = `; Domain=${domain ?? (IS_DEV ? 'localhost' : '.sectionschool.com')}`
	const secureAttr = IS_DEV ? '' : '; Secure; SameSite=Lax'
	const path = '; path=/'

	if (days != null) {
		const date = getNow();
		date.setTime(date.getTime() + convertDaysToMilliseconds(days));
		expires = `; expires=${date.toUTCString()}`;
	}

	return `${name}=${value}${path}${domainAttr}${secureAttr}${expires}`;
}

export const setCookie = (name: string, value: string, days?: number, domain?: string): void => {
	document.cookie = buildCookieString(name, value, days, domain)
}

export const parseCookie = (name: string): string => {
	const nameEQ = `${name}=`
	const ca = document.cookie.split(';')

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length)
		}
		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length)
		}
	}

	return ''
}

export const deleteCookie = (name: string, domain?: string): void => {
	setCookie(name, '', -1, domain)
}

export const parseTokenCookie = (): string => parseCookie(TOKEN_COOKIE_NAME)