import type { StateCreator } from 'zustand';
import { Theme } from '../../components/Common/Toggle';

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCzvn2PSQI36mUmuBN_45lNGQ-eOI7Qh1k',
	authDomain: 'profai-d901f.firebaseapp.com',
	projectId: 'profai-d901f',
	storageBucket: 'profai-d901f.appspot.com',
	messagingSenderId: '634579088076',
	appId: '1:634579088076:web:d487892b1bc9ffdb968869',
	measurementId: 'G-38Z5Z9EZL7',
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export interface UISettingsSlice {
	theme: Theme;
	setTheme: (theme: Theme) => void;
	firebaseApp: firebase.app.App;
}

export const createUISettingsSlice: StateCreator<UISettingsSlice, [], [], UISettingsSlice> = (
	set,
	get,
) => ({
	theme: Theme.LIGHT,
	setTheme: (theme: Theme) => {
		set({ theme });
	},
	firebaseApp
});
