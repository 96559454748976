export const COOKIE_NAMESPACE = process.env.COOKIE_NAMESPACE;
export const IS_DEV = import.meta.env.DEV;
// stage and prod both have NODE_ENV === 'production'
export const IS_PROD = import.meta.env.PROD;
// 'test' is set for Jest tests
export const TOKEN_COOKIE_NAME = `${COOKIE_NAMESPACE}_id_token`;
export const REFRESH_TOKEN_COOKIE_NAME = `${COOKIE_NAMESPACE}_refresh_token`;
export const USER_ID_COOKIE_NAME = `${COOKIE_NAMESPACE}_user_id`;
export const AUDIENCE_COOKIE_NAME: string = `${COOKIE_NAMESPACE}_audience`; // cognito pool client id

export const DEFAULT_LOGIN_DAYS = 31;

export const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID;
export const COGNITO_SSO_CLIENT_ID = process.env.COGNITO_SSO_CLIENT_ID;
export const COGNITO_SSO_URL = process.env.COGNITO_SSO_URL;
export const COGNITO_SSO_USER_POOL_ID = process.env.COGNITO_SSO_USER_POOL_ID;
export const COGNITO_URL = process.env.COGNITO_URL;
export const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID;
export const AUTH_REDIRECT_URI = '';

export const AUTH_TOKEN_URL = `${COGNITO_URL}/oauth2/token`
export const SSO_AUTH_TOKEN_URL = `${COGNITO_SSO_URL}/oauth2/token`


export const APP_HOST = process.env.APP_HOST;

export const API_URL = process.env.API_URL;

export const NEST_API = process.env.NEST_API;

export const LOGIN_ROUTE= process.env.LOGIN_ROUTE ?? '/login';