import type { StateCreator } from 'zustand';
import {
	type AnswerOptions,
	type ChatMessage,
	type ChatSession,
	ChatSource,
	type CourseOfferred,
	type InSessionQuizSummaryEntry,
	type SessionType,
	type SkillLevel,
} from '../../types';
import { mockHistorySession } from '../mockData';
import { createQuizOfType } from '../quizHelper';
import moment from 'moment/moment';
import { type UserSlice } from './userSlice';
import { type AppStateSlice } from './appStateSlice';

const useMockData = import.meta.env.VITE_MOCK_APIS === 'true';

export interface SessionSlice {
	sessionCounter: number;
	historySessions: Map<string, ChatSession>;
	addSession: (
		sessionType: SessionType,
		course: CourseOfferred,
	) => Promise<ChatSession | undefined>;
	removeSession: (session: ChatSession) => void;
	sessionWithId: (sessionId: string) => ChatSession | undefined;
	addMessageToSession: (sessionId: string, message: ChatMessage) => ChatMessage[];
	processQuizAnswer: (
		sessionId: string,
		quizIndex: number,
		choice: AnswerOptions,
		skipped?: boolean,
	) => void;
}

export const createSessionSlice: StateCreator<
	SessionSlice & UserSlice & AppStateSlice,
	[],
	[],
	SessionSlice
> = (set, get) => ({
	sessionCounter: 0,
	historySessions: useMockData ? mockHistorySession() : new Map(),
	addSession: async (
		sessionType: SessionType,
		course: CourseOfferred,
		skillLevel: SkillLevel = 'beginner',
	): Promise<ChatSession | undefined> => {
		const state = get();
		const session: ChatSession = {
			sessionId: `${state.sessionCounter + 1}`,
			sessionTitle: course.title,
			title: course.description,
			date: moment(new Date()).format('dd-mm-yyyy'),
			sessionType,
			course,
			messages: [],
		};
		if (sessionType === 'quiz') {
			session.quizQuestions = await createQuizOfType(course?.str_id ?? '', skillLevel);
			session.quizProgress = {
				correct: 0,
				total: session.quizQuestions.length,
				complete: 0,
				maxSeen: 0,
			};
			session.quizSummary = [...Array(session.quizQuestions.length).keys()].map((_) => {
				return {
					skipped: false,
					userProvidedAnswer: undefined,
				} as InSessionQuizSummaryEntry;
			});
		}

		set((prev) => ({
			historySessions: new Map(prev.historySessions).set(session.sessionId, session),
			sessionCounter: prev.sessionCounter + 1,
		}));

		return session;
	},
	removeSession: (session: ChatSession) => {
		const state = get();
		const historySessions = state.historySessions;
		historySessions.delete(session.sessionId);
		set((prev) => ({
			historySessions: new Map(historySessions),
		}));
		if (session.sessionId === state.currentSession?.sessionId) {
			set((prev) => ({
				currentSession: undefined,
			}));
		}
	},
	sessionWithId: (sessionId: string): ChatSession | undefined => {
		return get().historySessions.get(sessionId);
	},
	addMessageToSession: (sessionId: string, message: ChatMessage): ChatMessage[] => {
		const state = get();
		const session = state.historySessions.get(sessionId);

		if (session === undefined) {
			if (sessionId === state.currentSession?.sessionId) {
				set((prev) => ({
					currentSession: undefined,
				}));
			}
			return [];
		}
		const newSession = { ...session };
		if (session.messages !== undefined) {
			newSession.messages = [...session.messages, message];
		} else {
			newSession.messages = [message];
		}
		set((prev) => ({
			historySessions: new Map(prev.historySessions).set(sessionId, newSession),
		}));
		if (state.currentSession?.sessionId === sessionId) {
			set((prev) => ({
				currentSession: newSession,
			}));
		}
		return newSession.messages ?? [];
	},

	processQuizAnswer: (
		sessionId: string,
		quizIndex: number,
		choice: AnswerOptions,
		skipped = false,
	) => {
		const state = get();
		const session = state.historySessions.get(sessionId);
		if (session === undefined) {
			return;
		}
		const newSession = {
			...session,
			quizSummary:
				session.sessionType === 'quiz' && session.quizSummary !== undefined
					? [...session.quizSummary]
					: undefined,
			quizProgress:
				session.sessionType === 'quiz' && session.quizProgress !== undefined
					? { ...session.quizProgress }
					: undefined,
		} as ChatSession;
		if (
			newSession.sessionType === 'quiz' &&
			newSession.quizQuestions !== undefined &&
			newSession.quizProgress !== undefined &&
			newSession.quizSummary !== undefined &&
			newSession.quizSummary?.length > quizIndex &&
			newSession.quizQuestions.length > quizIndex
		) {
			if (!skipped) {
				newSession.quizProgress.complete = (newSession.quizProgress?.complete ?? 0) + 1;
			}
			newSession.quizSummary[quizIndex].skipped = skipped ?? false;
			if (choice !== undefined) {
				newSession.quizSummary[quizIndex].userProvidedAnswer = choice;
				newSession.messages = [
					...(newSession.messages ?? []),
					{
						messageId: `${(newSession.messages?.length ?? 0) + 1}`,
						message: `${choice}`,
						messageSource: ChatSource.User,
					},
				];
			}
			newSession.quizSummary[quizIndex].correct =
				newSession.quizQuestions[quizIndex].answer === choice;
			if (newSession.quizSummary[quizIndex].correct === true) {
				newSession.quizProgress.correct = (newSession.quizProgress.correct ?? 0) + 1;
			}

			newSession.quizProgress.maxSeen = Math.max(
				quizIndex + 1,
				newSession.quizProgress.maxSeen,
			);

			set((prev) => ({
				historySessions: new Map(prev.historySessions).set(sessionId, newSession),
			}));
			if (state.currentSession?.sessionId === sessionId) {
				set((prev) => ({
					currentSession: newSession,
				}));
			}
			return newSession.messages ?? [];
		}
	},
});