import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import TermsOfUseFileHandle from '../../assets/termsofuse.txt';
import { ButtonType, ButtonWithIcons } from '../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../Common/ContentHeader';
import { Theme } from '../Common/Toggle';

export interface TermsOfServiceProps {
	onClick?: () => void;
	theme: Theme;
}

export const TermsOfService: React.FC<TermsOfServiceProps> = (props: TermsOfServiceProps) => {
	const [text, setText] = useState<string>('');
	const { t } = useTranslation();
	useEffect(() => {
		void fetch(TermsOfUseFileHandle)
			.then(async (response) => await response.text())
			.then((textContent) => {
				setText(textContent);
			});
	}, []);
	return (
		<div className={styles.tosContainer}>
			<ContentHeader
				style={{ paddingBottom: '100px', maxWidth: 'none' }}
				title={t('Terms of Service')}
				message={''}
			>
				<pre className={styles.tosContent}>{text}</pre>
				<div className={styles.buttonContainer}>
					<ButtonWithIcons
						type={props.theme === Theme.LIGHT ? ButtonType.action : ButtonType.primary}
						onClick={() => props.onClick?.()}
						text={t('I understand')}
					/>
				</div>
			</ContentHeader>
		</div>
	);
};
