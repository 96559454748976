import React from 'react';
import styles from './index.module.css';
import AiLogo from '../../../assets/ai-logo.svg?react';
import HamburgerIcon from '../../../assets/hamburger_filled.svg?react';
import { ButtonType, ButtonWithIcons } from '../ButtonWithIcons';
import Rectangle from '../../../assets/Rectangle.svg?react';
import { Theme, ToggleTheme } from '../Toggle';
import { SectionLogo } from '../Icons/SectionLogo';
import { ProfLogo } from '../Icons/ProfLogo';
import { SectionTitle } from '../Icons/SectionTitle';
import { ProfileLogo } from '../Icons/ProfileLogo';

export interface NavHeaderProps {
	showSectionLogo?: boolean;
	showThemeSelector?: boolean;
	showProfile?: boolean;
	showHamburger?: boolean;
	onThemeChanged?: (theme: Theme) => void;
	theme?: Theme;
	onLogoClicked?: () => void;
	onProfileClicked?: () => void;
}

export const NavHeader: React.FC<NavHeaderProps> = (props: NavHeaderProps) => {
	const showSectionLogo = props.showSectionLogo ?? true;
	const showProfile = props.showProfile ?? false;
	const showThemeSelector = props.showThemeSelector ?? false;
	const showHamburger = props.showHamburger ?? false;
	return (
		<div className={styles.nav}>
			<div className={styles.navLogoSection} onClick={() => props.onLogoClicked?.()}>
				<SectionLogo className={styles.sectionLogoIcon} fillColor={'var(--nav-header-icon-fill)'}/>
				{showSectionLogo && <SectionTitle className={styles.sectionLogoIcon} fillColor={'var(--nav-header-icon-fill)'}/>}
				{showSectionLogo && <Rectangle />}
				<ProfLogo className={styles.profIcon} fillColor={'var(--nav-header-icon-fill)'} />
				<AiLogo className={styles.AiIcon} />
			</div>
			<div className={styles.navProfileSection}>
				{showProfile && (
					<ButtonWithIcons
						style={props.theme === Theme.DARK ?{border: '1px solid var(--nav-header-button-border-color)'}:{}}
						type={props.theme === Theme.DARK ? ButtonType.action : ButtonType.tertiary}
						iconRight={<ProfileLogo fillColor={'var(--nav-header-icon-fill)'}/>}
						onClick={() => {
							props.onProfileClicked?.();
						}}
					>
						<span>My Profile</span>
					</ButtonWithIcons>
				)}
				{showThemeSelector && (
					<ToggleTheme
						selectedTheme={props.theme ?? Theme.LIGHT}
						onChange={(theme) => props.onThemeChanged?.(theme)}
					/>
				)}
				{showHamburger && <HamburgerIcon />}
			</div>
		</div>
	);
};
