import React from 'react';
import {
	type ChatMessage,
	type SolutionChoice,
	type UserAvailableActions,
} from '../../../../types';
import { ChatEntry } from '../ChatEntry';
import styles from './index.module.css';

export interface RenderableInteraction {
	messages: ChatMessage[];
}

export interface InteractionEntryProps {
	index: number;
	height: number;
	interactions: RenderableInteraction;
	onActionClicked?: (messageId: string, action: UserAvailableActions) => void;
	onChoiceClicked?: (messageId: string, choice: SolutionChoice) => void;
	choiceLabelClassName?:string;
}

export const InteractionChatEntry: React.FC<InteractionEntryProps> = (
	props: InteractionEntryProps,
) => {
	return (
		<div className={styles.interactionWrapper} style={{ height:`${props.height-100}px` }}>
			{props.interactions.messages.map((message) => {
				return (
					<ChatEntry
						choiceLabelClassName={props.choiceLabelClassName}
						key={`int_${message.messageId}_${props.index}`}
						message={message}
						onChoiceClicked={(messageId, choice) => {
							props.onChoiceClicked?.(messageId, choice);
						}}
						onActionClicked={(messageId: string, action: UserAvailableActions) => {
							props.onActionClicked?.(messageId, action);
						}}
					/>
				);
			})}
		</div>
	);
};
