import React from 'react';
import { ListPane } from '../../Common/ListPane';
import { SummaryView } from './SummaryView';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import { type UserProfile } from '../../../types';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { EventBus } from '../../../utils/eventBus';
import { EVT_PROFILE_ELEMENT_CLICKED } from '../../../types/Events';
import { HeaderIconJustification } from '../../Common/ListPane/Header';
import { BookIcon } from '../../Common/Icons/BookIcon';

export interface ProfileSummaryWorkingPaneProps {
	toggleEnabled?: boolean;
	paneExpanded: boolean;
	onPaneExpanded: (expanded: boolean) => void;
	me: UserProfile;
	showSaveAndQuit?: boolean;
	editMode: boolean;
	onSaveAndQuitClicked:()=>void;
}

export const ProfileSummaryWorkingPane: React.FC<ProfileSummaryWorkingPaneProps> = (
	props: ProfileSummaryWorkingPaneProps,
) => {
	const { t } = useTranslation();
	const showSaveAndQuit = props.showSaveAndQuit ?? false;

	return (
		<ListPane
			expanded={props.paneExpanded}
			onToggle={(expanded) => {
				props.onPaneExpanded(expanded);
			}}
			headerIconJustification={HeaderIconJustification.Left}
			lazyHeaderIcon={() => <BookIcon className={styles.headerIcon}  fillColor={'var(--nav-header-icon-fill)'} onClick={() => {
				if (props.toggleEnabled === true) {
					props.onPaneExpanded(!props.paneExpanded);
				}
			}} />}
			toggleEnabled={props.toggleEnabled}
		>
			<div className={styles.content}>
				<SummaryView
					profile={props.me}
					onProfileElementClicked={(elementType) => {
						if (props.editMode) {
							EventBus.getInstance().dispatch(EVT_PROFILE_ELEMENT_CLICKED, elementType);
						}
					}}
				/>
			</div>
			{showSaveAndQuit && (
				<div className={styles.bottomButtonContainer}>
					<ButtonWithIcons type={ButtonType.tertiary} text={t('save_and_quit')} onClick={props.onSaveAndQuitClicked} />
				</div>
			)}
		</ListPane>
	);
};
