import React, { type CSSProperties } from 'react';

import styles from './index.module.css';

export enum PaneId {
	LEFT,
	RIGHT,
}

export interface SlidingPaneProps {
	isLeftOpen?: boolean;
	isRightOpen?: boolean;
	leftPane?: React.ReactNode;
	children: React.ReactNode;
	rightPane?: React.ReactNode;
	leftPaneWidth?: number;
	rightPaneWidth?: number;
	className?: string;
	style?: CSSProperties;
	onRequestClose?: (pane: PaneId) => void;
	onAfterOpen?: (pane: PaneId) => void;
	onAfterClose?: (pane: PaneId) => void;
}

export const SlidingPanes: React.FC<SlidingPaneProps> = ({
	isLeftOpen,
	isRightOpen,
	leftPane,
	rightPane,
	children,
	className,
	style,
	leftPaneWidth,
	rightPaneWidth,
	onRequestClose,
	onAfterOpen,
	onAfterClose,
}: SlidingPaneProps) => {
	// const handleAfterOpen = () => {
	// 	// Timeout fixes animation in Safari
	// 	onAfterOpen?.();
	// 	setTimeout(() => {
	// 		setWasOpen(true);
	// 	}, 0);
	// };
	//
	// const handleAfterClose = () => {
	// 	onAfterClose?.();
	// 	setTimeout(() => {
	// 		setWasOpen(false);
	// 	}, 0);
	// };

	return (
		<div className={`${styles.slidingPane} ${className ?? ''}`} style={style ?? {}}>
			{typeof leftPane !== 'undefined' && (
				<div
					className={`${
						isLeftOpen === true ? styles.leftPaneOpen : styles.leftPaneCollapsed
					}`}
					style={{ width: `${isLeftOpen === true ? leftPaneWidth : 56}px` }}
				>
					{leftPane}
				</div>
			)}
			{typeof children !== 'undefined' && <div className={styles.content}>{children}</div>}
			{typeof rightPane !== 'undefined' && (
				<div
					className={`${
						isRightOpen === true ? styles.rightPaneOpen : styles.rightPaneCollapsed
					}`}
					style={{ width: `${isRightOpen === true ? rightPaneWidth : 56}px` }}
				>
					{rightPane}
				</div>
			)}
		</div>
	);
};
