import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { NavHeader } from '../components/Common/NavHeader';
import { SlidingPanes } from '../components/Common/SlidingPanes';
import { HistoryPane } from '../components/HistoryPane';
import { ProfileSummaryWorkingPane } from '../components/Profile/ProfileSummaryWorkingPane';
import { UserOnboardingState, useStore } from '../store/profAiStore';
import { CourseSelectionPane } from '../components/Courses/CourseSelectionPane';
import { TermsOfService } from '../components/TermsOfService';
import { type CourseOfferred } from '../types';
import { CourseSelected } from '../components/Courses/CourseSelected';
import { ProfileSetupIntro } from '../components/Profile/ProfileSetupIntro';
import { UpdateProfile } from '../components/Profile/UpdateProfile';
import { ProfileComplete } from '../components/Profile/ProfileComplete';
import { useLocation } from 'react-router';
import { ContinueSessionPane } from '../components/Courses/ContinueSessionPane';
import { AppRoutes } from '../App';
import { useHistory } from 'react-router-dom';
import { QuizProgressPane } from '../components/Courses/QuizProgressPane';
import { ProgressIndicator } from '../components/Common/ProgressIndicator';

interface HomePageProps {
	tos?: boolean;
	showProfile?: boolean;
	newSession?: boolean;
	continueSession?: boolean;
	course?: CourseOfferred;
}

export const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
	const theme = useStore((state) => state.theme);
	const setTheme = useStore((state) => state.setTheme);

	const onboardingState = useStore((state) => state.onboardingState);
	const setOnboardingState = useStore((state) => state.setOnboardingState);

	const addSession = useStore((state) => state.addSession);
	const setCurrentSession = useStore((state) => state.setCurrentSession);
	const addMessageToSession = useStore((state) => state.addMessageToSession);

	const courseList = useStore((state) => state.courseList);
	const selectedCourse = useStore((state) => state.selectedCourse);
	const setSelectedCourse = useStore((state) => state.setSelectedCourse);
	const processQuizAnswer = useStore((state) => state.processQuizAnswer);

	const currentSession = useStore((state) => state.currentSession);

	const historySessions = useStore((state) => state.historySessions);

	// const router = useIonRouter();
	const history = useHistory();

	const me = useStore((state) => state.me);
	const setMe = useStore((state) => state.setMe);

	const bootstrapApp = useStore((state) => state.bootstrapApp);

	const showBootstrap = onboardingState === UserOnboardingState.RESOLVING;
	const showTos = props.tos ?? false;
	const showNewSessionScreen = props.newSession ?? false;
	const showContinueSession = props.continueSession ?? false;
	const showProfile = props.showProfile ?? false;

	const location = useLocation();

	const [leftPaneOpen, setLeftPaneOpen] = useState(false);
	const [leftPaneEnabled, setLeftPaneEnabled] = useState(false);
	const [rightPaneOpen, setRightPaneOpen] = useState(false);
	const [rightPaneEnabled, setRightPaneEnabled] = useState(false);

	const navToPath = (path: string): void => {
		console.log(`navToPath : ${path}`);
		if (location.pathname !== path) {
			if (location.pathname === AppRoutes.profile && path === AppRoutes.home) {
				window.location.href = path;
			} else {
				history.replace(path);
			}
		} else {
			console.log('Ignoring request to navigate.');
		}
	};

	useEffect(() => {
		if (showProfile || onboardingState !== UserOnboardingState.ONBOARDED) {
			setLeftPaneEnabled(false);
			setLeftPaneOpen(false);
			setRightPaneEnabled(false);
			setRightPaneOpen(
				![UserOnboardingState.RESOLVING, UserOnboardingState.FTU].includes(onboardingState),
			);
		} else if (showNewSessionScreen) {
			setLeftPaneEnabled(true);
			// only show the panel expanded if there is history
			setLeftPaneOpen(historySessions.size > 0);
			setRightPaneEnabled(false);
			setRightPaneOpen(false);
		} else if (showContinueSession) {
			if (currentSession === undefined) {
				navToPath(AppRoutes.home);
				return;
			}
			setLeftPaneEnabled(true);
			setLeftPaneOpen(historySessions.size > 0 && currentSession?.sessionType !== 'quiz');
			setRightPaneOpen(currentSession?.sessionType === 'quiz');
			setRightPaneEnabled(currentSession?.sessionType !== 'quiz');
		} else if (showTos) {
			// we need to do nothing
		} else {
			setLeftPaneEnabled(true);
			setLeftPaneOpen(historySessions.size > 0);
			setRightPaneEnabled(false);
			setRightPaneOpen(false);
		}

		console.log(`onboarding state: ${onboardingState}\n props: ${JSON.stringify(props)}`);
		console.log(
			`currentSession : ${JSON.stringify(
				currentSession,
			)} ${showTos} ${showNewSessionScreen} ${showProfile} ${showContinueSession}`,
		);
		console.log(
			`${
				typeof currentSession === 'undefined' &&
				!showTos &&
				!showNewSessionScreen &&
				!showProfile &&
				!showContinueSession
			}`,
		);
		if (showProfile || onboardingState !== UserOnboardingState.ONBOARDED) {
			navToPath(AppRoutes.profile);
		}
	}, [
		onboardingState,
		props.tos,
		props.showProfile,
		props.course,
		props.continueSession,
		props.newSession,
	]);

	const renderTermsOfService = (): React.ReactNode => {
		return (
			<TermsOfService
				theme={theme}
				onClick={() => {
					navToPath(AppRoutes.home);
				}}
			/>
		);
	};

	const renderIntroProfile = (): React.ReactNode => {
		return (
			<ProfileSetupIntro
				ftuForComplete={onboardingState === UserOnboardingState.FTU_COMPLETE}
				onCanceled={() => {
					setOnboardingState(UserOnboardingState.ONBOARDED);
					navToPath('/home');
				}}
				onClick={() => {
					if (onboardingState === UserOnboardingState.FTU) {
						setOnboardingState(UserOnboardingState.ONBOARDING);
					} else {
						setOnboardingState(UserOnboardingState.ONBOARDED);
					}
				}}
			/>
		);
	};

	const renderCreateProfile = (): React.ReactNode => {
		return (
			<UpdateProfile
				editMode={false}
				profile={me}
				setProfile={(me, completed) => {
					setMe(me)
						.then(() => {
							if (completed) {
								setOnboardingState(UserOnboardingState.COMPLETE);
							}
						})
						.catch(() => {});
				}}
			/>
		);
	};

	const renderProfileComplete = (): React.ReactNode => {
		return (
			<ProfileComplete
				onClick={() => {
					setOnboardingState(UserOnboardingState.ONBOARDED);
					navToPath(AppRoutes.home);
				}}
			/>
		);
	};

	const renderUpdateProfile = (): React.ReactNode => {
		return (
			<UpdateProfile
				editMode={true}
				profile={me}
				setProfile={(me, completed) => {
					void setMe(me);
				}}
			/>
		);
	};

	const renderCourseSelectionPane = (): React.ReactNode => {
		return (
			<CourseSelectionPane
				courseList={courseList}
				onReviewTermsClicked={() => {
					navToPath(AppRoutes.tos);
				}}
				onCourseSelected={(course) => {
					setSelectedCourse(course);
					navToPath(AppRoutes.newSession);
				}}
			/>
		);
	};

	const renderCourseSelectedPane = (): React.ReactNode => {
		return (
			<CourseSelected
				course={selectedCourse}
				profile={me}
				theme={theme}
				onClick={(sessionType) => {
					if (selectedCourse !== undefined) {
						addSession(sessionType, selectedCourse)
							.then(async (session) => {
								setCurrentSession(session);
								navToPath(AppRoutes.continueSession);
							})
							.catch(() => {
								console.error('Could not create a new session');
								navToPath(AppRoutes.home);
							});
					}
				}}
			/>
		);
	};

	const renderContinueSessionPane = (): React.ReactNode => {
		if (currentSession === undefined) {
			return null;
		}
		return (
			<ContinueSessionPane
				me={me}
				currentSession={currentSession}
				newMessageInSession={(sessionId, userMessage) => {
					addMessageToSession(sessionId, userMessage);
				}}
				processQuizAnswer={(sessionId, quizIndex, choice, skipped) => {
					processQuizAnswer(sessionId, quizIndex, choice, skipped);
				}}
				profile={me}
				sessionComplete={() => {
					setCurrentSession(undefined);
					navToPath(AppRoutes.home);
				}}
				redoSession={() => {
					const sessionType = currentSession?.sessionType;
					const course = currentSession?.course;
					setCurrentSession(undefined);
					if (course === undefined) {
						// will never happen but handling it for warning
						navToPath(AppRoutes.home);
						return;
					}
					addSession(sessionType, course)
						.then(async (session) => {
							setCurrentSession(session);
							navToPath(AppRoutes.continueSession);
						})
						.catch(() => {
							console.error('Could not create a new session');
							navToPath(AppRoutes.home);
						});
				}}
			/>
		);
	};

	const renderProfileContainerPane = (): React.ReactNode => {
		switch (onboardingState) {
			case UserOnboardingState.FTU:
			case UserOnboardingState.FTU_COMPLETE:
				return renderIntroProfile();
			case UserOnboardingState.ONBOARDING:
				return renderCreateProfile();
			case UserOnboardingState.COMPLETE:
				return renderProfileComplete();
			default:
				return renderUpdateProfile();
		}
	};

	const renderLeftPane = (): React.ReactNode => {
		return (
			<HistoryPane
				theme={theme}
				onSessionSelected={(chatSession) => {
					setCurrentSession(chatSession);
					navToPath(AppRoutes.continueSession);
				}}
				showNewSessionButton={location.pathname !== AppRoutes.home}
				onPaneExpanded={(expanded) => {
					setLeftPaneOpen(expanded);
				}}
				toggleEnabled={leftPaneEnabled}
				paneExpanded={leftPaneOpen}
				historySessions={historySessions}
				onRequestNewSession={() => {
					setCurrentSession(undefined);
					navToPath(AppRoutes.home);
				}}
			/>
		);
	};

	const renderRightPane = (): React.ReactNode => {
		return (
			<>
				{showProfile && (
					<ProfileSummaryWorkingPane
						me={me}
						onPaneExpanded={setRightPaneOpen}
						toggleEnabled={rightPaneEnabled}
						paneExpanded={rightPaneOpen}
						showSaveAndQuit={onboardingState === UserOnboardingState.ONBOARDED}
						editMode={onboardingState === UserOnboardingState.ONBOARDED}
						onSaveAndQuitClicked={() => {
							navToPath(AppRoutes.home);
						}}
					/>
				)}
				{!showProfile && (
					<>
						{showContinueSession && (
							<>
								{currentSession?.sessionType === 'quiz' && (
									<QuizProgressPane
										paneExpanded={rightPaneOpen}
										toggleEnabled={rightPaneEnabled}
										onPaneExpanded={() => {}}
										session={currentSession}
										onSaveAndQuitClicked={() => {
											setCurrentSession(undefined);
											navToPath(AppRoutes.home);
										}}
									/>
								)}
							</>
						)}
					</>
				)}
			</>
		);
	};

	const renderBootstrap = (): React.ReactNode => {
		return <ProgressIndicator />;
	};

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<NavHeader
						showThemeSelector={true}
						showProfile={true}
						showSectionLogo={true}
						theme={theme}
						onThemeChanged={setTheme}
						onLogoClicked={() => {
							// When the user clicks on the nav, it is considered to be end of current session
							setCurrentSession(undefined);
							if (onboardingState === UserOnboardingState.FTU_COMPLETE) {
								setOnboardingState(UserOnboardingState.ONBOARDED);
							}
							navToPath(AppRoutes.home);
						}}
						onProfileClicked={() => {
							if (onboardingState === UserOnboardingState.ONBOARDED) {
								setOnboardingState(UserOnboardingState.FTU_COMPLETE);
							}
							navToPath(AppRoutes.profile);
						}}
					/>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonPage>
					<SlidingPanes
						leftPane={renderLeftPane()}
						rightPane={renderRightPane()}
						isLeftOpen={leftPaneOpen}
						isRightOpen={rightPaneOpen}
						leftPaneWidth={413}
						rightPaneWidth={613}
					>
						{showBootstrap && renderBootstrap()}
						{!showBootstrap && (
							<>
								{showTos && renderTermsOfService()}
								{!showTos && (
									<>
										{showProfile && renderProfileContainerPane()}
										{!showProfile &&
											onboardingState === UserOnboardingState.ONBOARDED && (
												<>
													{showNewSessionScreen &&
														renderCourseSelectedPane()}
													{showContinueSession &&
														typeof currentSession !== 'undefined' &&
														renderContinueSessionPane()}
												</>
											)}
									</>
								)}
							</>
						)}

						{typeof currentSession === 'undefined' &&
							onboardingState === UserOnboardingState.ONBOARDED &&
							!showTos &&
							!showBootstrap &&
							!showNewSessionScreen &&
							!showProfile &&
							!showContinueSession &&
							renderCourseSelectionPane()}
					</SlidingPanes>
				</IonPage>
			</IonContent>
		</>
	);
};
