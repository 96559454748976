/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"clientName":"nest-js"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
	DateTime: any;
	/** JSON object */
	Json: any;
};

export type BaseSession = {
	__typename?: 'BaseSession';
	courseId: Scalars['String'];
	courseMeta?: Maybe<Scalars['Json']>;
	/** course type (eg. SPRINT | WORKSHOP) */
	courseType: Scalars['String'];
	courseTypeMeta?: Maybe<Scalars['Json']>;
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	endAt: Scalars['DateTime'];
	enrollmentClose?: Maybe<Scalars['DateTime']>;
	enrollmentOpen?: Maybe<Scalars['DateTime']>;
	faculties: Array<Faculty>;
	/** gated access type (eg. CUSTOMER | NOT_GATED) applied for the course. */
	gatedAccessType: Scalars['String'];
	onDemandAvailable: Scalars['Boolean'];
	sessionId: Scalars['String'];
	sessionMeta?: Maybe<Scalars['Json']>;
	/** session type (eg. LIVE | ON_DEMAND) */
	sessionType: Scalars['String'];
	startAt: Scalars['DateTime'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	wpCohortId?: Maybe<Scalars['Float']>;
	wpCohortSlug?: Maybe<Scalars['String']>;
};

export type CaseStudy = {
	__typename?: 'CaseStudy';
	bodyText: Scalars['String'];
	caseStudyId: Scalars['String'];
	coverImageUrl: Scalars['String'];
	publishedAt: Scalars['String'];
	status: Scalars['String'];
	title: Scalars['String'];
	video: Scalars['String'];
};

export type Certificate = {
	__typename?: 'Certificate';
	badgeImageUrl?: Maybe<Scalars['String']>;
	certificateId: Scalars['String'];
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	groups: Array<CertificateGroup>;
	/** Current certificate completion status for the user making the request. */
	isCompleted?: Maybe<Scalars['Boolean']>;
	/** Current certificate registration status for the user making the request. */
	isRegistered?: Maybe<Scalars['Boolean']>;
	meta?: Maybe<Scalars['Json']>;
	numElectiveCourses: Scalars['Float'];
	primaryColor: Scalars['String'];
	publishStatus: Scalars['String'];
	slug: Scalars['String'];
	title: Scalars['String'];
	type: CertificateType;
};

export type CertificateGroup = {
	__typename?: 'CertificateGroup';
	certificateGroupId: Scalars['String'];
	courses: Array<Maybe<CertificateGroupCourse>>;
	description?: Maybe<Scalars['String']>;
	/** Indicates if a capstone course is included in the group */
	isCapstoneGroup: Scalars['Boolean'];
	meta?: Maybe<Scalars['Json']>;
	publishStatus: Scalars['String'];
	title: Scalars['String'];
};

export type CertificateGroupCourse = {
	__typename?: 'CertificateGroupCourse';
	completionRequired: Scalars['Boolean'];
	courseId: Scalars['String'];
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	liveOnly: Scalars['Boolean'];
	/** Extra information about the course in the context of the current certificate. This is a specifically a certificate-course field, it is not the course's meta field. */
	meta?: Maybe<Scalars['Json']>;
	publishStatus: Scalars['String'];
	published?: Maybe<Scalars['DateTime']>;
	sortOrder: Scalars['Float'];
	status?: Maybe<CourseStatus>;
	subtitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
};

/** Certificate type signifying origin and audience (eg. OPEN certificates are created for the widest audience, CUSTOMER certificates are created for enterprise customers) */
export enum CertificateType {
	Customer = 'CUSTOMER',
	Open = 'OPEN'
}

export type Company = {
	__typename?: 'Company';
	name?: Maybe<Scalars['String']>;
	userCompanyId?: Maybe<Scalars['String']>;
};

export type CourseBookmarkType = {
	__typename?: 'CourseBookmarkType';
	courseId: Scalars['String'];
};

export type CourseContent = {
	__typename?: 'CourseContent';
	completionRequired: Scalars['Boolean'];
	courseContentId: Scalars['String'];
	/** Description of course content */
	description?: Maybe<Scalars['String']>;
	/** Toggle for displaying this course content artifact */
	hidePreview: Scalars['Boolean'];
	/** Extended info about this course content artifact */
	meta: Scalars['Json'];
	/** Identifier for this course content artifact */
	slug: Scalars['String'];
	/** Title for course content */
	title?: Maybe<Scalars['String']>;
	type: CourseContentType;
	/** Course content video information, if applicable */
	video?: Maybe<CourseContentVideo>;
};

/** Corresponds to platform course table */
export type CourseContentContainer = {
	__typename?: 'CourseContentContainer';
	courseId: Scalars['String'];
	courseMeta?: Maybe<Scalars['Json']>;
	/** Course type (eg. Sprint) */
	courseType: Scalars['String'];
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	faculties: Array<Faculty>;
	groups: Array<CourseContentGroup>;
	hidePreview: Scalars['Boolean'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
};

export type CourseContentGroup = {
	__typename?: 'CourseContentGroup';
	content: Array<CourseContent>;
	courseContentGroupId: Scalars['String'];
	/** Description for a group of related content, per course */
	description?: Maybe<Scalars['String']>;
	hidePreview: Scalars['Boolean'];
	meta?: Maybe<Scalars['Json']>;
	/** Identifier for a group of related content, per course */
	slug: Scalars['String'];
	/** Title for a group of related content, per course */
	title?: Maybe<Scalars['String']>;
};

/** Media type of individual course content item (eg. VIDEO) */
export enum CourseContentType {
	All = 'ALL',
	Document = 'DOCUMENT',
	Video = 'VIDEO'
}

export type CourseContentVideo = {
	__typename?: 'CourseContentVideo';
	aspectHeight?: Maybe<Scalars['Float']>;
	aspectWidth?: Maybe<Scalars['Float']>;
	chapters?: Maybe<Array<CourseContentVideoChapter>>;
	description?: Maybe<Scalars['String']>;
	/** video duration in seconds */
	duration?: Maybe<Scalars['Float']>;
	externalKey?: Maybe<Scalars['String']>;
	meta?: Maybe<Scalars['Json']>;
	slug?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	videoId?: Maybe<Scalars['String']>;
};

export type CourseContentVideoChapter = {
	__typename?: 'CourseContentVideoChapter';
	endSeconds?: Maybe<Scalars['Float']>;
	/** Identifier for this video chapter */
	slug: Scalars['String'];
	startSeconds?: Maybe<Scalars['Float']>;
	title: Scalars['String'];
	videoChapterId?: Maybe<Scalars['String']>;
	videoId?: Maybe<Scalars['String']>;
};

/** Course status (eg. DRAFT, PUBLISHED, ARCHIVED) */
export enum CourseStatus {
	Archived = 'ARCHIVED',
	Draft = 'DRAFT',
	Published = 'PUBLISHED'
}

export type CreateSignedUploadUrlFields = {
	__typename?: 'CreateSignedUploadUrlFields';
	acl: Scalars['String'];
	key: Scalars['String'];
	policy: Scalars['String'];
	xAmzAlgorithm: Scalars['String'];
	xAmzCredential: Scalars['String'];
	xAmzDate: Scalars['String'];
	xAmzSignature: Scalars['String'];
};

export type CreateSignedUploadUrlInput = {
	fileType: Scalars['String'];
	uploadType: Scalars['String'];
};

export type CreateSignedUploadUrlResultType = {
	__typename?: 'CreateSignedUploadUrlResultType';
	fields: CreateSignedUploadUrlFields;
	url: Scalars['String'];
};

export type Customer = {
	__typename?: 'Customer';
	customerId: Scalars['String'];
	name: Scalars['String'];
	type: Scalars['String'];
};

export type EntitledSession = {
	__typename?: 'EntitledSession';
	coverImageUrl: Scalars['String'];
	endAt: Scalars['String'];
	sessionId: Scalars['String'];
	startAt: Scalars['String'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
};

/** enum items categorizing entitlements */
export enum EntitlementType {
	Area = 'AREA',
	Course = 'COURSE',
	Session = 'SESSION'
}

export type EventCategory = {
	__typename?: 'EventCategory';
	category: Scalars['String'];
	label: Scalars['String'];
};

export type EventType = {
	__typename?: 'EventType';
	description: Scalars['String'];
	displayOrder: Scalars['Float'];
	label: Scalars['String'];
	type: Scalars['String'];
};

export type Faculty = {
	__typename?: 'Faculty';
	biography?: Maybe<Scalars['String']>;
	facultyId: Scalars['String'];
	fullName: Scalars['String'];
	imageUrl?: Maybe<Scalars['String']>;
	sortOrder: Scalars['Float'];
};

export type GetUserSessionInput = {
	sessionId: Scalars['String'];
};

export type IndustryRef = {
	__typename?: 'IndustryRef';
	active: Scalars['String'];
	industry: Scalars['String'];
	industryRefId: Scalars['String'];
	sortOrder: Scalars['String'];
};

export type JobFunctionRef = {
	__typename?: 'JobFunctionRef';
	active: Scalars['String'];
	jobFunction: Scalars['String'];
	jobFunctionRefId: Scalars['String'];
	sortOrder: Scalars['String'];
};

export type Language = {
	__typename?: 'Language';
	name: Scalars['String'];
};

export type ListUserSessionsByCourseInput = {
	courseId: Scalars['String'];
};

export type Location = {
	__typename?: 'Location';
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	modified?: Maybe<Scalars['DateTime']>;
	primaryLocation?: Maybe<Scalars['Boolean']>;
	region?: Maybe<Scalars['String']>;
	userLocationId?: Maybe<Scalars['String']>;
};

/** Logout user, removing SSO-pool records from AWS Cognito */
export type LogoutResult = {
	__typename?: 'LogoutResult';
	success: Scalars['Boolean'];
};

export type MemberCommunitySaveResultType = {
	__typename?: 'MemberCommunitySaveResultType';
	about: Scalars['String'];
	communityGet: Scalars['String'];
	communityGive: Scalars['String'];
	error?: Maybe<MemberCommunitySavesError>;
	growthTip: Scalars['String'];
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	userId: Scalars['String'];
	whatILove: Scalars['String'];
};

export type MemberCommunitySavesError = {
	__typename?: 'MemberCommunitySavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type MemberCompanySaveResultType = {
	__typename?: 'MemberCompanySaveResultType';
	error?: Maybe<MemberCompanySavesError>;
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	userCompanyId: Scalars['String'];
	userId: Scalars['String'];
};

export type MemberCompanySavesError = {
	__typename?: 'MemberCompanySavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type MemberEntitlement = {
	__typename?: 'MemberEntitlement';
	customization?: Maybe<PlatformCustomization>;
	entitlementId: Scalars['String'];
	label: Scalars['String'];
	precedence?: Maybe<Scalars['Float']>;
	type: EntitlementType;
};

export type MemberEvent = {
	__typename?: 'MemberEvent';
	details: Scalars['String'];
	eventCategory: EventCategory;
	eventEnd: Scalars['String'];
	eventStart: Scalars['String'];
	eventType: EventType;
	heroImageUrl: Scalars['String'];
	memberEventId: Scalars['String'];
	status: Scalars['String'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	zoomLink?: Maybe<Scalars['String']>;
};

export type MemberEventRsvpSaveResultType = {
	__typename?: 'MemberEventRsvpSaveResultType';
	error?: Maybe<MemberEventRsvpSavesError>;
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	updatedMemberEventRsvpId?: Maybe<Scalars['String']>;
};

export type MemberEventRsvpSavesError = {
	__typename?: 'MemberEventRsvpSavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type MemberEventsRsvp = {
	__typename?: 'MemberEventsRsvp';
	memberEventId?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	zoomJoinUrl?: Maybe<Scalars['String']>;
	zoomRegistrantId?: Maybe<Scalars['String']>;
};

export type MemberLocationSaveResultType = {
	__typename?: 'MemberLocationSaveResultType';
	error?: Maybe<MemberLocationSavesError>;
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	updatedLocationId: Scalars['String'];
	userId: Scalars['String'];
};

export type MemberLocationSavesError = {
	__typename?: 'MemberLocationSavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type MemberSaveResultType = {
	__typename?: 'MemberSaveResultType';
	error?: Maybe<MemberSavesError>;
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	userId: Scalars['String'];
};

export type MemberSavesError = {
	__typename?: 'MemberSavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type Mutation = {
	__typename?: 'Mutation';
	/** Mark the authenticated user completed for a certificate program. */
	addCertificateCompletion: UserCertificateResult;
	/** Register the authenticated user for a certificate program. */
	addCertificateRegistration: UserCertificateResult;
	addUserSave: UserSavesResultType;
	createSignedUploadUrl: CreateSignedUploadUrlResultType;
	createStripePortalUrl: Scalars['String'];
	createStripeSubscriptionCheckoutSession: Scalars['String'];
	createUserWithPass: PublicPassRegistrationResult;
	getShareLinks: Array<ShareLink>;
	logout: LogoutResult;
	lookupActivationLink: PublicPassActivationLookupResult;
	/** Unmark the authenticated user completed for a certificate program. */
	removeCertificateCompletion: UserCertificateResult;
	/** Unregister the authenticated user for a certificate program. */
	removeCertificateRegistration: UserCertificateResult;
	removeUserSave: UserSavesResultType;
	saveEnrollment: UserSession;
	saveMemberBasicInfo: MemberSaveResultType;
	saveMemberCommunity: MemberCommunitySaveResultType;
	saveMemberCompany: MemberCompanySaveResultType;
	saveMemberEventRsvp: MemberEventRsvpSaveResultType;
	saveMemberJobInfo: MemberSaveResultType;
	saveMemberLocation: MemberLocationSaveResultType;
	saveMemberSocialInfo: MemberSaveResultType;
	sendVerificationCode: SendVerificationCodeResult;
	startAccountLinking: StartAccountLinkingResult;
	unlinkAccount: UnlinkAccountResult;
	updateExistingStripeSubscription: UpdateSubscriptionResult;
	updateVideoProgress: UserVideoProgress;
};


export type MutationAddCertificateCompletionArgs = {
	certificateId: Scalars['String'];
};


export type MutationAddCertificateRegistrationArgs = {
	certificateId: Scalars['String'];
};


export type MutationAddUserSaveArgs = {
	contentId: Scalars['String'];
	contentType: Scalars['String'];
};


export type MutationCreateSignedUploadUrlArgs = {
	input: CreateSignedUploadUrlInput;
};


export type MutationCreateStripePortalUrlArgs = {
	configId: Scalars['String'];
};


export type MutationCreateUserWithPassArgs = {
	code: Scalars['String'];
	company?: InputMaybe<Scalars['String']>;
	companyNumberOfEmployees?: InputMaybe<Scalars['String']>;
	email: Scalars['String'];
	firstName: Scalars['String'];
	jobLevel?: InputMaybe<Scalars['String']>;
	jobTitle?: InputMaybe<Scalars['String']>;
	lastName: Scalars['String'];
	phone?: InputMaybe<Scalars['String']>;
};


export type MutationLookupActivationLinkArgs = {
	code: Scalars['String'];
	email: Scalars['String'];
	tac: Scalars['String'];
};


export type MutationRemoveCertificateCompletionArgs = {
	certificateId: Scalars['String'];
};


export type MutationRemoveCertificateRegistrationArgs = {
	certificateId: Scalars['String'];
};


export type MutationRemoveUserSaveArgs = {
	contentId: Scalars['String'];
	contentType: Scalars['String'];
};


export type MutationSaveEnrollmentArgs = {
	input: SaveEnrollmentInput;
};


export type MutationSaveMemberBasicInfoArgs = {
	input: SaveMemberBasicInfoInput;
};


export type MutationSaveMemberCommunityArgs = {
	input: SaveMemberCommunityInput;
};


export type MutationSaveMemberCompanyArgs = {
	input: SaveMemberCompanyInput;
};


export type MutationSaveMemberEventRsvpArgs = {
	input: SaveMemberEventRsvpInput;
};


export type MutationSaveMemberJobInfoArgs = {
	input: SaveMemberJobInfoInput;
};


export type MutationSaveMemberLocationArgs = {
	input: SaveMemberLocationInput;
};


export type MutationSaveMemberSocialInfoArgs = {
	input: SaveMemberSocialInput;
};


export type MutationSendVerificationCodeArgs = {
	b64email: Scalars['String'];
	b64tac: Scalars['String'];
};


export type MutationStartAccountLinkingArgs = {
	email: Scalars['String'];
	userName: Scalars['String'];
};


export type MutationUpdateExistingStripeSubscriptionArgs = {
	coupon: Scalars['String'];
};


export type MutationUpdateVideoProgressArgs = {
	continueAtTime: Scalars['Float'];
	videoId: Scalars['String'];
};

export type PageContent = {
	__typename?: 'PageContent';
	/** Two-letter (ISO-639-1) language code for a page content item (eg. en) */
	langCode: Scalars['String'];
	meta: Scalars['Json'];
	publishStatus: Scalars['String'];
	slug: Scalars['String'];
};

/** Two-letter (ISO-639-1) language code for a page content item (eg. en) */
export enum PageContentLanguageCode {
	En = 'en'
}

export type PageInfo = {
	__typename?: 'PageInfo';
	count: Scalars['Int'];
	filter?: Maybe<Scalars['String']>;
	page: Scalars['Int'];
	total: Scalars['Int'];
	totalPages: Scalars['Int'];
};

export type PaginatedPublicMemberList = {
	__typename?: 'PaginatedPublicMemberList';
	list: Array<PublicMember>;
	pageInfo: PageInfo;
};

export type PaginatedResourceList = {
	__typename?: 'PaginatedResourceList';
	list: Array<Resource>;
	pageInfo: PageInfo;
};

/** Context for making a pass query; used to make additional adjustments to response. */
export enum PassQueryContext {
	InvitePreview = 'INVITE_PREVIEW',
	InviteRegistration = 'INVITE_REGISTRATION',
	TopNav = 'TOP_NAV'
}

export type PlatformCustomization = {
	__typename?: 'PlatformCustomization';
	navLogoUrl?: Maybe<Scalars['String']>;
};

export type PrivateMember = {
	__typename?: 'PrivateMember';
	about?: Maybe<Scalars['String']>;
	accountType: Scalars['String'];
	alternateSSOEmail?: Maybe<Scalars['String']>;
	communityGet?: Maybe<Scalars['String']>;
	communityGive?: Maybe<Scalars['String']>;
	company: Company;
	customer?: Maybe<Customer>;
	displayName?: Maybe<Scalars['String']>;
	email: Scalars['String'];
	firstName?: Maybe<Scalars['String']>;
	growthTip?: Maybe<Scalars['String']>;
	hasOngoingSprintAccess: Scalars['Boolean'];
	industry?: Maybe<Scalars['String']>;
	instagramProfileUrl?: Maybe<Scalars['String']>;
	jobFunction?: Maybe<Scalars['String']>;
	jobRole?: Maybe<Scalars['String']>;
	languages: Array<Language>;
	lastName?: Maybe<Scalars['String']>;
	linkedinProfileUrl?: Maybe<Scalars['String']>;
	location: Location;
	manageBilling: Scalars['Boolean'];
	meetPreference?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	profileImageUrl?: Maybe<Scalars['String']>;
	pronouns?: Maybe<Scalars['String']>;
	registrationDate: Scalars['DateTime'];
	scholarship?: Maybe<Scholarship>;
	status: Scalars['String'];
	testData: Scalars['Boolean'];
	trialEndDate?: Maybe<Scalars['DateTime']>;
	twitterProfileUrl?: Maybe<Scalars['String']>;
	userId: Scalars['String'];
	userType: Scalars['String'];
	whatILove?: Maybe<Scalars['String']>;
	wpUserId?: Maybe<Scalars['Int']>;
};

export type PublicMember = {
	__typename?: 'PublicMember';
	about?: Maybe<Scalars['String']>;
	accountType: Scalars['String'];
	communityGet?: Maybe<Scalars['String']>;
	communityGive?: Maybe<Scalars['String']>;
	company: Company;
	displayName?: Maybe<Scalars['String']>;
	email: Scalars['String'];
	firstName?: Maybe<Scalars['String']>;
	growthTip?: Maybe<Scalars['String']>;
	industry?: Maybe<Scalars['String']>;
	instagramProfileUrl?: Maybe<Scalars['String']>;
	jobFunction?: Maybe<Scalars['String']>;
	jobRole?: Maybe<Scalars['String']>;
	languages: Array<Language>;
	lastName?: Maybe<Scalars['String']>;
	linkedinProfileUrl?: Maybe<Scalars['String']>;
	location: Location;
	meetPreference?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	profileImageUrl?: Maybe<Scalars['String']>;
	pronouns?: Maybe<Scalars['String']>;
	registrationDate: Scalars['DateTime'];
	status: Scalars['String'];
	testData: Scalars['Boolean'];
	twitterProfileUrl?: Maybe<Scalars['String']>;
	userId: Scalars['String'];
	userType: Scalars['String'];
	whatILove?: Maybe<Scalars['String']>;
	wpUserId?: Maybe<Scalars['Int']>;
};

/** Result after polling for an activation link. Success indicates that a valid link can be displayed in the frontend. */
export type PublicPassActivationLookupResult = {
	__typename?: 'PublicPassActivationLookupResult';
	existingUser: Scalars['Boolean'];
	/** Activation link that will kick off a set-password flow */
	link?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
};

export type PublicPassCourseContent = {
	__typename?: 'PublicPassCourseContent';
	courseContentId?: Maybe<Scalars['String']>;
	/** Description of course content */
	description?: Maybe<Scalars['String']>;
	/** Identifier for this course content artifact */
	slug?: Maybe<Scalars['String']>;
	/** Title for course content */
	title?: Maybe<Scalars['String']>;
	type?: Maybe<CourseContentType>;
	/** Course content video information without chapters */
	video?: Maybe<PublicPassCourseContentVideo>;
	videoThumbnail?: Maybe<Scalars['String']>;
};

export type PublicPassCourseContentVideo = {
	__typename?: 'PublicPassCourseContentVideo';
	aspectHeight?: Maybe<Scalars['Float']>;
	aspectWidth?: Maybe<Scalars['Float']>;
	description?: Maybe<Scalars['String']>;
	/** video duration in seconds */
	duration?: Maybe<Scalars['Float']>;
	externalKey?: Maybe<Scalars['String']>;
	meta?: Maybe<Scalars['Json']>;
	slug?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	videoId?: Maybe<Scalars['String']>;
};

export type PublicPassDetails = {
	__typename?: 'PublicPassDetails';
	autoEnrollSessionId?: Maybe<Scalars['String']>;
	content: Array<PublicPassCourseContent>;
	/** Extended info about pass (eg. pass-specific text fields) */
	meta: Scalars['Json'];
	passType: Scalars['String'];
	/** Redirect URL after pass is activated, if specified */
	redirectUrl?: Maybe<Scalars['String']>;
	showRegistration: Scalars['Boolean'];
	targetSegment: Scalars['String'];
};

export type PublicPassRegistrationError = {
	__typename?: 'PublicPassRegistrationError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

/** Preliminary result indicating acceptance of request to create a pass user. If successful, a Create User job is enqueued and handled asynchronously. */
export type PublicPassRegistrationResult = {
	__typename?: 'PublicPassRegistrationResult';
	error?: Maybe<PublicPassRegistrationError>;
	result: RegistrationConfirmationState;
	/** Temporary activation code (UUIDv4) to link an activation button request, if necessary. */
	tac?: Maybe<Scalars['String']>;
};

export type Query = {
	__typename?: 'Query';
	checkIfStripeScheduledSubscription: Scalars['Boolean'];
	checkStripeSubscriptionDiscountOffer: SubscriptionDiscountOfferResult;
	/** Retrieve a single certificate. Only certificates visible to the authenticated user will be returned. */
	getCertificate?: Maybe<Certificate>;
	getEntitledBootcampSessions: Array<EntitledSession>;
	/** Get a member by userId. If a userId is passed as an argument, it will be used. Otherwise, the userId from the JWT will be used. */
	getMember: PrivateMember;
	/** Get the list of member entitlements by userId. If a userId is passed as an argument, it will be used. Otherwise, the userId from the JWT will be used. */
	getMemberEntitlements: Array<MemberEntitlement>;
	getMemberEvent: MemberEvent;
	getMemberEventRsvp: MemberEventsRsvp;
	/** CourseContent is the top level of on-demand content in the database while the Sprint Center and its Wordpress CMS coexist with the platform. This query's name uses "On-demand" to make what it returns more obvious to the frontend. */
	getOnDemandContent: CourseContentContainer;
	getPageContent: PageContent;
	getPassDetailsByCode: PublicPassDetails;
	getResource: Resource;
	getSessionContainerContent: Array<SessionContainerContent>;
	getSessionContent: SessionContent;
	getSessionContentVideos: Array<SessionContentVideo>;
	getSessionLink: Scalars['String'];
	getUserSession: UserSession;
	getVideoProgress: UserVideoProgress;
	getVideoProgressByCourse: UserVideoProgressByCourse;
	getVideoProgressBySession: UserVideoProgressBySession;
	listCaseStudies: Array<CaseStudy>;
	/** List all certificates visible to the authenticated user. */
	listCertificates: Array<Certificate>;
	listCourseBookmarks: Array<CourseBookmarkType>;
	listCourseContent: Array<CourseContentContainer>;
	listIndustryReferences: Array<IndustryRef>;
	listJobFunctionReferences: Array<JobFunctionRef>;
	listMemberEvents: Array<MemberEvent>;
	listMemberEventsRsvp: Array<MemberEventsRsvp>;
	listMembers: PaginatedPublicMemberList;
	listPastSessions: Array<BaseSession>;
	listResources: PaginatedResourceList;
	listUserSessions: Array<UserSession>;
	listUserSessionsByCourse: Array<UserSession>;
	searchCourses: Array<SearchCoursesResult>;
};


export type QueryGetCertificateArgs = {
	certificateId: Scalars['String'];
};


export type QueryGetMemberArgs = {
	userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberEntitlementsArgs = {
	userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberEventArgs = {
	memberEventId: Scalars['String'];
};


export type QueryGetMemberEventRsvpArgs = {
	memberEventId: Scalars['String'];
	userId: Scalars['String'];
};


export type QueryGetOnDemandContentArgs = {
	courseId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPageContentArgs = {
	langCode?: InputMaybe<PageContentLanguageCode>;
	publishStatuses?: InputMaybe<Array<Scalars['String']>>;
	slug: Scalars['String'];
};


export type QueryGetPassDetailsByCodeArgs = {
	code: Scalars['String'];
	context: PassQueryContext;
};


export type QueryGetResourceArgs = {
	resourceID?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
};


export type QueryGetSessionContainerContentArgs = {
	sessionContentIds: Array<Scalars['String']>;
};


export type QueryGetSessionContentArgs = {
	sessionId?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
};


export type QueryGetSessionContentVideosArgs = {
	sessionId: Scalars['String'];
	videoGroupId: Scalars['String'];
};


export type QueryGetSessionLinkArgs = {
	date: Scalars['DateTime'];
	forceRefresh?: InputMaybe<Scalars['Boolean']>;
	sessionId: Scalars['String'];
	sessionTitle: Scalars['String'];
};


export type QueryGetUserSessionArgs = {
	where: GetUserSessionInput;
};


export type QueryGetVideoProgressArgs = {
	videoId: Scalars['String'];
};


export type QueryGetVideoProgressByCourseArgs = {
	courseId: Scalars['String'];
};


export type QueryGetVideoProgressBySessionArgs = {
	sessionId?: InputMaybe<Scalars['String']>;
};


export type QueryListCaseStudiesArgs = {
	caseStudyId?: InputMaybe<Scalars['String']>;
};


export type QueryListCourseContentArgs = {
	courseId?: InputMaybe<Scalars['String']>;
	groupSlug?: InputMaybe<Scalars['String']>;
	listAll?: InputMaybe<Scalars['Boolean']>;
	type?: InputMaybe<CourseContentType>;
};


export type QueryListMemberEventsArgs = {
	category?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Scalars['String']>;
};


export type QueryListMemberEventsRsvpArgs = {
	includePastEvents: Scalars['Boolean'];
	userId: Scalars['String'];
};


export type QueryListMembersArgs = {
	context?: InputMaybe<QueryContext>;
	count: Scalars['Int'];
	filter?: InputMaybe<Scalars['String']>;
	page: Scalars['Int'];
};


export type QueryListResourcesArgs = {
	activeOnly?: InputMaybe<Scalars['Boolean']>;
	count?: InputMaybe<Scalars['Int']>;
	page?: InputMaybe<Scalars['Int']>;
	resourceIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryListUserSessionsByCourseArgs = {
	where: ListUserSessionsByCourseInput;
};


export type QuerySearchCoursesArgs = {
	query: SearchCoursesInput;
};

/** Context for making the query; used to make additional adjustments to filter criteria or sorting. QueryContext.ADMIN (default): all active members. QueryContext.MEMBERSPAGE: filters out users with less complete profiles for nicer presentation. QueryContext.HOMEPAGE: even stricter filter, profiles without profile pictures are excluded. */
export enum QueryContext {
	Admin = 'ADMIN',
	Homepage = 'HOMEPAGE',
	Memberspage = 'MEMBERSPAGE'
}

/** Tagged record object, usually found as the `tags` field on a tagged record. */
export type RecordTag = {
	__typename?: 'RecordTag';
	/** Tag category (optional, some categories are uncategorized) */
	category?: Maybe<Scalars['String']>;
	label: Scalars['String'];
	/** The ID (usually primary key) of the record being tagged */
	recordID?: Maybe<Scalars['String']>;
	tagID?: Maybe<Scalars['String']>;
};

/** Multi-state outcome from pass registration. "Confirmed with activation" signals that the frontend should use the provided temporary access code (tac, obfuscated intentionally) to retrieve a link URL for redirect to the Member Center set-password page. */
export enum RegistrationConfirmationState {
	ConfirmedWithoutActivation = 'CONFIRMED_WITHOUT_ACTIVATION',
	ConfirmedWithActivation = 'CONFIRMED_WITH_ACTIVATION',
	Error = 'ERROR'
}

/** Resource top-level object. Contains resource_content sorted by sort_order. */
export type Resource = {
	__typename?: 'Resource';
	coverImageUrl?: Maybe<Scalars['String']>;
	created?: Maybe<Scalars['String']>;
	/** google_doc_copy_url is stored as a meta field */
	googleDocCopyUrl?: Maybe<Scalars['String']>;
	items?: Maybe<Array<ResourceContent>>;
	/** ISO 639-1 language code (db default 'en') */
	lang?: Maybe<Scalars['String']>;
	modified?: Maybe<Scalars['String']>;
	/** pdf_download_url is stored as a meta field */
	pdfDownloadUrl?: Maybe<Scalars['String']>;
	resourceID: Scalars['String'];
	slug: Scalars['String'];
	status?: Maybe<Scalars['String']>;
	/** subtitle is stored as a meta field */
	subtitle?: Maybe<Scalars['String']>;
	tags: Array<RecordTag>;
	title: Scalars['String'];
	type: Scalars['String'];
};

/** Resource content objects, these are the child records for resource records. SQL will return each resource]'s resource_content in ascending order by sort_order. */
export type ResourceContent = {
	__typename?: 'ResourceContent';
	/** Optional name for linking via URL. */
	anchorName?: Maybe<Scalars['String']>;
	/** Most resource content records will use this field to store Markdown. May be undefined for certain types. */
	content?: Maybe<Scalars['String']>;
	created: Scalars['String'];
	/** For image types, this is the URL of the image. This field is stored in meta. */
	imageUrl?: Maybe<Scalars['String']>;
	modified: Scalars['String'];
	resourceContentID: Scalars['String'];
	resourceID?: Maybe<Scalars['String']>;
	sortOrder?: Maybe<Scalars['String']>;
	type: Scalars['String'];
};

export type SaveEnrollmentInput = {
	sessionId: Scalars['String'];
	unenroll?: InputMaybe<Scalars['Boolean']>;
	userId: Scalars['String'];
};

export type SaveMemberBasicInfoInput = {
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	middleName: Scalars['String'];
	profileImageUrl: Scalars['String'];
	pronouns: Scalars['String'];
	userId: Scalars['String'];
};

export type SaveMemberCommunityInput = {
	about: Scalars['String'];
	communityGet: Scalars['String'];
	communityGive: Scalars['String'];
	growthTip: Scalars['String'];
	userId: Scalars['String'];
	whatILove: Scalars['String'];
};

export type SaveMemberCompanyInput = {
	companyName: Scalars['String'];
	userCompanyId?: InputMaybe<Scalars['String']>;
	userId: Scalars['String'];
};

export type SaveMemberEventRsvpInput = {
	memberEventId: Scalars['String'];
	status: Scalars['String'];
	userId: Scalars['String'];
};

export type SaveMemberJobInfoInput = {
	industry: Scalars['String'];
	jobFunction?: InputMaybe<Scalars['String']>;
	jobRole: Scalars['String'];
	userId: Scalars['String'];
};

export type SaveMemberLocationInput = {
	city: Scalars['String'];
	country: Scalars['String'];
	region: Scalars['String'];
	userId: Scalars['String'];
	userLocationId: Scalars['String'];
};

export type SaveMemberSocialInput = {
	instagramProfileUrl: Scalars['String'];
	linkedinProfileUrl: Scalars['String'];
	twitterProfileUrl: Scalars['String'];
	userId: Scalars['String'];
};

export type Scholarship = {
	__typename?: 'Scholarship';
	name: Scalars['String'];
	scholarshipId: Scalars['String'];
	type: Scalars['String'];
};

export type SearchCoursesInput = {
	query: Scalars['String'];
};

export type SearchCoursesResult = {
	__typename?: 'SearchCoursesResult';
	courseId: Scalars['String'];
	score: Scalars['Float'];
};

/** Send verification code to user email address if temporary activation code is valid. */
export type SendVerificationCodeResult = {
	__typename?: 'SendVerificationCodeResult';
	success: Scalars['Boolean'];
};

/** Session COLLAPSIBLE_CONTAINER content for session index page (directory of all components of a session) */
export type SessionContainerContent = {
	__typename?: 'SessionContainerContent';
	/** Text in markdown format. Types: RICH_TEXT */
	body?: Maybe<Scalars['String']>;
	/** Text for the button. Types: CTA_BUTTON */
	buttonText?: Maybe<Scalars['String']>;
	/** URL for the button. Types: CTA_BUTTON */
	buttonUrl?: Maybe<Scalars['String']>;
	/** Date basis for 5-day calendar component. Types: CALENDAR */
	calendarStartDate?: Maybe<Scalars['String']>;
	/** Text for day 1 of 5-day calendar component. Types: CALENDAR */
	day1Text?: Maybe<Scalars['String']>;
	/** Text for day 2 of 5-day calendar component. Types: CALENDAR */
	day2Text?: Maybe<Scalars['String']>;
	/** Text for day 3 of 5-day calendar component. Types: CALENDAR */
	day3Text?: Maybe<Scalars['String']>;
	/** Text for day 4 of 5-day calendar component. Types: CALENDAR */
	day4Text?: Maybe<Scalars['String']>;
	/** Text for day 5 of 5-day calendar component. Types: CALENDAR */
	day5Text?: Maybe<Scalars['String']>;
	/** Timestamp after which the component should be fully enabled. Types: VIDEO_GROUP */
	enabledAt?: Maybe<Scalars['String']>;
	/** Intro (top) text in markdown format. Types: VIDEO_GROUP, CTA_BUTTON */
	intro?: Maybe<Scalars['String']>;
	/** Outro (bottom) text in markdown format. Types: VIDEO_GROUP, CTA_BUTTON */
	outro?: Maybe<Scalars['String']>;
	parentSessionContentId: Scalars['String'];
	sessionContentId: Scalars['String'];
	/** Content title in plaintext. Container content types will not display this value. */
	title: Scalars['String'];
	/** Type of content in this container. Types: RICH_TEXT, VIDEO_GROUP, CTA_BUTTON */
	type: SessionContentType;
	videoPreviews?: Maybe<Array<SessionContentVideoPreview>>;
};

/** Session content for session index page (directory of all component of a session) */
export type SessionContent = {
	__typename?: 'SessionContent';
	announcements: Array<SessionContentAnnouncement>;
	containers: Array<SessionContentContainer>;
	courseId: Scalars['String'];
	courseType: Scalars['String'];
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	/** End time of the session. */
	sessionEndAt: Scalars['String'];
	sessionId: Scalars['String'];
	/** Start time of the session. */
	sessionStartAt: Scalars['String'];
	sessionType: Scalars['String'];
	subtitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

/** Announcements at the top of the session index page. */
export type SessionContentAnnouncement = {
	__typename?: 'SessionContentAnnouncement';
	/** Announcement text in markdown format */
	announcement?: Maybe<Scalars['String']>;
	externalLinkUrl?: Maybe<Scalars['String']>;
	imageUrl?: Maybe<Scalars['String']>;
	internalLinkUrl?: Maybe<Scalars['String']>;
	sessionContentId: Scalars['String'];
	/** convention for specifying styles related to the colors and fonts in the announcement */
	stylePreset?: Maybe<Scalars['String']>;
};

/** Containers on session index page. Make a separate query to get the contents of each container. */
export type SessionContentContainer = {
	__typename?: 'SessionContentContainer';
	containsActiveContent: Scalars['Boolean'];
	expandedPlaceholder?: Maybe<Scalars['String']>;
	sessionContentId: Scalars['String'];
	title: Scalars['String'];
};

export enum SessionContentType {
	Announcement = 'ANNOUNCEMENT',
	Calendar = 'CALENDAR',
	CollapsibleContainer = 'COLLAPSIBLE_CONTAINER',
	CtaButton = 'CTA_BUTTON',
	RichText = 'RICH_TEXT',
	VideoGroup = 'VIDEO_GROUP'
}

/** Sort_order-sorted video info for VIDEO_GROUP video pages. */
export type SessionContentVideo = {
	__typename?: 'SessionContentVideo';
	aspectHeight?: Maybe<Scalars['Float']>;
	aspectWidth?: Maybe<Scalars['Float']>;
	chapters: Array<VideoChapter>;
	/** from session_content_video mapping meta */
	description?: Maybe<Scalars['String']>;
	/** video duration in seconds */
	duration: Scalars['Float'];
	externalKey: Scalars['String'];
	thumbnailUrl?: Maybe<Scalars['String']>;
	/** from session_content_video mapping meta */
	title?: Maybe<Scalars['String']>;
	videoId: Scalars['String'];
};

/** Sort_order-sorted video info for VIDEO_GROUP session content. Query user progress separately */
export type SessionContentVideoPreview = {
	__typename?: 'SessionContentVideoPreview';
	duration: Scalars['Float'];
	sortOrder?: Maybe<Scalars['Float']>;
	thumbnailUrl: Scalars['String'];
	videoId: Scalars['String'];
};

export type ShareLink = {
	__typename?: 'ShareLink';
	program: Scalars['String'];
	url: Scalars['String'];
};

/** Progress towards account linking. */
export type StartAccountLinkingResult = {
	__typename?: 'StartAccountLinkingResult';
	email: Scalars['String'];
	error?: Maybe<UserAuthError>;
	result: UserAuthState;
};

export type SubscriptionDiscountOfferResult = {
	__typename?: 'SubscriptionDiscountOfferResult';
	acceptCta: Scalars['String'];
	cancelConfigId: Scalars['String'];
	couponCode: Scalars['String'];
	headline: Scalars['String'];
	newPrice: Scalars['Float'];
	normalPrice: Scalars['String'];
	offer: Scalars['String'];
	rejectCta: Scalars['String'];
	subheadline: Scalars['String'];
};

/** Remove account linking from account. */
export type UnlinkAccountResult = {
	__typename?: 'UnlinkAccountResult';
	email: Scalars['String'];
	error?: Maybe<UserAuthError>;
	result: UserAuthState;
};

export type UpdateSubscriptionResult = {
	__typename?: 'UpdateSubscriptionResult';
	currentPeriodEnd: Scalars['Float'];
	currentPrice: Scalars['Float'];
	discountPrice: Scalars['Float'];
};

export type UserAuthError = {
	__typename?: 'UserAuthError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

/** Multi-state outcome for user auth. */
export enum UserAuthState {
	Error = 'ERROR',
	Pending = 'PENDING',
	Unlinked = 'UNLINKED',
	Verified = 'VERIFIED'
}

/** Result of adding or removing certificate registrations or completions. Only REGISTERED users may move to COMPLETED. */
export enum UserCertificateResult {
	Completed = 'COMPLETED',
	Ineligible = 'INELIGIBLE',
	Registered = 'REGISTERED',
	Unregistered = 'UNREGISTERED'
}

export type UserSavesError = {
	__typename?: 'UserSavesError';
	code: Scalars['Float'];
	message: Scalars['String'];
};

export type UserSavesResultType = {
	__typename?: 'UserSavesResultType';
	contentId: Scalars['String'];
	error?: Maybe<UserSavesError>;
	status?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	userId: Scalars['String'];
};

export type UserSession = {
	__typename?: 'UserSession';
	courseBadgeId?: Maybe<Scalars['String']>;
	courseId: Scalars['String'];
	courseMeta?: Maybe<Scalars['Json']>;
	/** course type (eg. SPRINT | WORKSHOP) */
	courseType: Scalars['String'];
	courseTypeMeta?: Maybe<Scalars['Json']>;
	coverImageUrl?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	endAt: Scalars['DateTime'];
	enrollmentCap?: Maybe<Scalars['Float']>;
	enrollmentClose?: Maybe<Scalars['DateTime']>;
	enrollmentOpen?: Maybe<Scalars['DateTime']>;
	/** One of the following values: ENROLLED (member is enrolled in the session), WAITLISTED (member is on the wait list for this session), FUTURE (enrollment has not opened yet), CLOSED (enrollment has closed), MANUAL (member can not enroll themselves), WAITLIST (member can add themselves to the wait list), ENROLL (member can enroll), COMPLETED (member is enrolled and the session has ended) */
	enrollmentStatus: Scalars['String'];
	faculties: Array<Faculty>;
	/** gated access type (eg. CUSTOMER | NOT_GATED) applied for the course. */
	gatedAccessType: Scalars['String'];
	lowInventory?: Maybe<Scalars['Boolean']>;
	onDemandAvailable: Scalars['Boolean'];
	sessionId: Scalars['String'];
	sessionMeta?: Maybe<Scalars['Json']>;
	/** session type (eg. LIVE | ON_DEMAND) */
	sessionType: Scalars['String'];
	startAt: Scalars['DateTime'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	userId: Scalars['String'];
	wpCohortId?: Maybe<Scalars['Float']>;
	wpCohortSlug?: Maybe<Scalars['String']>;
};

export type UserVideoProgress = {
	__typename?: 'UserVideoProgress';
	continueAtTime: Scalars['Float'];
	farthestWatchedTime: Scalars['Float'];
	userId: Scalars['String'];
	videoId: Scalars['String'];
};

export type UserVideoProgressByCourse = {
	__typename?: 'UserVideoProgressByCourse';
	courseId: Scalars['String'];
	groups: Array<UserVideoProgressByCourseContentGroup>;
	totalDuration: Scalars['Float'];
	totalFarthestWatchedTime: Scalars['Float'];
	userId: Scalars['String'];
};

export type UserVideoProgressByCourseContent = {
	__typename?: 'UserVideoProgressByCourseContent';
	courseContentId: Scalars['String'];
	duration: Scalars['Float'];
	farthestWatchedTime: Scalars['Float'];
	videoId: Scalars['String'];
};

export type UserVideoProgressByCourseContentGroup = {
	__typename?: 'UserVideoProgressByCourseContentGroup';
	courseContentGroupId: Scalars['String'];
	totalDuration: Scalars['Float'];
	totalFarthestWatchedTime: Scalars['Float'];
	videos: Array<UserVideoProgressByCourseContent>;
};

export type UserVideoProgressBySession = {
	__typename?: 'UserVideoProgressBySession';
	groups: Array<UserVideoProgressBySessionVideoGroup>;
	sessionId: Scalars['String'];
	userId: Scalars['String'];
};

export type UserVideoProgressBySessionVideo = {
	__typename?: 'UserVideoProgressBySessionVideo';
	duration: Scalars['Float'];
	farthestWatchedTime: Scalars['Float'];
	/** true if watching the video is not required for completion */
	optional: Scalars['Boolean'];
	videoId: Scalars['String'];
};

export type UserVideoProgressBySessionVideoGroup = {
	__typename?: 'UserVideoProgressBySessionVideoGroup';
	groupSessionContentId: Scalars['String'];
	videos: Array<UserVideoProgressBySessionVideo>;
};

export type VideoChapter = {
	__typename?: 'VideoChapter';
	endSeconds: Scalars['Float'];
	/** Identifier for this video chapter */
	slug: Scalars['String'];
	startSeconds: Scalars['Float'];
	title: Scalars['String'];
	videoChapterId: Scalars['String'];
	videoId: Scalars['String'];
};

export type CourseFacultiesFragment = { __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string };

export type FullResourceFragment = { __typename?: 'Resource', resourceID: string, slug: string, title: string, subtitle?: string, type: string, coverImageUrl?: string, pdfDownloadUrl?: string, googleDocCopyUrl?: string, created?: string, modified?: string, items?: Array<{ __typename?: 'ResourceContent', resourceContentID: string, type: string, content?: string, anchorName?: string, imageUrl?: string, created: string, modified: string }>, tags: Array<{ __typename?: 'RecordTag', category?: string, label: string, tagID?: string, recordID?: string }> };

export type MemberFragment = { __typename?: 'PublicMember', about?: string, accountType: string, communityGet?: string, communityGive?: string, displayName?: string, email: string, firstName?: string, growthTip?: string, industry?: string, instagramProfileUrl?: string, jobFunction?: string, jobRole?: string, lastName?: string, linkedinProfileUrl?: string, meetPreference?: string, middleName?: string, profileImageUrl?: string, pronouns?: string, registrationDate: any, status: string, testData: boolean, twitterProfileUrl?: string, userId: string, userType: string, whatILove?: string, wpUserId?: number, company: { __typename?: 'Company', name?: string, userCompanyId?: string }, languages: Array<{ __typename?: 'Language', name: string }>, location: { __typename?: 'Location', city?: string, country?: string, primaryLocation?: boolean, region?: string, userLocationId?: string } };

export type ResourceTileFragment = { __typename?: 'Resource', resourceID: string, slug: string, title: string, subtitle?: string, type: string, tags: Array<{ __typename?: 'RecordTag', category?: string, label: string, tagID?: string, recordID?: string }> };

export type SessionByUserFragment = { __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> };

export type AddCertificateCompletionMutationVariables = Exact<{
	certificateId: Scalars['String'];
}>;


export type AddCertificateCompletionMutation = { __typename?: 'Mutation', addCertificateCompletion: UserCertificateResult };

export type AddCertificateRegistrationMutationVariables = Exact<{
	certificateId: Scalars['String'];
}>;


export type AddCertificateRegistrationMutation = { __typename?: 'Mutation', addCertificateRegistration: UserCertificateResult };

export type AddUserSaveMutationVariables = Exact<{
	contentId: Scalars['String'];
	contentType: Scalars['String'];
}>;


export type AddUserSaveMutation = { __typename?: 'Mutation', addUserSave: { __typename?: 'UserSavesResultType', success: boolean, contentId: string, error?: { __typename?: 'UserSavesError', code: number, message: string } } };

export type CreateSignedUploadUrlMutationVariables = Exact<{
	input: CreateSignedUploadUrlInput;
}>;


export type CreateSignedUploadUrlMutation = { __typename?: 'Mutation', createSignedUploadUrl: { __typename?: 'CreateSignedUploadUrlResultType', url: string, fields: { __typename?: 'CreateSignedUploadUrlFields', acl: string, key: string, policy: string, xAmzAlgorithm: string, xAmzCredential: string, xAmzDate: string, xAmzSignature: string } } };

export type CreateStripePortalUrlMutationVariables = Exact<{
	configId: Scalars['String'];
}>;


export type CreateStripePortalUrlMutation = { __typename?: 'Mutation', createStripePortalUrl: string };

export type CreateStripeSubscriptionCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeSubscriptionCheckoutSessionMutation = { __typename?: 'Mutation', createStripeSubscriptionCheckoutSession: string };

export type CreateUserWithPassMutationVariables = Exact<{
	code: Scalars['String'];
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	email: Scalars['String'];
	company?: InputMaybe<Scalars['String']>;
	jobTitle?: InputMaybe<Scalars['String']>;
	jobLevel?: InputMaybe<Scalars['String']>;
	companyNumberOfEmployees?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['String']>;
}>;


export type CreateUserWithPassMutation = { __typename?: 'Mutation', createUserWithPass: { __typename?: 'PublicPassRegistrationResult', result: RegistrationConfirmationState, tac?: string, error?: { __typename?: 'PublicPassRegistrationError', code: number, message: string } } };

export type GetShareLinksMutationVariables = Exact<{ [key: string]: never; }>;


export type GetShareLinksMutation = { __typename?: 'Mutation', getShareLinks: Array<{ __typename?: 'ShareLink', program: string, url: string }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResult', success: boolean } };

export type LookupActivationLinkMutationVariables = Exact<{
	code: Scalars['String'];
	email: Scalars['String'];
	tac: Scalars['String'];
}>;


export type LookupActivationLinkMutation = { __typename?: 'Mutation', lookupActivationLink: { __typename?: 'PublicPassActivationLookupResult', success: boolean, link?: string, existingUser: boolean } };

export type RemoveCertificateCompletionMutationVariables = Exact<{
	certificateId: Scalars['String'];
}>;


export type RemoveCertificateCompletionMutation = { __typename?: 'Mutation', removeCertificateCompletion: UserCertificateResult };

export type RemoveCertificateRegistrationMutationVariables = Exact<{
	certificateId: Scalars['String'];
}>;


export type RemoveCertificateRegistrationMutation = { __typename?: 'Mutation', removeCertificateRegistration: UserCertificateResult };

export type RemoveUserSaveMutationVariables = Exact<{
	contentId: Scalars['String'];
	contentType: Scalars['String'];
}>;


export type RemoveUserSaveMutation = { __typename?: 'Mutation', removeUserSave: { __typename?: 'UserSavesResultType', success: boolean, contentId: string, status?: string, error?: { __typename?: 'UserSavesError', code: number, message: string } } };

export type SaveEnrollmentMutationVariables = Exact<{
	input: SaveEnrollmentInput;
}>;


export type SaveEnrollmentMutation = { __typename?: 'Mutation', saveEnrollment: { __typename?: 'UserSession', userId: string, sessionId: string, title: string, subtitle?: string, description?: string, enrollmentStatus: string } };

export type SaveMemberBasicInfoMutationVariables = Exact<{
	input: SaveMemberBasicInfoInput;
}>;


export type SaveMemberBasicInfoMutation = { __typename?: 'Mutation', saveMemberBasicInfo: { __typename?: 'MemberSaveResultType', userId: string, error?: { __typename?: 'MemberSavesError', code: number, message: string } } };

export type SaveMemberCommunityMutationVariables = Exact<{
	input: SaveMemberCommunityInput;
}>;


export type SaveMemberCommunityMutation = { __typename?: 'Mutation', saveMemberCommunity: { __typename?: 'MemberCommunitySaveResultType', userId: string, about: string, whatILove: string, communityGet: string, communityGive: string, growthTip: string, error?: { __typename?: 'MemberCommunitySavesError', code: number, message: string } } };

export type SaveMemberCompanyMutationVariables = Exact<{
	input: SaveMemberCompanyInput;
}>;


export type SaveMemberCompanyMutation = { __typename?: 'Mutation', saveMemberCompany: { __typename?: 'MemberCompanySaveResultType', userId: string, error?: { __typename?: 'MemberCompanySavesError', code: number, message: string } } };

export type SaveMemberEventRsvpMutationVariables = Exact<{
	input: SaveMemberEventRsvpInput;
}>;


export type SaveMemberEventRsvpMutation = { __typename?: 'Mutation', saveMemberEventRsvp: { __typename?: 'MemberEventRsvpSaveResultType', updatedMemberEventRsvpId?: string, error?: { __typename?: 'MemberEventRsvpSavesError', code: number, message: string } } };

export type SaveMemberJobInfoMutationVariables = Exact<{
	input: SaveMemberJobInfoInput;
}>;


export type SaveMemberJobInfoMutation = { __typename?: 'Mutation', saveMemberJobInfo: { __typename?: 'MemberSaveResultType', userId: string, error?: { __typename?: 'MemberSavesError', code: number, message: string } } };

export type SaveMemberLocationMutationVariables = Exact<{
	input: SaveMemberLocationInput;
}>;


export type SaveMemberLocationMutation = { __typename?: 'Mutation', saveMemberLocation: { __typename?: 'MemberLocationSaveResultType', updatedLocationId: string, error?: { __typename?: 'MemberLocationSavesError', code: number, message: string } } };

export type SaveMemberSocialInfoMutationVariables = Exact<{
	input: SaveMemberSocialInput;
}>;


export type SaveMemberSocialInfoMutation = { __typename?: 'Mutation', saveMemberSocialInfo: { __typename?: 'MemberSaveResultType', userId: string, error?: { __typename?: 'MemberSavesError', code: number, message: string } } };

export type SendVerificationCodeMutationVariables = Exact<{
	b64email: Scalars['String'];
	b64tac: Scalars['String'];
}>;


export type SendVerificationCodeMutation = { __typename?: 'Mutation', sendVerificationCode: { __typename?: 'SendVerificationCodeResult', success: boolean } };

export type StartAccountLinkingMutationVariables = Exact<{
	email: Scalars['String'];
	userName: Scalars['String'];
}>;


export type StartAccountLinkingMutation = { __typename?: 'Mutation', startAccountLinking: { __typename?: 'StartAccountLinkingResult', result: UserAuthState, email: string, error?: { __typename?: 'UserAuthError', code: number, message: string } } };

export type UnlinkAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type UnlinkAccountMutation = { __typename?: 'Mutation', unlinkAccount: { __typename?: 'UnlinkAccountResult', result: UserAuthState, error?: { __typename?: 'UserAuthError', code: number, message: string } } };

export type UpdateExistingStripeSubscriptionMutationVariables = Exact<{
	coupon: Scalars['String'];
}>;


export type UpdateExistingStripeSubscriptionMutation = { __typename?: 'Mutation', updateExistingStripeSubscription: { __typename?: 'UpdateSubscriptionResult', currentPeriodEnd: number, currentPrice: number, discountPrice: number } };

export type UpdateVideoProgressMutationVariables = Exact<{
	continueAtTime: Scalars['Float'];
	videoId: Scalars['String'];
}>;


export type UpdateVideoProgressMutation = { __typename?: 'Mutation', updateVideoProgress: { __typename?: 'UserVideoProgress', userId: string, videoId: string, continueAtTime: number, farthestWatchedTime: number } };

export type CheckIfStripeScheduledSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckIfStripeScheduledSubscriptionQuery = { __typename?: 'Query', isScheduledSubscription: boolean };

export type CheckStripeSubscriptionDiscountOfferQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckStripeSubscriptionDiscountOfferQuery = { __typename?: 'Query', checkStripeSubscriptionDiscountOffer: { __typename?: 'SubscriptionDiscountOfferResult', newPrice: number, cancelConfigId: string, couponCode: string, acceptCta: string, rejectCta: string, headline: string, subheadline: string, offer: string, normalPrice: string } };

export type GetCertificateQueryVariables = Exact<{
	certificateId: Scalars['String'];
}>;


export type GetCertificateQuery = { __typename?: 'Query', getCertificate?: { __typename?: 'Certificate', certificateId: string, title: string, description?: string, slug: string, type: CertificateType, publishStatus: string, coverImageUrl?: string, badgeImageUrl?: string, primaryColor: string, meta?: any, isRegistered?: boolean, isCompleted?: boolean, numElectiveCourses: number, groups: Array<{ __typename?: 'CertificateGroup', certificateGroupId: string, title: string, description?: string, publishStatus: string, meta?: any, isCapstoneGroup: boolean, courses: Array<{ __typename?: 'CertificateGroupCourse', courseId: string, publishStatus: string, meta?: any, liveOnly: boolean, completionRequired: boolean, sortOrder: number }> }> }, listUserSessions: Array<{ __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }>, listPastSessions: Array<{ __typename?: 'BaseSession', sessionId: string, courseId: string, title: string, subtitle?: string, coverImageUrl?: string, courseType: string, sessionType: string, gatedAccessType: string, startAt: any, endAt: any, enrollmentClose?: any, onDemandAvailable: boolean, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }>, listCourseBookmarks: Array<{ __typename?: 'CourseBookmarkType', courseId: string }> };

export type GetEntitledBootcampSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEntitledBootcampSessionsQuery = { __typename?: 'Query', getEntitledBootcampSessions: Array<{ __typename?: 'EntitledSession', sessionId: string, title: string, subtitle?: string, coverImageUrl: string, startAt: string, endAt: string }> };

export type GetMemberQueryVariables = Exact<{
	userId?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberQuery = { __typename?: 'Query', getMember: { __typename?: 'PrivateMember', about?: string, accountType: string, alternateSSOEmail?: string, communityGet?: string, communityGive?: string, displayName?: string, email: string, firstName?: string, growthTip?: string, hasOngoingSprintAccess: boolean, industry?: string, instagramProfileUrl?: string, jobFunction?: string, jobRole?: string, lastName?: string, linkedinProfileUrl?: string, manageBilling: boolean, meetPreference?: string, middleName?: string, profileImageUrl?: string, pronouns?: string, registrationDate: any, trialEndDate?: any, status: string, testData: boolean, twitterProfileUrl?: string, userId: string, userType: string, whatILove?: string, wpUserId?: number, company: { __typename?: 'Company', name?: string, userCompanyId?: string }, customer?: { __typename?: 'Customer', customerId: string, name: string, type: string }, languages: Array<{ __typename?: 'Language', name: string }>, location: { __typename?: 'Location', city?: string, country?: string, primaryLocation?: boolean, region?: string, userLocationId?: string }, scholarship?: { __typename?: 'Scholarship', name: string, scholarshipId: string, type: string } }, getMemberEntitlements: Array<{ __typename?: 'MemberEntitlement', entitlementId: string, type: EntitlementType, label: string, precedence?: number, customization?: { __typename?: 'PlatformCustomization', navLogoUrl?: string } }> };

export type GetMemberEventQueryVariables = Exact<{
	memberEventId: Scalars['String'];
}>;


export type GetMemberEventQuery = { __typename?: 'Query', getMemberEvent: { __typename?: 'MemberEvent', memberEventId: string, eventStart: string, eventEnd: string, title: string, subtitle?: string, details: string, zoomLink?: string, status: string, heroImageUrl: string, eventType: { __typename?: 'EventType', label: string }, eventCategory: { __typename?: 'EventCategory', category: string, label: string } } };

export type GetMemberEventRsvpQueryVariables = Exact<{
	userId: Scalars['String'];
	memberEventId: Scalars['String'];
}>;


export type GetMemberEventRsvpQuery = { __typename?: 'Query', getMemberEventRsvp: { __typename?: 'MemberEventsRsvp', memberEventId?: string, status?: string, userId?: string, zoomJoinUrl?: string, zoomRegistrantId?: string } };

export type GetOnDemandContentQueryVariables = Exact<{
	courseId?: InputMaybe<Scalars['String']>;
}>;


export type GetOnDemandContentQuery = { __typename?: 'Query', getOnDemandContent: { __typename?: 'CourseContentContainer', courseId: string, courseType: string, title: string, subtitle?: string, description?: string, hidePreview: boolean, coverImageUrl?: string, courseMeta?: any, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }>, groups: Array<{ __typename?: 'CourseContentGroup', courseContentGroupId: string, slug: string, title?: string, description?: string, hidePreview: boolean, meta?: any, content: Array<{ __typename?: 'CourseContent', courseContentId: string, slug: string, title?: string, description?: string, type: CourseContentType, hidePreview: boolean, completionRequired: boolean, meta: any, video?: { __typename?: 'CourseContentVideo', videoId?: string, externalKey?: string, duration?: number, aspectWidth?: number, aspectHeight?: number, meta?: any, chapters?: Array<{ __typename?: 'CourseContentVideoChapter', videoChapterId?: string, slug: string, title: string, startSeconds?: number, endSeconds?: number }> } }> }> } };

export type GetPageContentQueryVariables = Exact<{
	slug: Scalars['String'];
	langCode?: InputMaybe<PageContentLanguageCode>;
	publishStatuses?: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPageContentQuery = { __typename?: 'Query', getPageContent: { __typename?: 'PageContent', slug: string, langCode: string, publishStatus: string, meta: any } };

export type GetPassDetailsByCodeQueryVariables = Exact<{
	code: Scalars['String'];
	context: PassQueryContext;
}>;


export type GetPassDetailsByCodeQuery = { __typename?: 'Query', getPassDetailsByCode: { __typename?: 'PublicPassDetails', showRegistration: boolean, targetSegment: string, meta: any, redirectUrl?: string, content: Array<{ __typename?: 'PublicPassCourseContent', courseContentId?: string, slug?: string, title?: string, description?: string, videoThumbnail?: string, type?: CourseContentType, video?: { __typename?: 'PublicPassCourseContentVideo', videoId?: string, externalKey?: string, duration?: number, aspectWidth?: number, aspectHeight?: number } }> } };

export type GetResourceQueryVariables = Exact<{
	resourceID?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
}>;


export type GetResourceQuery = { __typename?: 'Query', getResource: { __typename?: 'Resource', resourceID: string, slug: string, title: string, subtitle?: string, type: string, coverImageUrl?: string, pdfDownloadUrl?: string, googleDocCopyUrl?: string, created?: string, modified?: string, items?: Array<{ __typename?: 'ResourceContent', resourceContentID: string, type: string, content?: string, anchorName?: string, imageUrl?: string, created: string, modified: string }>, tags: Array<{ __typename?: 'RecordTag', category?: string, label: string, tagID?: string, recordID?: string }> } };

export type GetSessionContainerContentQueryVariables = Exact<{
	sessionContentIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetSessionContainerContentQuery = { __typename?: 'Query', getSessionContainerContent: Array<{ __typename?: 'SessionContainerContent', sessionContentId: string, parentSessionContentId: string, title: string, type: SessionContentType, intro?: string, body?: string, outro?: string, buttonText?: string, buttonUrl?: string, enabledAt?: string, calendarStartDate?: string, day1Text?: string, day2Text?: string, day3Text?: string, day4Text?: string, day5Text?: string, videoPreviews?: Array<{ __typename?: 'SessionContentVideoPreview', videoId: string, duration: number, thumbnailUrl: string }> }> };

export type GetSessionContentQueryVariables = Exact<{
	sessionId?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
}>;


export type GetSessionContentQuery = { __typename?: 'Query', getSessionContent: { __typename?: 'SessionContent', courseId: string, sessionId: string, title?: string, subtitle?: string, description?: string, coverImageUrl?: string, courseType: string, sessionType: string, sessionStartAt: string, sessionEndAt: string, announcements: Array<{ __typename?: 'SessionContentAnnouncement', sessionContentId: string, announcement?: string, imageUrl?: string, stylePreset?: string, internalLinkUrl?: string, externalLinkUrl?: string }>, containers: Array<{ __typename?: 'SessionContentContainer', sessionContentId: string, title: string, expandedPlaceholder?: string, containsActiveContent: boolean }> }, getVideoProgressBySession: { __typename?: 'UserVideoProgressBySession', userId: string, sessionId: string, groups: Array<{ __typename?: 'UserVideoProgressBySessionVideoGroup', groupSessionContentId: string, videos: Array<{ __typename?: 'UserVideoProgressBySessionVideo', videoId: string, duration: number, farthestWatchedTime: number, optional: boolean }> }> } };

export type GetSessionContentVideosQueryVariables = Exact<{
	sessionId: Scalars['String'];
	videoGroupId: Scalars['String'];
}>;


export type GetSessionContentVideosQuery = { __typename?: 'Query', getSessionContentVideos: Array<{ __typename?: 'SessionContentVideo', videoId: string, externalKey: string, duration: number, aspectWidth?: number, aspectHeight?: number, title?: string, description?: string, thumbnailUrl?: string, chapters: Array<{ __typename?: 'VideoChapter', videoChapterId: string, slug: string, title: string, startSeconds: number, endSeconds: number }> }> };

export type GetSessionLinkQueryVariables = Exact<{
	sessionId: Scalars['String'];
	sessionTitle: Scalars['String'];
	date: Scalars['DateTime'];
	forceRefresh?: Scalars['Boolean'];
}>;


export type GetSessionLinkQuery = { __typename?: 'Query', getSessionLink: string };

export type GetUserSessionQueryVariables = Exact<{
	where: GetUserSessionInput;
}>;


export type GetUserSessionQuery = { __typename?: 'Query', getUserSession: { __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> } };

export type GetVideoProgressQueryVariables = Exact<{
	videoId: Scalars['String'];
}>;


export type GetVideoProgressQuery = { __typename?: 'Query', getVideoProgress: { __typename?: 'UserVideoProgress', userId: string, videoId: string, continueAtTime: number, farthestWatchedTime: number } };

export type GetVideoProgressByCourseQueryVariables = Exact<{
	courseId: Scalars['String'];
}>;


export type GetVideoProgressByCourseQuery = { __typename?: 'Query', getVideoProgressByCourse: { __typename?: 'UserVideoProgressByCourse', userId: string, courseId: string, totalDuration: number, totalFarthestWatchedTime: number, groups: Array<{ __typename?: 'UserVideoProgressByCourseContentGroup', courseContentGroupId: string, totalDuration: number, totalFarthestWatchedTime: number, videos: Array<{ __typename?: 'UserVideoProgressByCourseContent', courseContentId: string, videoId: string, duration: number, farthestWatchedTime: number }> }> } };

export type GetVideoProgressBySessionQueryVariables = Exact<{
	sessionId: Scalars['String'];
}>;


export type GetVideoProgressBySessionQuery = { __typename?: 'Query', getVideoProgressBySession: { __typename?: 'UserVideoProgressBySession', userId: string, sessionId: string, groups: Array<{ __typename?: 'UserVideoProgressBySessionVideoGroup', groupSessionContentId: string, videos: Array<{ __typename?: 'UserVideoProgressBySessionVideo', videoId: string, duration: number, farthestWatchedTime: number, optional: boolean }> }> } };

export type ListCaseStudiesQueryVariables = Exact<{
	caseStudyId?: InputMaybe<Scalars['String']>;
}>;


export type ListCaseStudiesQuery = { __typename?: 'Query', listCaseStudies: Array<{ __typename?: 'CaseStudy', caseStudyId: string, bodyText: string, coverImageUrl: string, publishedAt: string, status: string, title: string, video: string }> };

export type ListCertificatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCertificatesQuery = { __typename?: 'Query', listCertificates: Array<{ __typename?: 'Certificate', certificateId: string, title: string, description?: string, slug: string, type: CertificateType, publishStatus: string, coverImageUrl?: string, badgeImageUrl?: string, primaryColor: string, meta?: any, isRegistered?: boolean, isCompleted?: boolean, numElectiveCourses: number, groups: Array<{ __typename?: 'CertificateGroup', certificateGroupId: string, title: string, description?: string, publishStatus: string, meta?: any, isCapstoneGroup: boolean, courses: Array<{ __typename?: 'CertificateGroupCourse', courseId: string, title?: string, description?: string, type?: string, coverImageUrl?: string, publishStatus: string, meta?: any, liveOnly: boolean, completionRequired: boolean, sortOrder: number }> }> }> };

export type ListCourseBookmarksQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCourseBookmarksQuery = { __typename?: 'Query', listCourseBookmarks: Array<{ __typename?: 'CourseBookmarkType', courseId: string }> };

export type ListIndustryReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIndustryReferencesQuery = { __typename?: 'Query', listIndustryReferences: Array<{ __typename?: 'IndustryRef', industryRefId: string, industry: string, sortOrder: string, active: string }> };

export type ListJobFunctionReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListJobFunctionReferencesQuery = { __typename?: 'Query', listJobFunctionReferences: Array<{ __typename?: 'JobFunctionRef', jobFunctionRefId: string, jobFunction: string, sortOrder: string, active: string }> };

export type ListMemberEventsQueryVariables = Exact<{
	category?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Scalars['String']>;
}>;


export type ListMemberEventsQuery = { __typename?: 'Query', listMemberEvents: Array<{ __typename?: 'MemberEvent', memberEventId: string, eventStart: string, eventEnd: string, title: string, subtitle?: string, details: string, zoomLink?: string, status: string, heroImageUrl: string, eventType: { __typename?: 'EventType', type: string, label: string }, eventCategory: { __typename?: 'EventCategory', category: string, label: string } }> };

export type ListMemberEventsRsvpQueryVariables = Exact<{
	userId: Scalars['String'];
	includePastEvents: Scalars['Boolean'];
}>;


export type ListMemberEventsRsvpQuery = { __typename?: 'Query', listMemberEventsRsvp: Array<{ __typename?: 'MemberEventsRsvp', memberEventId?: string, status?: string, userId?: string, zoomJoinUrl?: string, zoomRegistrantId?: string }> };

export type ListMembersQueryVariables = Exact<{
	filter?: InputMaybe<Scalars['String']>;
	count?: InputMaybe<Scalars['Int']>;
	page?: InputMaybe<Scalars['Int']>;
	context?: InputMaybe<QueryContext>;
}>;


export type ListMembersQuery = { __typename?: 'Query', listMembers: { __typename?: 'PaginatedPublicMemberList', list: Array<{ __typename?: 'PublicMember', userId: string, displayName?: string, firstName?: string, jobRole?: string, lastName?: string, profileImageUrl?: string, registrationDate: any, company: { __typename?: 'Company', userCompanyId?: string, name?: string } }>, pageInfo: { __typename?: 'PageInfo', totalPages: number, total: number, page: number, count: number, filter?: string } } };

export type ListPastSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPastSessionsQuery = { __typename?: 'Query', listPastSessions: Array<{ __typename?: 'BaseSession', sessionId: string, courseId: string, title: string, sessionType: string, courseType: string, startAt: any, endAt: any, onDemandAvailable: boolean, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }> };

export type ListResourcesQueryVariables = Exact<{
	resourceIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	count?: InputMaybe<Scalars['Int']>;
	page?: InputMaybe<Scalars['Int']>;
}>;


export type ListResourcesQuery = { __typename?: 'Query', listResources: { __typename?: 'PaginatedResourceList', list: Array<{ __typename?: 'Resource', resourceID: string, slug: string, title: string, subtitle?: string, type: string, tags: Array<{ __typename?: 'RecordTag', category?: string, label: string, tagID?: string, recordID?: string }> }>, pageInfo: { __typename?: 'PageInfo', totalPages: number, total: number, page: number, count: number } } };

export type ListUserCourseDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserCourseDataQuery = { __typename?: 'Query', listUserSessions: Array<{ __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }>, listCourseBookmarks: Array<{ __typename?: 'CourseBookmarkType', courseId: string }> };

export type ListUserSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserSessionsQuery = { __typename?: 'Query', listUserSessions: Array<{ __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }> };

export type ListUserSessionsByCourseQueryVariables = Exact<{
	where: ListUserSessionsByCourseInput;
}>;


export type ListUserSessionsByCourseQuery = { __typename?: 'Query', listUserSessionsByCourse: Array<{ __typename?: 'UserSession', sessionId: string, courseId: string, title: string, courseType: string, sessionType: string, gatedAccessType: string, subtitle?: string, description?: string, coverImageUrl?: string, startAt: any, endAt: any, lowInventory?: boolean, enrollmentStatus: string, enrollmentClose?: any, courseTypeMeta?: any, sessionMeta?: any, courseMeta?: any, wpCohortSlug?: string, onDemandAvailable: boolean, courseBadgeId?: string, faculties: Array<{ __typename?: 'Faculty', fullName: string, imageUrl?: string, facultyId: string, sortOrder: number, biography?: string }> }> };

export type SearchCoursesQueryVariables = Exact<{
	input: SearchCoursesInput;
}>;


export type SearchCoursesQuery = { __typename?: 'Query', searchCourses: Array<{ __typename?: 'SearchCoursesResult', courseId: string, score: number }> };

export const FullResourceFragmentDoc = gql`
    fragment FullResource on Resource {
  resourceID
  slug
  title
  subtitle
  type
  coverImageUrl
  pdfDownloadUrl
  googleDocCopyUrl
  created
  modified
  items {
    resourceContentID
    type
    content
    anchorName
    imageUrl
    created
    modified
  }
  tags {
    category
    label
    tagID
    recordID
  }
}
    `;
export const MemberFragmentDoc = gql`
    fragment member on PublicMember {
  about
  accountType
  communityGet
  communityGive
  company {
    name
    userCompanyId
  }
  displayName
  email
  firstName
  growthTip
  industry
  instagramProfileUrl
  jobFunction
  jobRole
  languages {
    name
  }
  lastName
  linkedinProfileUrl
  location {
    city
    country
    primaryLocation
    region
    userLocationId
  }
  meetPreference
  middleName
  profileImageUrl
  pronouns
  registrationDate
  status
  testData
  twitterProfileUrl
  userId
  userType
  whatILove
  wpUserId
}
    `;
export const ResourceTileFragmentDoc = gql`
    fragment ResourceTile on Resource {
  resourceID
  slug
  title
  subtitle
  type
  tags {
    category
    label
    tagID
    recordID
  }
}
    `;
export const CourseFacultiesFragmentDoc = gql`
    fragment courseFaculties on Faculty {
  fullName
  imageUrl
  facultyId
  sortOrder
  biography
}
    `;
export const SessionByUserFragmentDoc = gql`
    fragment SessionByUser on UserSession {
  sessionId
  courseId
  title
  courseType
  sessionType
  gatedAccessType
  subtitle
  description
  coverImageUrl
  faculties {
    ...courseFaculties
  }
  startAt
  endAt
  lowInventory
  enrollmentStatus
  enrollmentClose
  courseTypeMeta
  sessionMeta
  courseMeta
  wpCohortSlug
  onDemandAvailable
  courseBadgeId
}
    ${CourseFacultiesFragmentDoc}`;
export const AddCertificateCompletionDocument = gql`
    mutation addCertificateCompletion($certificateId: String!) {
  addCertificateCompletion(certificateId: $certificateId)
}
    `;
export type AddCertificateCompletionMutationFn = Apollo.MutationFunction<AddCertificateCompletionMutation, AddCertificateCompletionMutationVariables>;

/**
 * __useAddCertificateCompletionMutation__
 *
 * To run a mutation, you first call `useAddCertificateCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCertificateCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCertificateCompletionMutation, { data, loading, error }] = useAddCertificateCompletionMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useAddCertificateCompletionMutation(baseOptions?: Apollo.MutationHookOptions<AddCertificateCompletionMutation, AddCertificateCompletionMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<AddCertificateCompletionMutation, AddCertificateCompletionMutationVariables>(AddCertificateCompletionDocument, options);
}
export type AddCertificateCompletionMutationHookResult = ReturnType<typeof useAddCertificateCompletionMutation>;
export type AddCertificateCompletionMutationResult = Apollo.MutationResult<AddCertificateCompletionMutation>;
export type AddCertificateCompletionMutationOptions = Apollo.BaseMutationOptions<AddCertificateCompletionMutation, AddCertificateCompletionMutationVariables>;
export const AddCertificateRegistrationDocument = gql`
    mutation addCertificateRegistration($certificateId: String!) {
  addCertificateRegistration(certificateId: $certificateId)
}
    `;
export type AddCertificateRegistrationMutationFn = Apollo.MutationFunction<AddCertificateRegistrationMutation, AddCertificateRegistrationMutationVariables>;

/**
 * __useAddCertificateRegistrationMutation__
 *
 * To run a mutation, you first call `useAddCertificateRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCertificateRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCertificateRegistrationMutation, { data, loading, error }] = useAddCertificateRegistrationMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useAddCertificateRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<AddCertificateRegistrationMutation, AddCertificateRegistrationMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<AddCertificateRegistrationMutation, AddCertificateRegistrationMutationVariables>(AddCertificateRegistrationDocument, options);
}
export type AddCertificateRegistrationMutationHookResult = ReturnType<typeof useAddCertificateRegistrationMutation>;
export type AddCertificateRegistrationMutationResult = Apollo.MutationResult<AddCertificateRegistrationMutation>;
export type AddCertificateRegistrationMutationOptions = Apollo.BaseMutationOptions<AddCertificateRegistrationMutation, AddCertificateRegistrationMutationVariables>;
export const AddUserSaveDocument = gql`
    mutation addUserSave($contentId: String!, $contentType: String!) {
  addUserSave(contentId: $contentId, contentType: $contentType) {
    success
    contentId
    error {
      code
      message
    }
  }
}
    `;
export type AddUserSaveMutationFn = Apollo.MutationFunction<AddUserSaveMutation, AddUserSaveMutationVariables>;

/**
 * __useAddUserSaveMutation__
 *
 * To run a mutation, you first call `useAddUserSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSaveMutation, { data, loading, error }] = useAddUserSaveMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useAddUserSaveMutation(baseOptions?: Apollo.MutationHookOptions<AddUserSaveMutation, AddUserSaveMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<AddUserSaveMutation, AddUserSaveMutationVariables>(AddUserSaveDocument, options);
}
export type AddUserSaveMutationHookResult = ReturnType<typeof useAddUserSaveMutation>;
export type AddUserSaveMutationResult = Apollo.MutationResult<AddUserSaveMutation>;
export type AddUserSaveMutationOptions = Apollo.BaseMutationOptions<AddUserSaveMutation, AddUserSaveMutationVariables>;
export const CreateSignedUploadUrlDocument = gql`
    mutation createSignedUploadUrl($input: CreateSignedUploadUrlInput!) {
  createSignedUploadUrl(input: $input) {
    url
    fields {
      acl
      key
      policy
      xAmzAlgorithm
      xAmzCredential
      xAmzDate
      xAmzSignature
    }
  }
}
    `;
export type CreateSignedUploadUrlMutationFn = Apollo.MutationFunction<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>;

/**
 * __useCreateSignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateSignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedUploadUrlMutation, { data, loading, error }] = useCreateSignedUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignedUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>(CreateSignedUploadUrlDocument, options);
}
export type CreateSignedUploadUrlMutationHookResult = ReturnType<typeof useCreateSignedUploadUrlMutation>;
export type CreateSignedUploadUrlMutationResult = Apollo.MutationResult<CreateSignedUploadUrlMutation>;
export type CreateSignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>;
export const CreateStripePortalUrlDocument = gql`
    mutation createStripePortalUrl($configId: String!) {
  createStripePortalUrl(configId: $configId)
}
    `;
export type CreateStripePortalUrlMutationFn = Apollo.MutationFunction<CreateStripePortalUrlMutation, CreateStripePortalUrlMutationVariables>;

/**
 * __useCreateStripePortalUrlMutation__
 *
 * To run a mutation, you first call `useCreateStripePortalUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripePortalUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripePortalUrlMutation, { data, loading, error }] = useCreateStripePortalUrlMutation({
 *   variables: {
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useCreateStripePortalUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripePortalUrlMutation, CreateStripePortalUrlMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<CreateStripePortalUrlMutation, CreateStripePortalUrlMutationVariables>(CreateStripePortalUrlDocument, options);
}
export type CreateStripePortalUrlMutationHookResult = ReturnType<typeof useCreateStripePortalUrlMutation>;
export type CreateStripePortalUrlMutationResult = Apollo.MutationResult<CreateStripePortalUrlMutation>;
export type CreateStripePortalUrlMutationOptions = Apollo.BaseMutationOptions<CreateStripePortalUrlMutation, CreateStripePortalUrlMutationVariables>;
export const CreateStripeSubscriptionCheckoutSessionDocument = gql`
    mutation createStripeSubscriptionCheckoutSession {
  createStripeSubscriptionCheckoutSession
}
    `;
export type CreateStripeSubscriptionCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeSubscriptionCheckoutSessionMutation, CreateStripeSubscriptionCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeSubscriptionCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSubscriptionCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSubscriptionCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSubscriptionCheckoutSessionMutation, { data, loading, error }] = useCreateStripeSubscriptionCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeSubscriptionCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSubscriptionCheckoutSessionMutation, CreateStripeSubscriptionCheckoutSessionMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<CreateStripeSubscriptionCheckoutSessionMutation, CreateStripeSubscriptionCheckoutSessionMutationVariables>(CreateStripeSubscriptionCheckoutSessionDocument, options);
}
export type CreateStripeSubscriptionCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeSubscriptionCheckoutSessionMutation>;
export type CreateStripeSubscriptionCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeSubscriptionCheckoutSessionMutation>;
export type CreateStripeSubscriptionCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeSubscriptionCheckoutSessionMutation, CreateStripeSubscriptionCheckoutSessionMutationVariables>;
export const CreateUserWithPassDocument = gql`
    mutation createUserWithPass($code: String!, $firstName: String!, $lastName: String!, $email: String!, $company: String, $jobTitle: String, $jobLevel: String, $companyNumberOfEmployees: String, $phone: String) {
  createUserWithPass(
    code: $code
    firstName: $firstName
    lastName: $lastName
    email: $email
    company: $company
    jobTitle: $jobTitle
    jobLevel: $jobLevel
    companyNumberOfEmployees: $companyNumberOfEmployees
    phone: $phone
  ) {
    result
    tac
    error {
      code
      message
    }
  }
}
    `;
export type CreateUserWithPassMutationFn = Apollo.MutationFunction<CreateUserWithPassMutation, CreateUserWithPassMutationVariables>;

/**
 * __useCreateUserWithPassMutation__
 *
 * To run a mutation, you first call `useCreateUserWithPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithPassMutation, { data, loading, error }] = useCreateUserWithPassMutation({
 *   variables: {
 *      code: // value for 'code'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      company: // value for 'company'
 *      jobTitle: // value for 'jobTitle'
 *      jobLevel: // value for 'jobLevel'
 *      companyNumberOfEmployees: // value for 'companyNumberOfEmployees'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateUserWithPassMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithPassMutation, CreateUserWithPassMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<CreateUserWithPassMutation, CreateUserWithPassMutationVariables>(CreateUserWithPassDocument, options);
}
export type CreateUserWithPassMutationHookResult = ReturnType<typeof useCreateUserWithPassMutation>;
export type CreateUserWithPassMutationResult = Apollo.MutationResult<CreateUserWithPassMutation>;
export type CreateUserWithPassMutationOptions = Apollo.BaseMutationOptions<CreateUserWithPassMutation, CreateUserWithPassMutationVariables>;
export const GetShareLinksDocument = gql`
    mutation getShareLinks {
  getShareLinks {
    program
    url
  }
}
    `;
export type GetShareLinksMutationFn = Apollo.MutationFunction<GetShareLinksMutation, GetShareLinksMutationVariables>;

/**
 * __useGetShareLinksMutation__
 *
 * To run a mutation, you first call `useGetShareLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareLinksMutation, { data, loading, error }] = useGetShareLinksMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetShareLinksMutation(baseOptions?: Apollo.MutationHookOptions<GetShareLinksMutation, GetShareLinksMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<GetShareLinksMutation, GetShareLinksMutationVariables>(GetShareLinksDocument, options);
}
export type GetShareLinksMutationHookResult = ReturnType<typeof useGetShareLinksMutation>;
export type GetShareLinksMutationResult = Apollo.MutationResult<GetShareLinksMutation>;
export type GetShareLinksMutationOptions = Apollo.BaseMutationOptions<GetShareLinksMutation, GetShareLinksMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LookupActivationLinkDocument = gql`
    mutation lookupActivationLink($code: String!, $email: String!, $tac: String!) {
  lookupActivationLink(code: $code, email: $email, tac: $tac) {
    success
    link
    existingUser
  }
}
    `;
export type LookupActivationLinkMutationFn = Apollo.MutationFunction<LookupActivationLinkMutation, LookupActivationLinkMutationVariables>;

/**
 * __useLookupActivationLinkMutation__
 *
 * To run a mutation, you first call `useLookupActivationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLookupActivationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lookupActivationLinkMutation, { data, loading, error }] = useLookupActivationLinkMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *      tac: // value for 'tac'
 *   },
 * });
 */
export function useLookupActivationLinkMutation(baseOptions?: Apollo.MutationHookOptions<LookupActivationLinkMutation, LookupActivationLinkMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<LookupActivationLinkMutation, LookupActivationLinkMutationVariables>(LookupActivationLinkDocument, options);
}
export type LookupActivationLinkMutationHookResult = ReturnType<typeof useLookupActivationLinkMutation>;
export type LookupActivationLinkMutationResult = Apollo.MutationResult<LookupActivationLinkMutation>;
export type LookupActivationLinkMutationOptions = Apollo.BaseMutationOptions<LookupActivationLinkMutation, LookupActivationLinkMutationVariables>;
export const RemoveCertificateCompletionDocument = gql`
    mutation removeCertificateCompletion($certificateId: String!) {
  removeCertificateCompletion(certificateId: $certificateId)
}
    `;
export type RemoveCertificateCompletionMutationFn = Apollo.MutationFunction<RemoveCertificateCompletionMutation, RemoveCertificateCompletionMutationVariables>;

/**
 * __useRemoveCertificateCompletionMutation__
 *
 * To run a mutation, you first call `useRemoveCertificateCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCertificateCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCertificateCompletionMutation, { data, loading, error }] = useRemoveCertificateCompletionMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useRemoveCertificateCompletionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCertificateCompletionMutation, RemoveCertificateCompletionMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<RemoveCertificateCompletionMutation, RemoveCertificateCompletionMutationVariables>(RemoveCertificateCompletionDocument, options);
}
export type RemoveCertificateCompletionMutationHookResult = ReturnType<typeof useRemoveCertificateCompletionMutation>;
export type RemoveCertificateCompletionMutationResult = Apollo.MutationResult<RemoveCertificateCompletionMutation>;
export type RemoveCertificateCompletionMutationOptions = Apollo.BaseMutationOptions<RemoveCertificateCompletionMutation, RemoveCertificateCompletionMutationVariables>;
export const RemoveCertificateRegistrationDocument = gql`
    mutation removeCertificateRegistration($certificateId: String!) {
  removeCertificateRegistration(certificateId: $certificateId)
}
    `;
export type RemoveCertificateRegistrationMutationFn = Apollo.MutationFunction<RemoveCertificateRegistrationMutation, RemoveCertificateRegistrationMutationVariables>;

/**
 * __useRemoveCertificateRegistrationMutation__
 *
 * To run a mutation, you first call `useRemoveCertificateRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCertificateRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCertificateRegistrationMutation, { data, loading, error }] = useRemoveCertificateRegistrationMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useRemoveCertificateRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCertificateRegistrationMutation, RemoveCertificateRegistrationMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<RemoveCertificateRegistrationMutation, RemoveCertificateRegistrationMutationVariables>(RemoveCertificateRegistrationDocument, options);
}
export type RemoveCertificateRegistrationMutationHookResult = ReturnType<typeof useRemoveCertificateRegistrationMutation>;
export type RemoveCertificateRegistrationMutationResult = Apollo.MutationResult<RemoveCertificateRegistrationMutation>;
export type RemoveCertificateRegistrationMutationOptions = Apollo.BaseMutationOptions<RemoveCertificateRegistrationMutation, RemoveCertificateRegistrationMutationVariables>;
export const RemoveUserSaveDocument = gql`
    mutation removeUserSave($contentId: String!, $contentType: String!) {
  removeUserSave(contentId: $contentId, contentType: $contentType) {
    success
    contentId
    status
    error {
      code
      message
    }
  }
}
    `;
export type RemoveUserSaveMutationFn = Apollo.MutationFunction<RemoveUserSaveMutation, RemoveUserSaveMutationVariables>;

/**
 * __useRemoveUserSaveMutation__
 *
 * To run a mutation, you first call `useRemoveUserSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserSaveMutation, { data, loading, error }] = useRemoveUserSaveMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useRemoveUserSaveMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserSaveMutation, RemoveUserSaveMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<RemoveUserSaveMutation, RemoveUserSaveMutationVariables>(RemoveUserSaveDocument, options);
}
export type RemoveUserSaveMutationHookResult = ReturnType<typeof useRemoveUserSaveMutation>;
export type RemoveUserSaveMutationResult = Apollo.MutationResult<RemoveUserSaveMutation>;
export type RemoveUserSaveMutationOptions = Apollo.BaseMutationOptions<RemoveUserSaveMutation, RemoveUserSaveMutationVariables>;
export const SaveEnrollmentDocument = gql`
    mutation saveEnrollment($input: SaveEnrollmentInput!) {
  saveEnrollment(input: $input) {
    userId
    sessionId
    title
    subtitle
    description
    enrollmentStatus
  }
}
    `;
export type SaveEnrollmentMutationFn = Apollo.MutationFunction<SaveEnrollmentMutation, SaveEnrollmentMutationVariables>;

/**
 * __useSaveEnrollmentMutation__
 *
 * To run a mutation, you first call `useSaveEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEnrollmentMutation, { data, loading, error }] = useSaveEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<SaveEnrollmentMutation, SaveEnrollmentMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveEnrollmentMutation, SaveEnrollmentMutationVariables>(SaveEnrollmentDocument, options);
}
export type SaveEnrollmentMutationHookResult = ReturnType<typeof useSaveEnrollmentMutation>;
export type SaveEnrollmentMutationResult = Apollo.MutationResult<SaveEnrollmentMutation>;
export type SaveEnrollmentMutationOptions = Apollo.BaseMutationOptions<SaveEnrollmentMutation, SaveEnrollmentMutationVariables>;
export const SaveMemberBasicInfoDocument = gql`
    mutation saveMemberBasicInfo($input: SaveMemberBasicInfoInput!) {
  saveMemberBasicInfo(input: $input) {
    userId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberBasicInfoMutationFn = Apollo.MutationFunction<SaveMemberBasicInfoMutation, SaveMemberBasicInfoMutationVariables>;

/**
 * __useSaveMemberBasicInfoMutation__
 *
 * To run a mutation, you first call `useSaveMemberBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberBasicInfoMutation, { data, loading, error }] = useSaveMemberBasicInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberBasicInfoMutation, SaveMemberBasicInfoMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberBasicInfoMutation, SaveMemberBasicInfoMutationVariables>(SaveMemberBasicInfoDocument, options);
}
export type SaveMemberBasicInfoMutationHookResult = ReturnType<typeof useSaveMemberBasicInfoMutation>;
export type SaveMemberBasicInfoMutationResult = Apollo.MutationResult<SaveMemberBasicInfoMutation>;
export type SaveMemberBasicInfoMutationOptions = Apollo.BaseMutationOptions<SaveMemberBasicInfoMutation, SaveMemberBasicInfoMutationVariables>;
export const SaveMemberCommunityDocument = gql`
    mutation saveMemberCommunity($input: SaveMemberCommunityInput!) {
  saveMemberCommunity(input: $input) {
    userId
    about
    whatILove
    communityGet
    communityGive
    growthTip
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberCommunityMutationFn = Apollo.MutationFunction<SaveMemberCommunityMutation, SaveMemberCommunityMutationVariables>;

/**
 * __useSaveMemberCommunityMutation__
 *
 * To run a mutation, you first call `useSaveMemberCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberCommunityMutation, { data, loading, error }] = useSaveMemberCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberCommunityMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberCommunityMutation, SaveMemberCommunityMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberCommunityMutation, SaveMemberCommunityMutationVariables>(SaveMemberCommunityDocument, options);
}
export type SaveMemberCommunityMutationHookResult = ReturnType<typeof useSaveMemberCommunityMutation>;
export type SaveMemberCommunityMutationResult = Apollo.MutationResult<SaveMemberCommunityMutation>;
export type SaveMemberCommunityMutationOptions = Apollo.BaseMutationOptions<SaveMemberCommunityMutation, SaveMemberCommunityMutationVariables>;
export const SaveMemberCompanyDocument = gql`
    mutation saveMemberCompany($input: SaveMemberCompanyInput!) {
  saveMemberCompany(input: $input) {
    userId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberCompanyMutationFn = Apollo.MutationFunction<SaveMemberCompanyMutation, SaveMemberCompanyMutationVariables>;

/**
 * __useSaveMemberCompanyMutation__
 *
 * To run a mutation, you first call `useSaveMemberCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberCompanyMutation, { data, loading, error }] = useSaveMemberCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberCompanyMutation, SaveMemberCompanyMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberCompanyMutation, SaveMemberCompanyMutationVariables>(SaveMemberCompanyDocument, options);
}
export type SaveMemberCompanyMutationHookResult = ReturnType<typeof useSaveMemberCompanyMutation>;
export type SaveMemberCompanyMutationResult = Apollo.MutationResult<SaveMemberCompanyMutation>;
export type SaveMemberCompanyMutationOptions = Apollo.BaseMutationOptions<SaveMemberCompanyMutation, SaveMemberCompanyMutationVariables>;
export const SaveMemberEventRsvpDocument = gql`
    mutation saveMemberEventRsvp($input: SaveMemberEventRsvpInput!) {
  saveMemberEventRsvp(input: $input) {
    updatedMemberEventRsvpId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberEventRsvpMutationFn = Apollo.MutationFunction<SaveMemberEventRsvpMutation, SaveMemberEventRsvpMutationVariables>;

/**
 * __useSaveMemberEventRsvpMutation__
 *
 * To run a mutation, you first call `useSaveMemberEventRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberEventRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberEventRsvpMutation, { data, loading, error }] = useSaveMemberEventRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberEventRsvpMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberEventRsvpMutation, SaveMemberEventRsvpMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberEventRsvpMutation, SaveMemberEventRsvpMutationVariables>(SaveMemberEventRsvpDocument, options);
}
export type SaveMemberEventRsvpMutationHookResult = ReturnType<typeof useSaveMemberEventRsvpMutation>;
export type SaveMemberEventRsvpMutationResult = Apollo.MutationResult<SaveMemberEventRsvpMutation>;
export type SaveMemberEventRsvpMutationOptions = Apollo.BaseMutationOptions<SaveMemberEventRsvpMutation, SaveMemberEventRsvpMutationVariables>;
export const SaveMemberJobInfoDocument = gql`
    mutation saveMemberJobInfo($input: SaveMemberJobInfoInput!) {
  saveMemberJobInfo(input: $input) {
    userId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberJobInfoMutationFn = Apollo.MutationFunction<SaveMemberJobInfoMutation, SaveMemberJobInfoMutationVariables>;

/**
 * __useSaveMemberJobInfoMutation__
 *
 * To run a mutation, you first call `useSaveMemberJobInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberJobInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberJobInfoMutation, { data, loading, error }] = useSaveMemberJobInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberJobInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberJobInfoMutation, SaveMemberJobInfoMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberJobInfoMutation, SaveMemberJobInfoMutationVariables>(SaveMemberJobInfoDocument, options);
}
export type SaveMemberJobInfoMutationHookResult = ReturnType<typeof useSaveMemberJobInfoMutation>;
export type SaveMemberJobInfoMutationResult = Apollo.MutationResult<SaveMemberJobInfoMutation>;
export type SaveMemberJobInfoMutationOptions = Apollo.BaseMutationOptions<SaveMemberJobInfoMutation, SaveMemberJobInfoMutationVariables>;
export const SaveMemberLocationDocument = gql`
    mutation saveMemberLocation($input: SaveMemberLocationInput!) {
  saveMemberLocation(input: $input) {
    updatedLocationId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberLocationMutationFn = Apollo.MutationFunction<SaveMemberLocationMutation, SaveMemberLocationMutationVariables>;

/**
 * __useSaveMemberLocationMutation__
 *
 * To run a mutation, you first call `useSaveMemberLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberLocationMutation, { data, loading, error }] = useSaveMemberLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberLocationMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberLocationMutation, SaveMemberLocationMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberLocationMutation, SaveMemberLocationMutationVariables>(SaveMemberLocationDocument, options);
}
export type SaveMemberLocationMutationHookResult = ReturnType<typeof useSaveMemberLocationMutation>;
export type SaveMemberLocationMutationResult = Apollo.MutationResult<SaveMemberLocationMutation>;
export type SaveMemberLocationMutationOptions = Apollo.BaseMutationOptions<SaveMemberLocationMutation, SaveMemberLocationMutationVariables>;
export const SaveMemberSocialInfoDocument = gql`
    mutation saveMemberSocialInfo($input: SaveMemberSocialInput!) {
  saveMemberSocialInfo(input: $input) {
    userId
    error {
      code
      message
    }
  }
}
    `;
export type SaveMemberSocialInfoMutationFn = Apollo.MutationFunction<SaveMemberSocialInfoMutation, SaveMemberSocialInfoMutationVariables>;

/**
 * __useSaveMemberSocialInfoMutation__
 *
 * To run a mutation, you first call `useSaveMemberSocialInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberSocialInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberSocialInfoMutation, { data, loading, error }] = useSaveMemberSocialInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMemberSocialInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberSocialInfoMutation, SaveMemberSocialInfoMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SaveMemberSocialInfoMutation, SaveMemberSocialInfoMutationVariables>(SaveMemberSocialInfoDocument, options);
}
export type SaveMemberSocialInfoMutationHookResult = ReturnType<typeof useSaveMemberSocialInfoMutation>;
export type SaveMemberSocialInfoMutationResult = Apollo.MutationResult<SaveMemberSocialInfoMutation>;
export type SaveMemberSocialInfoMutationOptions = Apollo.BaseMutationOptions<SaveMemberSocialInfoMutation, SaveMemberSocialInfoMutationVariables>;
export const SendVerificationCodeDocument = gql`
    mutation sendVerificationCode($b64email: String!, $b64tac: String!) {
  sendVerificationCode(b64email: $b64email, b64tac: $b64tac) {
    success
  }
}
    `;
export type SendVerificationCodeMutationFn = Apollo.MutationFunction<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>;

/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *      b64email: // value for 'b64email'
 *      b64tac: // value for 'b64tac'
 *   },
 * });
 */
export function useSendVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(SendVerificationCodeDocument, options);
}
export type SendVerificationCodeMutationHookResult = ReturnType<typeof useSendVerificationCodeMutation>;
export type SendVerificationCodeMutationResult = Apollo.MutationResult<SendVerificationCodeMutation>;
export type SendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>;
export const StartAccountLinkingDocument = gql`
    mutation startAccountLinking($email: String!, $userName: String!) {
  startAccountLinking(email: $email, userName: $userName) {
    result
    email
    error {
      code
      message
    }
  }
}
    `;
export type StartAccountLinkingMutationFn = Apollo.MutationFunction<StartAccountLinkingMutation, StartAccountLinkingMutationVariables>;

/**
 * __useStartAccountLinkingMutation__
 *
 * To run a mutation, you first call `useStartAccountLinkingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAccountLinkingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAccountLinkingMutation, { data, loading, error }] = useStartAccountLinkingMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useStartAccountLinkingMutation(baseOptions?: Apollo.MutationHookOptions<StartAccountLinkingMutation, StartAccountLinkingMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<StartAccountLinkingMutation, StartAccountLinkingMutationVariables>(StartAccountLinkingDocument, options);
}
export type StartAccountLinkingMutationHookResult = ReturnType<typeof useStartAccountLinkingMutation>;
export type StartAccountLinkingMutationResult = Apollo.MutationResult<StartAccountLinkingMutation>;
export type StartAccountLinkingMutationOptions = Apollo.BaseMutationOptions<StartAccountLinkingMutation, StartAccountLinkingMutationVariables>;
export const UnlinkAccountDocument = gql`
    mutation unlinkAccount {
  unlinkAccount {
    result
    error {
      code
      message
    }
  }
}
    `;
export type UnlinkAccountMutationFn = Apollo.MutationFunction<UnlinkAccountMutation, UnlinkAccountMutationVariables>;

/**
 * __useUnlinkAccountMutation__
 *
 * To run a mutation, you first call `useUnlinkAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkAccountMutation, { data, loading, error }] = useUnlinkAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnlinkAccountMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkAccountMutation, UnlinkAccountMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<UnlinkAccountMutation, UnlinkAccountMutationVariables>(UnlinkAccountDocument, options);
}
export type UnlinkAccountMutationHookResult = ReturnType<typeof useUnlinkAccountMutation>;
export type UnlinkAccountMutationResult = Apollo.MutationResult<UnlinkAccountMutation>;
export type UnlinkAccountMutationOptions = Apollo.BaseMutationOptions<UnlinkAccountMutation, UnlinkAccountMutationVariables>;
export const UpdateExistingStripeSubscriptionDocument = gql`
    mutation updateExistingStripeSubscription($coupon: String!) {
  updateExistingStripeSubscription(coupon: $coupon) {
    currentPeriodEnd
    currentPrice
    discountPrice
  }
}
    `;
export type UpdateExistingStripeSubscriptionMutationFn = Apollo.MutationFunction<UpdateExistingStripeSubscriptionMutation, UpdateExistingStripeSubscriptionMutationVariables>;

/**
 * __useUpdateExistingStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateExistingStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingStripeSubscriptionMutation, { data, loading, error }] = useUpdateExistingStripeSubscriptionMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useUpdateExistingStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExistingStripeSubscriptionMutation, UpdateExistingStripeSubscriptionMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<UpdateExistingStripeSubscriptionMutation, UpdateExistingStripeSubscriptionMutationVariables>(UpdateExistingStripeSubscriptionDocument, options);
}
export type UpdateExistingStripeSubscriptionMutationHookResult = ReturnType<typeof useUpdateExistingStripeSubscriptionMutation>;
export type UpdateExistingStripeSubscriptionMutationResult = Apollo.MutationResult<UpdateExistingStripeSubscriptionMutation>;
export type UpdateExistingStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateExistingStripeSubscriptionMutation, UpdateExistingStripeSubscriptionMutationVariables>;
export const UpdateVideoProgressDocument = gql`
    mutation updateVideoProgress($continueAtTime: Float!, $videoId: String!) {
  updateVideoProgress(continueAtTime: $continueAtTime, videoId: $videoId) {
    userId
    videoId
    continueAtTime
    farthestWatchedTime
  }
}
    `;
export type UpdateVideoProgressMutationFn = Apollo.MutationFunction<UpdateVideoProgressMutation, UpdateVideoProgressMutationVariables>;

/**
 * __useUpdateVideoProgressMutation__
 *
 * To run a mutation, you first call `useUpdateVideoProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoProgressMutation, { data, loading, error }] = useUpdateVideoProgressMutation({
 *   variables: {
 *      continueAtTime: // value for 'continueAtTime'
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useUpdateVideoProgressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoProgressMutation, UpdateVideoProgressMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useMutation<UpdateVideoProgressMutation, UpdateVideoProgressMutationVariables>(UpdateVideoProgressDocument, options);
}
export type UpdateVideoProgressMutationHookResult = ReturnType<typeof useUpdateVideoProgressMutation>;
export type UpdateVideoProgressMutationResult = Apollo.MutationResult<UpdateVideoProgressMutation>;
export type UpdateVideoProgressMutationOptions = Apollo.BaseMutationOptions<UpdateVideoProgressMutation, UpdateVideoProgressMutationVariables>;
export const CheckIfStripeScheduledSubscriptionDocument = gql`
    query checkIfStripeScheduledSubscription {
  isScheduledSubscription: checkIfStripeScheduledSubscription
}
    `;

/**
 * __useCheckIfStripeScheduledSubscriptionQuery__
 *
 * To run a query within a React component, call `useCheckIfStripeScheduledSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfStripeScheduledSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfStripeScheduledSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckIfStripeScheduledSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<CheckIfStripeScheduledSubscriptionQuery, CheckIfStripeScheduledSubscriptionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<CheckIfStripeScheduledSubscriptionQuery, CheckIfStripeScheduledSubscriptionQueryVariables>(CheckIfStripeScheduledSubscriptionDocument, options);
}
export function useCheckIfStripeScheduledSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIfStripeScheduledSubscriptionQuery, CheckIfStripeScheduledSubscriptionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<CheckIfStripeScheduledSubscriptionQuery, CheckIfStripeScheduledSubscriptionQueryVariables>(CheckIfStripeScheduledSubscriptionDocument, options);
}
export type CheckIfStripeScheduledSubscriptionQueryHookResult = ReturnType<typeof useCheckIfStripeScheduledSubscriptionQuery>;
export type CheckIfStripeScheduledSubscriptionLazyQueryHookResult = ReturnType<typeof useCheckIfStripeScheduledSubscriptionLazyQuery>;
export type CheckIfStripeScheduledSubscriptionQueryResult = Apollo.QueryResult<CheckIfStripeScheduledSubscriptionQuery, CheckIfStripeScheduledSubscriptionQueryVariables>;
export const CheckStripeSubscriptionDiscountOfferDocument = gql`
    query checkStripeSubscriptionDiscountOffer {
  checkStripeSubscriptionDiscountOffer {
    newPrice
    cancelConfigId
    couponCode
    acceptCta
    rejectCta
    headline
    subheadline
    offer
    normalPrice
  }
}
    `;

/**
 * __useCheckStripeSubscriptionDiscountOfferQuery__
 *
 * To run a query within a React component, call `useCheckStripeSubscriptionDiscountOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStripeSubscriptionDiscountOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStripeSubscriptionDiscountOfferQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckStripeSubscriptionDiscountOfferQuery(baseOptions?: Apollo.QueryHookOptions<CheckStripeSubscriptionDiscountOfferQuery, CheckStripeSubscriptionDiscountOfferQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<CheckStripeSubscriptionDiscountOfferQuery, CheckStripeSubscriptionDiscountOfferQueryVariables>(CheckStripeSubscriptionDiscountOfferDocument, options);
}
export function useCheckStripeSubscriptionDiscountOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckStripeSubscriptionDiscountOfferQuery, CheckStripeSubscriptionDiscountOfferQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<CheckStripeSubscriptionDiscountOfferQuery, CheckStripeSubscriptionDiscountOfferQueryVariables>(CheckStripeSubscriptionDiscountOfferDocument, options);
}
export type CheckStripeSubscriptionDiscountOfferQueryHookResult = ReturnType<typeof useCheckStripeSubscriptionDiscountOfferQuery>;
export type CheckStripeSubscriptionDiscountOfferLazyQueryHookResult = ReturnType<typeof useCheckStripeSubscriptionDiscountOfferLazyQuery>;
export type CheckStripeSubscriptionDiscountOfferQueryResult = Apollo.QueryResult<CheckStripeSubscriptionDiscountOfferQuery, CheckStripeSubscriptionDiscountOfferQueryVariables>;
export const GetCertificateDocument = gql`
    query getCertificate($certificateId: String!) {
  getCertificate(certificateId: $certificateId) {
    certificateId
    title
    description
    slug
    type
    publishStatus
    coverImageUrl
    badgeImageUrl
    primaryColor
    meta
    isRegistered
    isCompleted
    numElectiveCourses
    groups {
      certificateGroupId
      title
      description
      publishStatus
      meta
      isCapstoneGroup
      courses {
        courseId
        publishStatus
        meta
        liveOnly
        completionRequired
        sortOrder
      }
    }
  }
  listUserSessions {
    ...SessionByUser
  }
  listPastSessions {
    sessionId
    courseId
    title
    subtitle
    coverImageUrl
    courseType
    sessionType
    gatedAccessType
    faculties {
      ...courseFaculties
    }
    startAt
    endAt
    enrollmentClose
    onDemandAvailable
  }
  listCourseBookmarks {
    courseId
  }
}
    ${SessionByUserFragmentDoc}
${CourseFacultiesFragmentDoc}`;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useGetCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
}
export function useGetCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
}
export type GetCertificateQueryHookResult = ReturnType<typeof useGetCertificateQuery>;
export type GetCertificateLazyQueryHookResult = ReturnType<typeof useGetCertificateLazyQuery>;
export type GetCertificateQueryResult = Apollo.QueryResult<GetCertificateQuery, GetCertificateQueryVariables>;
export const GetEntitledBootcampSessionsDocument = gql`
    query GetEntitledBootcampSessions {
  getEntitledBootcampSessions {
    sessionId
    title
    subtitle
    coverImageUrl
    startAt
    endAt
  }
}
    `;

/**
 * __useGetEntitledBootcampSessionsQuery__
 *
 * To run a query within a React component, call `useGetEntitledBootcampSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitledBootcampSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitledBootcampSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntitledBootcampSessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEntitledBootcampSessionsQuery, GetEntitledBootcampSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetEntitledBootcampSessionsQuery, GetEntitledBootcampSessionsQueryVariables>(GetEntitledBootcampSessionsDocument, options);
}
export function useGetEntitledBootcampSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitledBootcampSessionsQuery, GetEntitledBootcampSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetEntitledBootcampSessionsQuery, GetEntitledBootcampSessionsQueryVariables>(GetEntitledBootcampSessionsDocument, options);
}
export type GetEntitledBootcampSessionsQueryHookResult = ReturnType<typeof useGetEntitledBootcampSessionsQuery>;
export type GetEntitledBootcampSessionsLazyQueryHookResult = ReturnType<typeof useGetEntitledBootcampSessionsLazyQuery>;
export type GetEntitledBootcampSessionsQueryResult = Apollo.QueryResult<GetEntitledBootcampSessionsQuery, GetEntitledBootcampSessionsQueryVariables>;
export const GetMemberDocument = gql`
    query GetMember($userId: String) {
  getMember(userId: $userId) {
    about
    accountType
    alternateSSOEmail
    communityGet
    communityGive
    company {
      name
      userCompanyId
    }
    customer {
      customerId
      name
      type
    }
    displayName
    email
    firstName
    growthTip
    hasOngoingSprintAccess
    industry
    instagramProfileUrl
    jobFunction
    jobRole
    languages {
      name
    }
    lastName
    linkedinProfileUrl
    location {
      city
      country
      primaryLocation
      region
      userLocationId
    }
    manageBilling
    meetPreference
    middleName
    profileImageUrl
    pronouns
    registrationDate
    scholarship {
      name
      scholarshipId
      type
    }
    trialEndDate
    status
    testData
    twitterProfileUrl
    userId
    userType
    whatILove
    wpUserId
  }
  getMemberEntitlements(userId: $userId) {
    entitlementId
    type
    label
    precedence
    customization {
      navLogoUrl
    }
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
}
export function useGetMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
}
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = Apollo.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const GetMemberEventDocument = gql`
    query getMemberEvent($memberEventId: String!) {
  getMemberEvent(memberEventId: $memberEventId) {
    memberEventId
    eventStart
    eventEnd
    title
    subtitle
    details
    zoomLink
    status
    heroImageUrl
    eventType {
      label
    }
    eventCategory {
      category
      label
    }
  }
}
    `;

/**
 * __useGetMemberEventQuery__
 *
 * To run a query within a React component, call `useGetMemberEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberEventQuery({
 *   variables: {
 *      memberEventId: // value for 'memberEventId'
 *   },
 * });
 */
export function useGetMemberEventQuery(baseOptions: Apollo.QueryHookOptions<GetMemberEventQuery, GetMemberEventQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetMemberEventQuery, GetMemberEventQueryVariables>(GetMemberEventDocument, options);
}
export function useGetMemberEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberEventQuery, GetMemberEventQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetMemberEventQuery, GetMemberEventQueryVariables>(GetMemberEventDocument, options);
}
export type GetMemberEventQueryHookResult = ReturnType<typeof useGetMemberEventQuery>;
export type GetMemberEventLazyQueryHookResult = ReturnType<typeof useGetMemberEventLazyQuery>;
export type GetMemberEventQueryResult = Apollo.QueryResult<GetMemberEventQuery, GetMemberEventQueryVariables>;
export const GetMemberEventRsvpDocument = gql`
    query getMemberEventRsvp($userId: String!, $memberEventId: String!) {
  getMemberEventRsvp(userId: $userId, memberEventId: $memberEventId) {
    memberEventId
    status
    userId
    zoomJoinUrl
    zoomRegistrantId
  }
}
    `;

/**
 * __useGetMemberEventRsvpQuery__
 *
 * To run a query within a React component, call `useGetMemberEventRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberEventRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberEventRsvpQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      memberEventId: // value for 'memberEventId'
 *   },
 * });
 */
export function useGetMemberEventRsvpQuery(baseOptions: Apollo.QueryHookOptions<GetMemberEventRsvpQuery, GetMemberEventRsvpQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetMemberEventRsvpQuery, GetMemberEventRsvpQueryVariables>(GetMemberEventRsvpDocument, options);
}
export function useGetMemberEventRsvpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberEventRsvpQuery, GetMemberEventRsvpQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetMemberEventRsvpQuery, GetMemberEventRsvpQueryVariables>(GetMemberEventRsvpDocument, options);
}
export type GetMemberEventRsvpQueryHookResult = ReturnType<typeof useGetMemberEventRsvpQuery>;
export type GetMemberEventRsvpLazyQueryHookResult = ReturnType<typeof useGetMemberEventRsvpLazyQuery>;
export type GetMemberEventRsvpQueryResult = Apollo.QueryResult<GetMemberEventRsvpQuery, GetMemberEventRsvpQueryVariables>;
export const GetOnDemandContentDocument = gql`
    query getOnDemandContent($courseId: String) {
  getOnDemandContent(courseId: $courseId) {
    courseId
    courseType
    title
    subtitle
    description
    hidePreview
    coverImageUrl
    courseMeta
    faculties {
      ...courseFaculties
    }
    groups {
      courseContentGroupId
      slug
      title
      description
      hidePreview
      meta
      content {
        courseContentId
        slug
        title
        description
        type
        hidePreview
        completionRequired
        meta
        video {
          videoId
          externalKey
          duration
          aspectWidth
          aspectHeight
          meta
          chapters {
            videoChapterId
            slug
            title
            startSeconds
            endSeconds
          }
        }
      }
    }
  }
}
    ${CourseFacultiesFragmentDoc}`;

/**
 * __useGetOnDemandContentQuery__
 *
 * To run a query within a React component, call `useGetOnDemandContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandContentQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetOnDemandContentQuery(baseOptions?: Apollo.QueryHookOptions<GetOnDemandContentQuery, GetOnDemandContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetOnDemandContentQuery, GetOnDemandContentQueryVariables>(GetOnDemandContentDocument, options);
}
export function useGetOnDemandContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnDemandContentQuery, GetOnDemandContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetOnDemandContentQuery, GetOnDemandContentQueryVariables>(GetOnDemandContentDocument, options);
}
export type GetOnDemandContentQueryHookResult = ReturnType<typeof useGetOnDemandContentQuery>;
export type GetOnDemandContentLazyQueryHookResult = ReturnType<typeof useGetOnDemandContentLazyQuery>;
export type GetOnDemandContentQueryResult = Apollo.QueryResult<GetOnDemandContentQuery, GetOnDemandContentQueryVariables>;
export const GetPageContentDocument = gql`
    query getPageContent($slug: String!, $langCode: PageContentLanguageCode = en, $publishStatuses: [String!]! = ["ACTIVE"]) {
  getPageContent(
    slug: $slug
    langCode: $langCode
    publishStatuses: $publishStatuses
  ) {
    slug
    langCode
    publishStatus
    meta
  }
}
    `;

/**
 * __useGetPageContentQuery__
 *
 * To run a query within a React component, call `useGetPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageContentQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      langCode: // value for 'langCode'
 *      publishStatuses: // value for 'publishStatuses'
 *   },
 * });
 */
export function useGetPageContentQuery(baseOptions: Apollo.QueryHookOptions<GetPageContentQuery, GetPageContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetPageContentQuery, GetPageContentQueryVariables>(GetPageContentDocument, options);
}
export function useGetPageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageContentQuery, GetPageContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetPageContentQuery, GetPageContentQueryVariables>(GetPageContentDocument, options);
}
export type GetPageContentQueryHookResult = ReturnType<typeof useGetPageContentQuery>;
export type GetPageContentLazyQueryHookResult = ReturnType<typeof useGetPageContentLazyQuery>;
export type GetPageContentQueryResult = Apollo.QueryResult<GetPageContentQuery, GetPageContentQueryVariables>;
export const GetPassDetailsByCodeDocument = gql`
    query getPassDetailsByCode($code: String!, $context: PassQueryContext!) {
  getPassDetailsByCode(code: $code, context: $context) {
    showRegistration
    targetSegment
    meta
    redirectUrl
    content {
      courseContentId
      slug
      title
      description
      videoThumbnail
      type
      video {
        videoId
        externalKey
        duration
        aspectWidth
        aspectHeight
      }
    }
  }
}
    `;

/**
 * __useGetPassDetailsByCodeQuery__
 *
 * To run a query within a React component, call `useGetPassDetailsByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassDetailsByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassDetailsByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetPassDetailsByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetPassDetailsByCodeQuery, GetPassDetailsByCodeQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetPassDetailsByCodeQuery, GetPassDetailsByCodeQueryVariables>(GetPassDetailsByCodeDocument, options);
}
export function useGetPassDetailsByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPassDetailsByCodeQuery, GetPassDetailsByCodeQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetPassDetailsByCodeQuery, GetPassDetailsByCodeQueryVariables>(GetPassDetailsByCodeDocument, options);
}
export type GetPassDetailsByCodeQueryHookResult = ReturnType<typeof useGetPassDetailsByCodeQuery>;
export type GetPassDetailsByCodeLazyQueryHookResult = ReturnType<typeof useGetPassDetailsByCodeLazyQuery>;
export type GetPassDetailsByCodeQueryResult = Apollo.QueryResult<GetPassDetailsByCodeQuery, GetPassDetailsByCodeQueryVariables>;
export const GetResourceDocument = gql`
    query getResource($resourceID: String, $slug: String) {
  getResource(resourceID: $resourceID, slug: $slug) {
    ...FullResource
  }
}
    ${FullResourceFragmentDoc}`;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      resourceID: // value for 'resourceID'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetResourceQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
}
export function useGetResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
}
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<typeof useGetResourceLazyQuery>;
export type GetResourceQueryResult = Apollo.QueryResult<GetResourceQuery, GetResourceQueryVariables>;
export const GetSessionContainerContentDocument = gql`
    query getSessionContainerContent($sessionContentIds: [String!]!) {
  getSessionContainerContent(sessionContentIds: $sessionContentIds) {
    sessionContentId
    parentSessionContentId
    title
    type
    intro
    body
    outro
    buttonText
    buttonUrl
    enabledAt
    calendarStartDate
    day1Text
    day2Text
    day3Text
    day4Text
    day5Text
    videoPreviews {
      videoId
      duration
      thumbnailUrl
    }
  }
}
    `;

/**
 * __useGetSessionContainerContentQuery__
 *
 * To run a query within a React component, call `useGetSessionContainerContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionContainerContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionContainerContentQuery({
 *   variables: {
 *      sessionContentIds: // value for 'sessionContentIds'
 *   },
 * });
 */
export function useGetSessionContainerContentQuery(baseOptions: Apollo.QueryHookOptions<GetSessionContainerContentQuery, GetSessionContainerContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetSessionContainerContentQuery, GetSessionContainerContentQueryVariables>(GetSessionContainerContentDocument, options);
}
export function useGetSessionContainerContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionContainerContentQuery, GetSessionContainerContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetSessionContainerContentQuery, GetSessionContainerContentQueryVariables>(GetSessionContainerContentDocument, options);
}
export type GetSessionContainerContentQueryHookResult = ReturnType<typeof useGetSessionContainerContentQuery>;
export type GetSessionContainerContentLazyQueryHookResult = ReturnType<typeof useGetSessionContainerContentLazyQuery>;
export type GetSessionContainerContentQueryResult = Apollo.QueryResult<GetSessionContainerContentQuery, GetSessionContainerContentQueryVariables>;
export const GetSessionContentDocument = gql`
    query getSessionContent($sessionId: String, $slug: String) {
  getSessionContent(sessionId: $sessionId, slug: $slug) {
    courseId
    sessionId
    title
    subtitle
    description
    coverImageUrl
    courseType
    sessionType
    sessionStartAt
    sessionEndAt
    announcements {
      sessionContentId
      announcement
      imageUrl
      stylePreset
      internalLinkUrl
      externalLinkUrl
    }
    containers {
      sessionContentId
      title
      expandedPlaceholder
      containsActiveContent
    }
  }
  getVideoProgressBySession(sessionId: $sessionId) {
    userId
    sessionId
    groups {
      groupSessionContentId
      videos {
        videoId
        duration
        farthestWatchedTime
        optional
      }
    }
  }
}
    `;

/**
 * __useGetSessionContentQuery__
 *
 * To run a query within a React component, call `useGetSessionContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionContentQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSessionContentQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionContentQuery, GetSessionContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetSessionContentQuery, GetSessionContentQueryVariables>(GetSessionContentDocument, options);
}
export function useGetSessionContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionContentQuery, GetSessionContentQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetSessionContentQuery, GetSessionContentQueryVariables>(GetSessionContentDocument, options);
}
export type GetSessionContentQueryHookResult = ReturnType<typeof useGetSessionContentQuery>;
export type GetSessionContentLazyQueryHookResult = ReturnType<typeof useGetSessionContentLazyQuery>;
export type GetSessionContentQueryResult = Apollo.QueryResult<GetSessionContentQuery, GetSessionContentQueryVariables>;
export const GetSessionContentVideosDocument = gql`
    query getSessionContentVideos($sessionId: String!, $videoGroupId: String!) {
  getSessionContentVideos(sessionId: $sessionId, videoGroupId: $videoGroupId) {
    videoId
    externalKey
    duration
    aspectWidth
    aspectHeight
    chapters {
      videoChapterId
      slug
      title
      startSeconds
      endSeconds
    }
    title
    description
    thumbnailUrl
  }
}
    `;

/**
 * __useGetSessionContentVideosQuery__
 *
 * To run a query within a React component, call `useGetSessionContentVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionContentVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionContentVideosQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      videoGroupId: // value for 'videoGroupId'
 *   },
 * });
 */
export function useGetSessionContentVideosQuery(baseOptions: Apollo.QueryHookOptions<GetSessionContentVideosQuery, GetSessionContentVideosQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetSessionContentVideosQuery, GetSessionContentVideosQueryVariables>(GetSessionContentVideosDocument, options);
}
export function useGetSessionContentVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionContentVideosQuery, GetSessionContentVideosQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetSessionContentVideosQuery, GetSessionContentVideosQueryVariables>(GetSessionContentVideosDocument, options);
}
export type GetSessionContentVideosQueryHookResult = ReturnType<typeof useGetSessionContentVideosQuery>;
export type GetSessionContentVideosLazyQueryHookResult = ReturnType<typeof useGetSessionContentVideosLazyQuery>;
export type GetSessionContentVideosQueryResult = Apollo.QueryResult<GetSessionContentVideosQuery, GetSessionContentVideosQueryVariables>;
export const GetSessionLinkDocument = gql`
    query getSessionLink($sessionId: String!, $sessionTitle: String!, $date: DateTime!, $forceRefresh: Boolean! = false) {
  getSessionLink(
    sessionId: $sessionId
    sessionTitle: $sessionTitle
    date: $date
    forceRefresh: $forceRefresh
  )
}
    `;

/**
 * __useGetSessionLinkQuery__
 *
 * To run a query within a React component, call `useGetSessionLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionLinkQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      sessionTitle: // value for 'sessionTitle'
 *      date: // value for 'date'
 *      forceRefresh: // value for 'forceRefresh'
 *   },
 * });
 */
export function useGetSessionLinkQuery(baseOptions: Apollo.QueryHookOptions<GetSessionLinkQuery, GetSessionLinkQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetSessionLinkQuery, GetSessionLinkQueryVariables>(GetSessionLinkDocument, options);
}
export function useGetSessionLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionLinkQuery, GetSessionLinkQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetSessionLinkQuery, GetSessionLinkQueryVariables>(GetSessionLinkDocument, options);
}
export type GetSessionLinkQueryHookResult = ReturnType<typeof useGetSessionLinkQuery>;
export type GetSessionLinkLazyQueryHookResult = ReturnType<typeof useGetSessionLinkLazyQuery>;
export type GetSessionLinkQueryResult = Apollo.QueryResult<GetSessionLinkQuery, GetSessionLinkQueryVariables>;
export const GetUserSessionDocument = gql`
    query getUserSession($where: GetUserSessionInput!) {
  getUserSession(where: $where) {
    ...SessionByUser
  }
}
    ${SessionByUserFragmentDoc}`;

/**
 * __useGetUserSessionQuery__
 *
 * To run a query within a React component, call `useGetUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserSessionQuery(baseOptions: Apollo.QueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, options);
}
export function useGetUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, options);
}
export type GetUserSessionQueryHookResult = ReturnType<typeof useGetUserSessionQuery>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionQueryResult = Apollo.QueryResult<GetUserSessionQuery, GetUserSessionQueryVariables>;
export const GetVideoProgressDocument = gql`
    query getVideoProgress($videoId: String!) {
  getVideoProgress(videoId: $videoId) {
    userId
    videoId
    continueAtTime
    farthestWatchedTime
  }
}
    `;

/**
 * __useGetVideoProgressQuery__
 *
 * To run a query within a React component, call `useGetVideoProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoProgressQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useGetVideoProgressQuery(baseOptions: Apollo.QueryHookOptions<GetVideoProgressQuery, GetVideoProgressQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetVideoProgressQuery, GetVideoProgressQueryVariables>(GetVideoProgressDocument, options);
}
export function useGetVideoProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoProgressQuery, GetVideoProgressQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetVideoProgressQuery, GetVideoProgressQueryVariables>(GetVideoProgressDocument, options);
}
export type GetVideoProgressQueryHookResult = ReturnType<typeof useGetVideoProgressQuery>;
export type GetVideoProgressLazyQueryHookResult = ReturnType<typeof useGetVideoProgressLazyQuery>;
export type GetVideoProgressQueryResult = Apollo.QueryResult<GetVideoProgressQuery, GetVideoProgressQueryVariables>;
export const GetVideoProgressByCourseDocument = gql`
    query getVideoProgressByCourse($courseId: String!) {
  getVideoProgressByCourse(courseId: $courseId) {
    userId
    courseId
    totalDuration
    totalFarthestWatchedTime
    groups {
      courseContentGroupId
      totalDuration
      totalFarthestWatchedTime
      videos {
        courseContentId
        videoId
        duration
        farthestWatchedTime
      }
    }
  }
}
    `;

/**
 * __useGetVideoProgressByCourseQuery__
 *
 * To run a query within a React component, call `useGetVideoProgressByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoProgressByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoProgressByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetVideoProgressByCourseQuery(baseOptions: Apollo.QueryHookOptions<GetVideoProgressByCourseQuery, GetVideoProgressByCourseQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetVideoProgressByCourseQuery, GetVideoProgressByCourseQueryVariables>(GetVideoProgressByCourseDocument, options);
}
export function useGetVideoProgressByCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoProgressByCourseQuery, GetVideoProgressByCourseQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetVideoProgressByCourseQuery, GetVideoProgressByCourseQueryVariables>(GetVideoProgressByCourseDocument, options);
}
export type GetVideoProgressByCourseQueryHookResult = ReturnType<typeof useGetVideoProgressByCourseQuery>;
export type GetVideoProgressByCourseLazyQueryHookResult = ReturnType<typeof useGetVideoProgressByCourseLazyQuery>;
export type GetVideoProgressByCourseQueryResult = Apollo.QueryResult<GetVideoProgressByCourseQuery, GetVideoProgressByCourseQueryVariables>;
export const GetVideoProgressBySessionDocument = gql`
    query getVideoProgressBySession($sessionId: String!) {
  getVideoProgressBySession(sessionId: $sessionId) {
    userId
    sessionId
    groups {
      groupSessionContentId
      videos {
        videoId
        duration
        farthestWatchedTime
        optional
      }
    }
  }
}
    `;

/**
 * __useGetVideoProgressBySessionQuery__
 *
 * To run a query within a React component, call `useGetVideoProgressBySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoProgressBySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoProgressBySessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetVideoProgressBySessionQuery(baseOptions: Apollo.QueryHookOptions<GetVideoProgressBySessionQuery, GetVideoProgressBySessionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<GetVideoProgressBySessionQuery, GetVideoProgressBySessionQueryVariables>(GetVideoProgressBySessionDocument, options);
}
export function useGetVideoProgressBySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoProgressBySessionQuery, GetVideoProgressBySessionQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<GetVideoProgressBySessionQuery, GetVideoProgressBySessionQueryVariables>(GetVideoProgressBySessionDocument, options);
}
export type GetVideoProgressBySessionQueryHookResult = ReturnType<typeof useGetVideoProgressBySessionQuery>;
export type GetVideoProgressBySessionLazyQueryHookResult = ReturnType<typeof useGetVideoProgressBySessionLazyQuery>;
export type GetVideoProgressBySessionQueryResult = Apollo.QueryResult<GetVideoProgressBySessionQuery, GetVideoProgressBySessionQueryVariables>;
export const ListCaseStudiesDocument = gql`
    query listCaseStudies($caseStudyId: String) {
  listCaseStudies(caseStudyId: $caseStudyId) {
    caseStudyId
    bodyText
    coverImageUrl
    publishedAt
    status
    title
    video
  }
}
    `;

/**
 * __useListCaseStudiesQuery__
 *
 * To run a query within a React component, call `useListCaseStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCaseStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCaseStudiesQuery({
 *   variables: {
 *      caseStudyId: // value for 'caseStudyId'
 *   },
 * });
 */
export function useListCaseStudiesQuery(baseOptions?: Apollo.QueryHookOptions<ListCaseStudiesQuery, ListCaseStudiesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListCaseStudiesQuery, ListCaseStudiesQueryVariables>(ListCaseStudiesDocument, options);
}
export function useListCaseStudiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCaseStudiesQuery, ListCaseStudiesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListCaseStudiesQuery, ListCaseStudiesQueryVariables>(ListCaseStudiesDocument, options);
}
export type ListCaseStudiesQueryHookResult = ReturnType<typeof useListCaseStudiesQuery>;
export type ListCaseStudiesLazyQueryHookResult = ReturnType<typeof useListCaseStudiesLazyQuery>;
export type ListCaseStudiesQueryResult = Apollo.QueryResult<ListCaseStudiesQuery, ListCaseStudiesQueryVariables>;
export const ListCertificatesDocument = gql`
    query listCertificates {
  listCertificates {
    certificateId
    title
    description
    slug
    type
    publishStatus
    coverImageUrl
    badgeImageUrl
    primaryColor
    meta
    isRegistered
    isCompleted
    numElectiveCourses
    groups {
      certificateGroupId
      title
      description
      publishStatus
      meta
      isCapstoneGroup
      courses {
        courseId
        title
        description
        type
        coverImageUrl
        publishStatus
        meta
        liveOnly
        completionRequired
        sortOrder
      }
    }
  }
}
    `;

/**
 * __useListCertificatesQuery__
 *
 * To run a query within a React component, call `useListCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCertificatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<ListCertificatesQuery, ListCertificatesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListCertificatesQuery, ListCertificatesQueryVariables>(ListCertificatesDocument, options);
}
export function useListCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCertificatesQuery, ListCertificatesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListCertificatesQuery, ListCertificatesQueryVariables>(ListCertificatesDocument, options);
}
export type ListCertificatesQueryHookResult = ReturnType<typeof useListCertificatesQuery>;
export type ListCertificatesLazyQueryHookResult = ReturnType<typeof useListCertificatesLazyQuery>;
export type ListCertificatesQueryResult = Apollo.QueryResult<ListCertificatesQuery, ListCertificatesQueryVariables>;
export const ListCourseBookmarksDocument = gql`
    query listCourseBookmarks {
  listCourseBookmarks {
    courseId
  }
}
    `;

/**
 * __useListCourseBookmarksQuery__
 *
 * To run a query within a React component, call `useListCourseBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCourseBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCourseBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCourseBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<ListCourseBookmarksQuery, ListCourseBookmarksQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListCourseBookmarksQuery, ListCourseBookmarksQueryVariables>(ListCourseBookmarksDocument, options);
}
export function useListCourseBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCourseBookmarksQuery, ListCourseBookmarksQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListCourseBookmarksQuery, ListCourseBookmarksQueryVariables>(ListCourseBookmarksDocument, options);
}
export type ListCourseBookmarksQueryHookResult = ReturnType<typeof useListCourseBookmarksQuery>;
export type ListCourseBookmarksLazyQueryHookResult = ReturnType<typeof useListCourseBookmarksLazyQuery>;
export type ListCourseBookmarksQueryResult = Apollo.QueryResult<ListCourseBookmarksQuery, ListCourseBookmarksQueryVariables>;
export const ListIndustryReferencesDocument = gql`
    query listIndustryReferences {
  listIndustryReferences {
    industryRefId
    industry
    sortOrder
    active
  }
}
    `;

/**
 * __useListIndustryReferencesQuery__
 *
 * To run a query within a React component, call `useListIndustryReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIndustryReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIndustryReferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIndustryReferencesQuery(baseOptions?: Apollo.QueryHookOptions<ListIndustryReferencesQuery, ListIndustryReferencesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListIndustryReferencesQuery, ListIndustryReferencesQueryVariables>(ListIndustryReferencesDocument, options);
}
export function useListIndustryReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIndustryReferencesQuery, ListIndustryReferencesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListIndustryReferencesQuery, ListIndustryReferencesQueryVariables>(ListIndustryReferencesDocument, options);
}
export type ListIndustryReferencesQueryHookResult = ReturnType<typeof useListIndustryReferencesQuery>;
export type ListIndustryReferencesLazyQueryHookResult = ReturnType<typeof useListIndustryReferencesLazyQuery>;
export type ListIndustryReferencesQueryResult = Apollo.QueryResult<ListIndustryReferencesQuery, ListIndustryReferencesQueryVariables>;
export const ListJobFunctionReferencesDocument = gql`
    query listJobFunctionReferences {
  listJobFunctionReferences {
    jobFunctionRefId
    jobFunction
    sortOrder
    active
  }
}
    `;

/**
 * __useListJobFunctionReferencesQuery__
 *
 * To run a query within a React component, call `useListJobFunctionReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobFunctionReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobFunctionReferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListJobFunctionReferencesQuery(baseOptions?: Apollo.QueryHookOptions<ListJobFunctionReferencesQuery, ListJobFunctionReferencesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListJobFunctionReferencesQuery, ListJobFunctionReferencesQueryVariables>(ListJobFunctionReferencesDocument, options);
}
export function useListJobFunctionReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListJobFunctionReferencesQuery, ListJobFunctionReferencesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListJobFunctionReferencesQuery, ListJobFunctionReferencesQueryVariables>(ListJobFunctionReferencesDocument, options);
}
export type ListJobFunctionReferencesQueryHookResult = ReturnType<typeof useListJobFunctionReferencesQuery>;
export type ListJobFunctionReferencesLazyQueryHookResult = ReturnType<typeof useListJobFunctionReferencesLazyQuery>;
export type ListJobFunctionReferencesQueryResult = Apollo.QueryResult<ListJobFunctionReferencesQuery, ListJobFunctionReferencesQueryVariables>;
export const ListMemberEventsDocument = gql`
    query listMemberEvents($category: String, $type: String) {
  listMemberEvents(category: $category, type: $type) {
    memberEventId
    eventStart
    eventEnd
    title
    subtitle
    details
    zoomLink
    status
    heroImageUrl
    eventType {
      type
      label
    }
    eventCategory {
      category
      label
    }
  }
}
    `;

/**
 * __useListMemberEventsQuery__
 *
 * To run a query within a React component, call `useListMemberEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberEventsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListMemberEventsQuery(baseOptions?: Apollo.QueryHookOptions<ListMemberEventsQuery, ListMemberEventsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListMemberEventsQuery, ListMemberEventsQueryVariables>(ListMemberEventsDocument, options);
}
export function useListMemberEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMemberEventsQuery, ListMemberEventsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListMemberEventsQuery, ListMemberEventsQueryVariables>(ListMemberEventsDocument, options);
}
export type ListMemberEventsQueryHookResult = ReturnType<typeof useListMemberEventsQuery>;
export type ListMemberEventsLazyQueryHookResult = ReturnType<typeof useListMemberEventsLazyQuery>;
export type ListMemberEventsQueryResult = Apollo.QueryResult<ListMemberEventsQuery, ListMemberEventsQueryVariables>;
export const ListMemberEventsRsvpDocument = gql`
    query listMemberEventsRsvp($userId: String!, $includePastEvents: Boolean!) {
  listMemberEventsRsvp(userId: $userId, includePastEvents: $includePastEvents) {
    memberEventId
    status
    userId
    zoomJoinUrl
    zoomRegistrantId
  }
}
    `;

/**
 * __useListMemberEventsRsvpQuery__
 *
 * To run a query within a React component, call `useListMemberEventsRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberEventsRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberEventsRsvpQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      includePastEvents: // value for 'includePastEvents'
 *   },
 * });
 */
export function useListMemberEventsRsvpQuery(baseOptions: Apollo.QueryHookOptions<ListMemberEventsRsvpQuery, ListMemberEventsRsvpQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListMemberEventsRsvpQuery, ListMemberEventsRsvpQueryVariables>(ListMemberEventsRsvpDocument, options);
}
export function useListMemberEventsRsvpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMemberEventsRsvpQuery, ListMemberEventsRsvpQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListMemberEventsRsvpQuery, ListMemberEventsRsvpQueryVariables>(ListMemberEventsRsvpDocument, options);
}
export type ListMemberEventsRsvpQueryHookResult = ReturnType<typeof useListMemberEventsRsvpQuery>;
export type ListMemberEventsRsvpLazyQueryHookResult = ReturnType<typeof useListMemberEventsRsvpLazyQuery>;
export type ListMemberEventsRsvpQueryResult = Apollo.QueryResult<ListMemberEventsRsvpQuery, ListMemberEventsRsvpQueryVariables>;
export const ListMembersDocument = gql`
    query listMembers($filter: String = "", $count: Int = 20, $page: Int = 0, $context: QueryContext = ADMIN) {
  listMembers(filter: $filter, count: $count, page: $page, context: $context) {
    list {
      userId
      company {
        userCompanyId
        name
      }
      displayName
      firstName
      jobRole
      lastName
      profileImageUrl
      registrationDate
    }
    pageInfo {
      totalPages
      total
      page
      count
      filter
    }
  }
}
    `;

/**
 * __useListMembersQuery__
 *
 * To run a query within a React component, call `useListMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      count: // value for 'count'
 *      page: // value for 'page'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useListMembersQuery(baseOptions?: Apollo.QueryHookOptions<ListMembersQuery, ListMembersQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, options);
}
export function useListMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMembersQuery, ListMembersQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, options);
}
export type ListMembersQueryHookResult = ReturnType<typeof useListMembersQuery>;
export type ListMembersLazyQueryHookResult = ReturnType<typeof useListMembersLazyQuery>;
export type ListMembersQueryResult = Apollo.QueryResult<ListMembersQuery, ListMembersQueryVariables>;
export const ListPastSessionsDocument = gql`
    query listPastSessions {
  listPastSessions {
    sessionId
    courseId
    title
    sessionType
    faculties {
      ...courseFaculties
    }
    courseType
    startAt
    endAt
    onDemandAvailable
  }
}
    ${CourseFacultiesFragmentDoc}`;

/**
 * __useListPastSessionsQuery__
 *
 * To run a query within a React component, call `useListPastSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPastSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPastSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPastSessionsQuery(baseOptions?: Apollo.QueryHookOptions<ListPastSessionsQuery, ListPastSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListPastSessionsQuery, ListPastSessionsQueryVariables>(ListPastSessionsDocument, options);
}
export function useListPastSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPastSessionsQuery, ListPastSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListPastSessionsQuery, ListPastSessionsQueryVariables>(ListPastSessionsDocument, options);
}
export type ListPastSessionsQueryHookResult = ReturnType<typeof useListPastSessionsQuery>;
export type ListPastSessionsLazyQueryHookResult = ReturnType<typeof useListPastSessionsLazyQuery>;
export type ListPastSessionsQueryResult = Apollo.QueryResult<ListPastSessionsQuery, ListPastSessionsQueryVariables>;
export const ListResourcesDocument = gql`
    query listResources($resourceIDs: [String], $count: Int = 24, $page: Int = 1) {
  listResources(resourceIDs: $resourceIDs, count: $count, page: $page) {
    list {
      ...ResourceTile
    }
    pageInfo {
      totalPages
      total
      page
      count
    }
  }
}
    ${ResourceTileFragmentDoc}`;

/**
 * __useListResourcesQuery__
 *
 * To run a query within a React component, call `useListResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListResourcesQuery({
 *   variables: {
 *      resourceIDs: // value for 'resourceIDs'
 *      count: // value for 'count'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ListResourcesQuery, ListResourcesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListResourcesQuery, ListResourcesQueryVariables>(ListResourcesDocument, options);
}
export function useListResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListResourcesQuery, ListResourcesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListResourcesQuery, ListResourcesQueryVariables>(ListResourcesDocument, options);
}
export type ListResourcesQueryHookResult = ReturnType<typeof useListResourcesQuery>;
export type ListResourcesLazyQueryHookResult = ReturnType<typeof useListResourcesLazyQuery>;
export type ListResourcesQueryResult = Apollo.QueryResult<ListResourcesQuery, ListResourcesQueryVariables>;
export const ListUserCourseDataDocument = gql`
    query listUserCourseData {
  listUserSessions {
    ...SessionByUser
  }
  listCourseBookmarks {
    courseId
  }
}
    ${SessionByUserFragmentDoc}`;

/**
 * __useListUserCourseDataQuery__
 *
 * To run a query within a React component, call `useListUserCourseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserCourseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserCourseDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserCourseDataQuery(baseOptions?: Apollo.QueryHookOptions<ListUserCourseDataQuery, ListUserCourseDataQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListUserCourseDataQuery, ListUserCourseDataQueryVariables>(ListUserCourseDataDocument, options);
}
export function useListUserCourseDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserCourseDataQuery, ListUserCourseDataQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListUserCourseDataQuery, ListUserCourseDataQueryVariables>(ListUserCourseDataDocument, options);
}
export type ListUserCourseDataQueryHookResult = ReturnType<typeof useListUserCourseDataQuery>;
export type ListUserCourseDataLazyQueryHookResult = ReturnType<typeof useListUserCourseDataLazyQuery>;
export type ListUserCourseDataQueryResult = Apollo.QueryResult<ListUserCourseDataQuery, ListUserCourseDataQueryVariables>;
export const ListUserSessionsDocument = gql`
    query listUserSessions {
  listUserSessions {
    ...SessionByUser
  }
}
    ${SessionByUserFragmentDoc}`;

/**
 * __useListUserSessionsQuery__
 *
 * To run a query within a React component, call `useListUserSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserSessionsQuery(baseOptions?: Apollo.QueryHookOptions<ListUserSessionsQuery, ListUserSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListUserSessionsQuery, ListUserSessionsQueryVariables>(ListUserSessionsDocument, options);
}
export function useListUserSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserSessionsQuery, ListUserSessionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListUserSessionsQuery, ListUserSessionsQueryVariables>(ListUserSessionsDocument, options);
}
export type ListUserSessionsQueryHookResult = ReturnType<typeof useListUserSessionsQuery>;
export type ListUserSessionsLazyQueryHookResult = ReturnType<typeof useListUserSessionsLazyQuery>;
export type ListUserSessionsQueryResult = Apollo.QueryResult<ListUserSessionsQuery, ListUserSessionsQueryVariables>;
export const ListUserSessionsByCourseDocument = gql`
    query listUserSessionsByCourse($where: ListUserSessionsByCourseInput!) {
  listUserSessionsByCourse(where: $where) {
    ...SessionByUser
  }
}
    ${SessionByUserFragmentDoc}`;

/**
 * __useListUserSessionsByCourseQuery__
 *
 * To run a query within a React component, call `useListUserSessionsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserSessionsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserSessionsByCourseQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListUserSessionsByCourseQuery(baseOptions: Apollo.QueryHookOptions<ListUserSessionsByCourseQuery, ListUserSessionsByCourseQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<ListUserSessionsByCourseQuery, ListUserSessionsByCourseQueryVariables>(ListUserSessionsByCourseDocument, options);
}
export function useListUserSessionsByCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserSessionsByCourseQuery, ListUserSessionsByCourseQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<ListUserSessionsByCourseQuery, ListUserSessionsByCourseQueryVariables>(ListUserSessionsByCourseDocument, options);
}
export type ListUserSessionsByCourseQueryHookResult = ReturnType<typeof useListUserSessionsByCourseQuery>;
export type ListUserSessionsByCourseLazyQueryHookResult = ReturnType<typeof useListUserSessionsByCourseLazyQuery>;
export type ListUserSessionsByCourseQueryResult = Apollo.QueryResult<ListUserSessionsByCourseQuery, ListUserSessionsByCourseQueryVariables>;
export const SearchCoursesDocument = gql`
    query searchCourses($input: SearchCoursesInput!) {
  searchCourses(query: $input) {
    courseId
    score
  }
}
    `;

/**
 * __useSearchCoursesQuery__
 *
 * To run a query within a React component, call `useSearchCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchCoursesQuery(baseOptions: Apollo.QueryHookOptions<SearchCoursesQuery, SearchCoursesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useQuery<SearchCoursesQuery, SearchCoursesQueryVariables>(SearchCoursesDocument, options);
}
export function useSearchCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCoursesQuery, SearchCoursesQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions}
	return Apollo.useLazyQuery<SearchCoursesQuery, SearchCoursesQueryVariables>(SearchCoursesDocument, options);
}
export type SearchCoursesQueryHookResult = ReturnType<typeof useSearchCoursesQuery>;
export type SearchCoursesLazyQueryHookResult = ReturnType<typeof useSearchCoursesLazyQuery>;
export type SearchCoursesQueryResult = Apollo.QueryResult<SearchCoursesQuery, SearchCoursesQueryVariables>;
export namespace CourseFaculties {
	export type Fragment = CourseFacultiesFragment;
}

export namespace FullResource {
	export type Fragment = FullResourceFragment;
	export type Items = NonNullable<(NonNullable<FullResourceFragment['items']>)[number]>;
	export type Tags = NonNullable<(NonNullable<FullResourceFragment['tags']>)[number]>;
}

export namespace Member {
	export type Fragment = MemberFragment;
	export type Company = (NonNullable<MemberFragment['company']>);
	export type Languages = NonNullable<(NonNullable<MemberFragment['languages']>)[number]>;
	export type Location = (NonNullable<MemberFragment['location']>);
}

export namespace ResourceTile {
	export type Fragment = ResourceTileFragment;
	export type Tags = NonNullable<(NonNullable<ResourceTileFragment['tags']>)[number]>;
}

export namespace SessionByUser {
	export type Fragment = SessionByUserFragment;
	export type Faculties = NonNullable<(NonNullable<SessionByUserFragment['faculties']>)[number]>;
}

export namespace AddCertificateCompletion {
	export type Variables = AddCertificateCompletionMutationVariables;
	export type Mutation = AddCertificateCompletionMutation;
	export const Document = AddCertificateCompletionDocument;
	export const use = useAddCertificateCompletionMutation;
}

export namespace AddCertificateRegistration {
	export type Variables = AddCertificateRegistrationMutationVariables;
	export type Mutation = AddCertificateRegistrationMutation;
	export const Document = AddCertificateRegistrationDocument;
	export const use = useAddCertificateRegistrationMutation;
}

export namespace AddUserSave {
	export type Variables = AddUserSaveMutationVariables;
	export type Mutation = AddUserSaveMutation;
	export type AddUserSave = (NonNullable<AddUserSaveMutation['addUserSave']>);
	export type Error = (NonNullable<(NonNullable<AddUserSaveMutation['addUserSave']>)['error']>);
	export const Document = AddUserSaveDocument;
	export const use = useAddUserSaveMutation;
}

export namespace CreateSignedUploadUrl {
	export type Variables = CreateSignedUploadUrlMutationVariables;
	export type Mutation = CreateSignedUploadUrlMutation;
	export type CreateSignedUploadUrl = (NonNullable<CreateSignedUploadUrlMutation['createSignedUploadUrl']>);
	export type Fields = (NonNullable<(NonNullable<CreateSignedUploadUrlMutation['createSignedUploadUrl']>)['fields']>);
	export const Document = CreateSignedUploadUrlDocument;
	export const use = useCreateSignedUploadUrlMutation;
}

export namespace CreateStripePortalUrl {
	export type Variables = CreateStripePortalUrlMutationVariables;
	export type Mutation = CreateStripePortalUrlMutation;
	export const Document = CreateStripePortalUrlDocument;
	export const use = useCreateStripePortalUrlMutation;
}

export namespace CreateStripeSubscriptionCheckoutSession {
	export type Variables = CreateStripeSubscriptionCheckoutSessionMutationVariables;
	export type Mutation = CreateStripeSubscriptionCheckoutSessionMutation;
	export const Document = CreateStripeSubscriptionCheckoutSessionDocument;
	export const use = useCreateStripeSubscriptionCheckoutSessionMutation;
}

export namespace CreateUserWithPass {
	export type Variables = CreateUserWithPassMutationVariables;
	export type Mutation = CreateUserWithPassMutation;
	export type CreateUserWithPass = (NonNullable<CreateUserWithPassMutation['createUserWithPass']>);
	export type Error = (NonNullable<(NonNullable<CreateUserWithPassMutation['createUserWithPass']>)['error']>);
	export const Document = CreateUserWithPassDocument;
	export const use = useCreateUserWithPassMutation;
}

export namespace GetShareLinks {
	export type Variables = GetShareLinksMutationVariables;
	export type Mutation = GetShareLinksMutation;
	export type GetShareLinks = NonNullable<(NonNullable<GetShareLinksMutation['getShareLinks']>)[number]>;
	export const Document = GetShareLinksDocument;
	export const use = useGetShareLinksMutation;
}

export namespace Logout {
	export type Variables = LogoutMutationVariables;
	export type Mutation = LogoutMutation;
	export type Logout = (NonNullable<LogoutMutation['logout']>);
	export const Document = LogoutDocument;
	export const use = useLogoutMutation;
}

export namespace LookupActivationLink {
	export type Variables = LookupActivationLinkMutationVariables;
	export type Mutation = LookupActivationLinkMutation;
	export type LookupActivationLink = (NonNullable<LookupActivationLinkMutation['lookupActivationLink']>);
	export const Document = LookupActivationLinkDocument;
	export const use = useLookupActivationLinkMutation;
}

export namespace RemoveCertificateCompletion {
	export type Variables = RemoveCertificateCompletionMutationVariables;
	export type Mutation = RemoveCertificateCompletionMutation;
	export const Document = RemoveCertificateCompletionDocument;
	export const use = useRemoveCertificateCompletionMutation;
}

export namespace RemoveCertificateRegistration {
	export type Variables = RemoveCertificateRegistrationMutationVariables;
	export type Mutation = RemoveCertificateRegistrationMutation;
	export const Document = RemoveCertificateRegistrationDocument;
	export const use = useRemoveCertificateRegistrationMutation;
}

export namespace RemoveUserSave {
	export type Variables = RemoveUserSaveMutationVariables;
	export type Mutation = RemoveUserSaveMutation;
	export type RemoveUserSave = (NonNullable<RemoveUserSaveMutation['removeUserSave']>);
	export type Error = (NonNullable<(NonNullable<RemoveUserSaveMutation['removeUserSave']>)['error']>);
	export const Document = RemoveUserSaveDocument;
	export const use = useRemoveUserSaveMutation;
}

export namespace SaveEnrollment {
	export type Variables = SaveEnrollmentMutationVariables;
	export type Mutation = SaveEnrollmentMutation;
	export type SaveEnrollment = (NonNullable<SaveEnrollmentMutation['saveEnrollment']>);
	export const Document = SaveEnrollmentDocument;
	export const use = useSaveEnrollmentMutation;
}

export namespace SaveMemberBasicInfo {
	export type Variables = SaveMemberBasicInfoMutationVariables;
	export type Mutation = SaveMemberBasicInfoMutation;
	export type SaveMemberBasicInfo = (NonNullable<SaveMemberBasicInfoMutation['saveMemberBasicInfo']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberBasicInfoMutation['saveMemberBasicInfo']>)['error']>);
	export const Document = SaveMemberBasicInfoDocument;
	export const use = useSaveMemberBasicInfoMutation;
}

export namespace SaveMemberCommunity {
	export type Variables = SaveMemberCommunityMutationVariables;
	export type Mutation = SaveMemberCommunityMutation;
	export type SaveMemberCommunity = (NonNullable<SaveMemberCommunityMutation['saveMemberCommunity']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberCommunityMutation['saveMemberCommunity']>)['error']>);
	export const Document = SaveMemberCommunityDocument;
	export const use = useSaveMemberCommunityMutation;
}

export namespace SaveMemberCompany {
	export type Variables = SaveMemberCompanyMutationVariables;
	export type Mutation = SaveMemberCompanyMutation;
	export type SaveMemberCompany = (NonNullable<SaveMemberCompanyMutation['saveMemberCompany']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberCompanyMutation['saveMemberCompany']>)['error']>);
	export const Document = SaveMemberCompanyDocument;
	export const use = useSaveMemberCompanyMutation;
}

export namespace SaveMemberEventRsvp {
	export type Variables = SaveMemberEventRsvpMutationVariables;
	export type Mutation = SaveMemberEventRsvpMutation;
	export type SaveMemberEventRsvp = (NonNullable<SaveMemberEventRsvpMutation['saveMemberEventRsvp']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberEventRsvpMutation['saveMemberEventRsvp']>)['error']>);
	export const Document = SaveMemberEventRsvpDocument;
	export const use = useSaveMemberEventRsvpMutation;
}

export namespace SaveMemberJobInfo {
	export type Variables = SaveMemberJobInfoMutationVariables;
	export type Mutation = SaveMemberJobInfoMutation;
	export type SaveMemberJobInfo = (NonNullable<SaveMemberJobInfoMutation['saveMemberJobInfo']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberJobInfoMutation['saveMemberJobInfo']>)['error']>);
	export const Document = SaveMemberJobInfoDocument;
	export const use = useSaveMemberJobInfoMutation;
}

export namespace SaveMemberLocation {
	export type Variables = SaveMemberLocationMutationVariables;
	export type Mutation = SaveMemberLocationMutation;
	export type SaveMemberLocation = (NonNullable<SaveMemberLocationMutation['saveMemberLocation']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberLocationMutation['saveMemberLocation']>)['error']>);
	export const Document = SaveMemberLocationDocument;
	export const use = useSaveMemberLocationMutation;
}

export namespace SaveMemberSocialInfo {
	export type Variables = SaveMemberSocialInfoMutationVariables;
	export type Mutation = SaveMemberSocialInfoMutation;
	export type SaveMemberSocialInfo = (NonNullable<SaveMemberSocialInfoMutation['saveMemberSocialInfo']>);
	export type Error = (NonNullable<(NonNullable<SaveMemberSocialInfoMutation['saveMemberSocialInfo']>)['error']>);
	export const Document = SaveMemberSocialInfoDocument;
	export const use = useSaveMemberSocialInfoMutation;
}

export namespace SendVerificationCode {
	export type Variables = SendVerificationCodeMutationVariables;
	export type Mutation = SendVerificationCodeMutation;
	export type SendVerificationCode = (NonNullable<SendVerificationCodeMutation['sendVerificationCode']>);
	export const Document = SendVerificationCodeDocument;
	export const use = useSendVerificationCodeMutation;
}

export namespace StartAccountLinking {
	export type Variables = StartAccountLinkingMutationVariables;
	export type Mutation = StartAccountLinkingMutation;
	export type StartAccountLinking = (NonNullable<StartAccountLinkingMutation['startAccountLinking']>);
	export type Error = (NonNullable<(NonNullable<StartAccountLinkingMutation['startAccountLinking']>)['error']>);
	export const Document = StartAccountLinkingDocument;
	export const use = useStartAccountLinkingMutation;
}

export namespace UnlinkAccount {
	export type Variables = UnlinkAccountMutationVariables;
	export type Mutation = UnlinkAccountMutation;
	export type UnlinkAccount = (NonNullable<UnlinkAccountMutation['unlinkAccount']>);
	export type Error = (NonNullable<(NonNullable<UnlinkAccountMutation['unlinkAccount']>)['error']>);
	export const Document = UnlinkAccountDocument;
	export const use = useUnlinkAccountMutation;
}

export namespace UpdateExistingStripeSubscription {
	export type Variables = UpdateExistingStripeSubscriptionMutationVariables;
	export type Mutation = UpdateExistingStripeSubscriptionMutation;
	export type UpdateExistingStripeSubscription = (NonNullable<UpdateExistingStripeSubscriptionMutation['updateExistingStripeSubscription']>);
	export const Document = UpdateExistingStripeSubscriptionDocument;
	export const use = useUpdateExistingStripeSubscriptionMutation;
}

export namespace UpdateVideoProgress {
	export type Variables = UpdateVideoProgressMutationVariables;
	export type Mutation = UpdateVideoProgressMutation;
	export type UpdateVideoProgress = (NonNullable<UpdateVideoProgressMutation['updateVideoProgress']>);
	export const Document = UpdateVideoProgressDocument;
	export const use = useUpdateVideoProgressMutation;
}

export namespace CheckIfStripeScheduledSubscription {
	export type Variables = CheckIfStripeScheduledSubscriptionQueryVariables;
	export type Query = CheckIfStripeScheduledSubscriptionQuery;
	export const Document = CheckIfStripeScheduledSubscriptionDocument;
	export const use = useCheckIfStripeScheduledSubscriptionQuery;
}

export namespace CheckStripeSubscriptionDiscountOffer {
	export type Variables = CheckStripeSubscriptionDiscountOfferQueryVariables;
	export type Query = CheckStripeSubscriptionDiscountOfferQuery;
	export type CheckStripeSubscriptionDiscountOffer = (NonNullable<CheckStripeSubscriptionDiscountOfferQuery['checkStripeSubscriptionDiscountOffer']>);
	export const Document = CheckStripeSubscriptionDiscountOfferDocument;
	export const use = useCheckStripeSubscriptionDiscountOfferQuery;
}

export namespace GetCertificate {
	export type Variables = GetCertificateQueryVariables;
	export type Query = GetCertificateQuery;
	export type GetCertificate = (NonNullable<GetCertificateQuery['getCertificate']>);
	export type Groups = NonNullable<(NonNullable<(NonNullable<GetCertificateQuery['getCertificate']>)['groups']>)[number]>;
	export type Courses = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetCertificateQuery['getCertificate']>)['groups']>)[number]>['courses']>)[number]>;
	export type ListUserSessions = NonNullable<(NonNullable<GetCertificateQuery['listUserSessions']>)[number]>;
	export type ListPastSessions = NonNullable<(NonNullable<GetCertificateQuery['listPastSessions']>)[number]>;
	export type Faculties = NonNullable<(NonNullable<NonNullable<(NonNullable<GetCertificateQuery['listPastSessions']>)[number]>['faculties']>)[number]>;
	export type ListCourseBookmarks = NonNullable<(NonNullable<GetCertificateQuery['listCourseBookmarks']>)[number]>;
	export const Document = GetCertificateDocument;
	export const use = useGetCertificateQuery;
}

export namespace GetEntitledBootcampSessions {
	export type Variables = GetEntitledBootcampSessionsQueryVariables;
	export type Query = GetEntitledBootcampSessionsQuery;
	export type GetEntitledBootcampSessions = NonNullable<(NonNullable<GetEntitledBootcampSessionsQuery['getEntitledBootcampSessions']>)[number]>;
	export const Document = GetEntitledBootcampSessionsDocument;
	export const use = useGetEntitledBootcampSessionsQuery;
}

export namespace GetMember {
	export type Variables = GetMemberQueryVariables;
	export type Query = GetMemberQuery;
	export type GetMember = (NonNullable<GetMemberQuery['getMember']>);
	export type Company = (NonNullable<(NonNullable<GetMemberQuery['getMember']>)['company']>);
	export type Customer = (NonNullable<(NonNullable<GetMemberQuery['getMember']>)['customer']>);
	export type Languages = NonNullable<(NonNullable<(NonNullable<GetMemberQuery['getMember']>)['languages']>)[number]>;
	export type Location = (NonNullable<(NonNullable<GetMemberQuery['getMember']>)['location']>);
	export type Scholarship = (NonNullable<(NonNullable<GetMemberQuery['getMember']>)['scholarship']>);
	export type GetMemberEntitlements = NonNullable<(NonNullable<GetMemberQuery['getMemberEntitlements']>)[number]>;
	export type Customization = (NonNullable<NonNullable<(NonNullable<GetMemberQuery['getMemberEntitlements']>)[number]>['customization']>);
	export const Document = GetMemberDocument;
	export const use = useGetMemberQuery;
}

export namespace GetMemberEvent {
	export type Variables = GetMemberEventQueryVariables;
	export type Query = GetMemberEventQuery;
	export type GetMemberEvent = (NonNullable<GetMemberEventQuery['getMemberEvent']>);
	export type EventType = (NonNullable<(NonNullable<GetMemberEventQuery['getMemberEvent']>)['eventType']>);
	export type EventCategory = (NonNullable<(NonNullable<GetMemberEventQuery['getMemberEvent']>)['eventCategory']>);
	export const Document = GetMemberEventDocument;
	export const use = useGetMemberEventQuery;
}

export namespace GetMemberEventRsvp {
	export type Variables = GetMemberEventRsvpQueryVariables;
	export type Query = GetMemberEventRsvpQuery;
	export type GetMemberEventRsvp = (NonNullable<GetMemberEventRsvpQuery['getMemberEventRsvp']>);
	export const Document = GetMemberEventRsvpDocument;
	export const use = useGetMemberEventRsvpQuery;
}

export namespace GetOnDemandContent {
	export type Variables = GetOnDemandContentQueryVariables;
	export type Query = GetOnDemandContentQuery;
	export type GetOnDemandContent = (NonNullable<GetOnDemandContentQuery['getOnDemandContent']>);
	export type Faculties = NonNullable<(NonNullable<(NonNullable<GetOnDemandContentQuery['getOnDemandContent']>)['faculties']>)[number]>;
	export type Groups = NonNullable<(NonNullable<(NonNullable<GetOnDemandContentQuery['getOnDemandContent']>)['groups']>)[number]>;
	export type Content = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetOnDemandContentQuery['getOnDemandContent']>)['groups']>)[number]>['content']>)[number]>;
	export type Video = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetOnDemandContentQuery['getOnDemandContent']>)['groups']>)[number]>['content']>)[number]>['video']>);
	export type Chapters = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetOnDemandContentQuery['getOnDemandContent']>)['groups']>)[number]>['content']>)[number]>['video']>)['chapters']>)[number]>;
	export const Document = GetOnDemandContentDocument;
	export const use = useGetOnDemandContentQuery;
}

export namespace GetPageContent {
	export type Variables = GetPageContentQueryVariables;
	export type Query = GetPageContentQuery;
	export type GetPageContent = (NonNullable<GetPageContentQuery['getPageContent']>);
	export const Document = GetPageContentDocument;
	export const use = useGetPageContentQuery;
}

export namespace GetPassDetailsByCode {
	export type Variables = GetPassDetailsByCodeQueryVariables;
	export type Query = GetPassDetailsByCodeQuery;
	export type GetPassDetailsByCode = (NonNullable<GetPassDetailsByCodeQuery['getPassDetailsByCode']>);
	export type Content = NonNullable<(NonNullable<(NonNullable<GetPassDetailsByCodeQuery['getPassDetailsByCode']>)['content']>)[number]>;
	export type Video = (NonNullable<NonNullable<(NonNullable<(NonNullable<GetPassDetailsByCodeQuery['getPassDetailsByCode']>)['content']>)[number]>['video']>);
	export const Document = GetPassDetailsByCodeDocument;
	export const use = useGetPassDetailsByCodeQuery;
}

export namespace GetResource {
	export type Variables = GetResourceQueryVariables;
	export type Query = GetResourceQuery;
	export type GetResource = (NonNullable<GetResourceQuery['getResource']>);
	export const Document = GetResourceDocument;
	export const use = useGetResourceQuery;
}

export namespace GetSessionContainerContent {
	export type Variables = GetSessionContainerContentQueryVariables;
	export type Query = GetSessionContainerContentQuery;
	export type GetSessionContainerContent = NonNullable<(NonNullable<GetSessionContainerContentQuery['getSessionContainerContent']>)[number]>;
	export type VideoPreviews = NonNullable<(NonNullable<NonNullable<(NonNullable<GetSessionContainerContentQuery['getSessionContainerContent']>)[number]>['videoPreviews']>)[number]>;
	export const Document = GetSessionContainerContentDocument;
	export const use = useGetSessionContainerContentQuery;
}

export namespace GetSessionContent {
	export type Variables = GetSessionContentQueryVariables;
	export type Query = GetSessionContentQuery;
	export type GetSessionContent = (NonNullable<GetSessionContentQuery['getSessionContent']>);
	export type Announcements = NonNullable<(NonNullable<(NonNullable<GetSessionContentQuery['getSessionContent']>)['announcements']>)[number]>;
	export type Containers = NonNullable<(NonNullable<(NonNullable<GetSessionContentQuery['getSessionContent']>)['containers']>)[number]>;
	export type GetVideoProgressBySession = (NonNullable<GetSessionContentQuery['getVideoProgressBySession']>);
	export type Groups = NonNullable<(NonNullable<(NonNullable<GetSessionContentQuery['getVideoProgressBySession']>)['groups']>)[number]>;
	export type Videos = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetSessionContentQuery['getVideoProgressBySession']>)['groups']>)[number]>['videos']>)[number]>;
	export const Document = GetSessionContentDocument;
	export const use = useGetSessionContentQuery;
}

export namespace GetSessionContentVideos {
	export type Variables = GetSessionContentVideosQueryVariables;
	export type Query = GetSessionContentVideosQuery;
	export type GetSessionContentVideos = NonNullable<(NonNullable<GetSessionContentVideosQuery['getSessionContentVideos']>)[number]>;
	export type Chapters = NonNullable<(NonNullable<NonNullable<(NonNullable<GetSessionContentVideosQuery['getSessionContentVideos']>)[number]>['chapters']>)[number]>;
	export const Document = GetSessionContentVideosDocument;
	export const use = useGetSessionContentVideosQuery;
}

export namespace GetSessionLink {
	export type Variables = GetSessionLinkQueryVariables;
	export type Query = GetSessionLinkQuery;
	export const Document = GetSessionLinkDocument;
	export const use = useGetSessionLinkQuery;
}

export namespace GetUserSession {
	export type Variables = GetUserSessionQueryVariables;
	export type Query = GetUserSessionQuery;
	export type GetUserSession = (NonNullable<GetUserSessionQuery['getUserSession']>);
	export const Document = GetUserSessionDocument;
	export const use = useGetUserSessionQuery;
}

export namespace GetVideoProgress {
	export type Variables = GetVideoProgressQueryVariables;
	export type Query = GetVideoProgressQuery;
	export type GetVideoProgress = (NonNullable<GetVideoProgressQuery['getVideoProgress']>);
	export const Document = GetVideoProgressDocument;
	export const use = useGetVideoProgressQuery;
}

export namespace GetVideoProgressByCourse {
	export type Variables = GetVideoProgressByCourseQueryVariables;
	export type Query = GetVideoProgressByCourseQuery;
	export type GetVideoProgressByCourse = (NonNullable<GetVideoProgressByCourseQuery['getVideoProgressByCourse']>);
	export type Groups = NonNullable<(NonNullable<(NonNullable<GetVideoProgressByCourseQuery['getVideoProgressByCourse']>)['groups']>)[number]>;
	export type Videos = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetVideoProgressByCourseQuery['getVideoProgressByCourse']>)['groups']>)[number]>['videos']>)[number]>;
	export const Document = GetVideoProgressByCourseDocument;
	export const use = useGetVideoProgressByCourseQuery;
}

export namespace GetVideoProgressBySession {
	export type Variables = GetVideoProgressBySessionQueryVariables;
	export type Query = GetVideoProgressBySessionQuery;
	export type GetVideoProgressBySession = (NonNullable<GetVideoProgressBySessionQuery['getVideoProgressBySession']>);
	export type Groups = NonNullable<(NonNullable<(NonNullable<GetVideoProgressBySessionQuery['getVideoProgressBySession']>)['groups']>)[number]>;
	export type Videos = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<GetVideoProgressBySessionQuery['getVideoProgressBySession']>)['groups']>)[number]>['videos']>)[number]>;
	export const Document = GetVideoProgressBySessionDocument;
	export const use = useGetVideoProgressBySessionQuery;
}

export namespace ListCaseStudies {
	export type Variables = ListCaseStudiesQueryVariables;
	export type Query = ListCaseStudiesQuery;
	export type ListCaseStudies = NonNullable<(NonNullable<ListCaseStudiesQuery['listCaseStudies']>)[number]>;
	export const Document = ListCaseStudiesDocument;
	export const use = useListCaseStudiesQuery;
}

export namespace ListCertificates {
	export type Variables = ListCertificatesQueryVariables;
	export type Query = ListCertificatesQuery;
	export type ListCertificates = NonNullable<(NonNullable<ListCertificatesQuery['listCertificates']>)[number]>;
	export type Groups = NonNullable<(NonNullable<NonNullable<(NonNullable<ListCertificatesQuery['listCertificates']>)[number]>['groups']>)[number]>;
	export type Courses = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<ListCertificatesQuery['listCertificates']>)[number]>['groups']>)[number]>['courses']>)[number]>;
	export const Document = ListCertificatesDocument;
	export const use = useListCertificatesQuery;
}

export namespace ListCourseBookmarks {
	export type Variables = ListCourseBookmarksQueryVariables;
	export type Query = ListCourseBookmarksQuery;
	export type ListCourseBookmarks = NonNullable<(NonNullable<ListCourseBookmarksQuery['listCourseBookmarks']>)[number]>;
	export const Document = ListCourseBookmarksDocument;
	export const use = useListCourseBookmarksQuery;
}

export namespace ListIndustryReferences {
	export type Variables = ListIndustryReferencesQueryVariables;
	export type Query = ListIndustryReferencesQuery;
	export type ListIndustryReferences = NonNullable<(NonNullable<ListIndustryReferencesQuery['listIndustryReferences']>)[number]>;
	export const Document = ListIndustryReferencesDocument;
	export const use = useListIndustryReferencesQuery;
}

export namespace ListJobFunctionReferences {
	export type Variables = ListJobFunctionReferencesQueryVariables;
	export type Query = ListJobFunctionReferencesQuery;
	export type ListJobFunctionReferences = NonNullable<(NonNullable<ListJobFunctionReferencesQuery['listJobFunctionReferences']>)[number]>;
	export const Document = ListJobFunctionReferencesDocument;
	export const use = useListJobFunctionReferencesQuery;
}

export namespace ListMemberEvents {
	export type Variables = ListMemberEventsQueryVariables;
	export type Query = ListMemberEventsQuery;
	export type ListMemberEvents = NonNullable<(NonNullable<ListMemberEventsQuery['listMemberEvents']>)[number]>;
	export type EventType = (NonNullable<NonNullable<(NonNullable<ListMemberEventsQuery['listMemberEvents']>)[number]>['eventType']>);
	export type EventCategory = (NonNullable<NonNullable<(NonNullable<ListMemberEventsQuery['listMemberEvents']>)[number]>['eventCategory']>);
	export const Document = ListMemberEventsDocument;
	export const use = useListMemberEventsQuery;
}

export namespace ListMemberEventsRsvp {
	export type Variables = ListMemberEventsRsvpQueryVariables;
	export type Query = ListMemberEventsRsvpQuery;
	export type ListMemberEventsRsvp = NonNullable<(NonNullable<ListMemberEventsRsvpQuery['listMemberEventsRsvp']>)[number]>;
	export const Document = ListMemberEventsRsvpDocument;
	export const use = useListMemberEventsRsvpQuery;
}

export namespace ListMembers {
	export type Variables = ListMembersQueryVariables;
	export type Query = ListMembersQuery;
	export type ListMembers = (NonNullable<ListMembersQuery['listMembers']>);
	export type List = NonNullable<(NonNullable<(NonNullable<ListMembersQuery['listMembers']>)['list']>)[number]>;
	export type Company = (NonNullable<NonNullable<(NonNullable<(NonNullable<ListMembersQuery['listMembers']>)['list']>)[number]>['company']>);
	export type PageInfo = (NonNullable<(NonNullable<ListMembersQuery['listMembers']>)['pageInfo']>);
	export const Document = ListMembersDocument;
	export const use = useListMembersQuery;
}

export namespace ListPastSessions {
	export type Variables = ListPastSessionsQueryVariables;
	export type Query = ListPastSessionsQuery;
	export type ListPastSessions = NonNullable<(NonNullable<ListPastSessionsQuery['listPastSessions']>)[number]>;
	export type Faculties = NonNullable<(NonNullable<NonNullable<(NonNullable<ListPastSessionsQuery['listPastSessions']>)[number]>['faculties']>)[number]>;
	export const Document = ListPastSessionsDocument;
	export const use = useListPastSessionsQuery;
}

export namespace ListResources {
	export type Variables = ListResourcesQueryVariables;
	export type Query = ListResourcesQuery;
	export type ListResources = (NonNullable<ListResourcesQuery['listResources']>);
	export type List = NonNullable<(NonNullable<(NonNullable<ListResourcesQuery['listResources']>)['list']>)[number]>;
	export type PageInfo = (NonNullable<(NonNullable<ListResourcesQuery['listResources']>)['pageInfo']>);
	export const Document = ListResourcesDocument;
	export const use = useListResourcesQuery;
}

export namespace ListUserCourseData {
	export type Variables = ListUserCourseDataQueryVariables;
	export type Query = ListUserCourseDataQuery;
	export type ListUserSessions = NonNullable<(NonNullable<ListUserCourseDataQuery['listUserSessions']>)[number]>;
	export type ListCourseBookmarks = NonNullable<(NonNullable<ListUserCourseDataQuery['listCourseBookmarks']>)[number]>;
	export const Document = ListUserCourseDataDocument;
	export const use = useListUserCourseDataQuery;
}

export namespace ListUserSessions {
	export type Variables = ListUserSessionsQueryVariables;
	export type Query = ListUserSessionsQuery;
	export type ListUserSessions = NonNullable<(NonNullable<ListUserSessionsQuery['listUserSessions']>)[number]>;
	export const Document = ListUserSessionsDocument;
	export const use = useListUserSessionsQuery;
}

export namespace ListUserSessionsByCourse {
	export type Variables = ListUserSessionsByCourseQueryVariables;
	export type Query = ListUserSessionsByCourseQuery;
	export type ListUserSessionsByCourse = NonNullable<(NonNullable<ListUserSessionsByCourseQuery['listUserSessionsByCourse']>)[number]>;
	export const Document = ListUserSessionsByCourseDocument;
	export const use = useListUserSessionsByCourseQuery;
}

export namespace SearchCourses {
	export type Variables = SearchCoursesQueryVariables;
	export type Query = SearchCoursesQuery;
	export type SearchCourses = NonNullable<(NonNullable<SearchCoursesQuery['searchCourses']>)[number]>;
	export const Document = SearchCoursesDocument;
	export const use = useSearchCoursesQuery;
}
