import React, { useEffect, useState } from 'react';
import { ChatPane } from '../../Common/ChatPane';
import {
	type ChatMessage,
	ChatSource,
	type ProfileElementType,
	type UserProfile,
} from '../../../types';
import { ProfileQATemplate } from '../../../store/profileQATemplate';
import { FormatString } from '../../../utils/strings';
import { EventBus } from '../../../utils/eventBus';
import { EVT_PROFILE_ELEMENT_CLICKED } from '../../../types/Events';

export interface UpdateProfileProps {
	profile: UserProfile;
	setProfile: (me: UserProfile, completed: boolean) => void;
	editMode: boolean;
	fullScreenMode?: boolean;
}

export const UpdateProfile: React.FC<UpdateProfileProps> = (props: UpdateProfileProps) => {
	const [profileEditStep, setProfileEditStep] = useState<number>(-1);
	const [nextQuestion, setNextQuestion] = useState<ChatMessage | undefined>(undefined);
	const [nextQuestionType, setNextQuestionType] = useState<ProfileElementType | undefined>(
		undefined,
	);
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const fullScreenMode = props.fullScreenMode ?? true;

	const determineNextStep = (forceElementType?: string): void => {
		if (
			(props.editMode && forceElementType === 'nickname') ||
			props.profile.nickname === undefined ||
			props.profile.nickname?.trim().length === 0
		) {
			setProfileEditStep(0);
			return;
		}
		if (
			(props.editMode && forceElementType === 'title') ||
			props.profile.title === undefined ||
			props.profile.title?.trim().length === 0
		) {
			setProfileEditStep(1);
			return;
		}
		if (
			(props.editMode && forceElementType === 'employmentStatus') ||
			props.profile.employmentStatus === undefined ||
			props.profile.employmentStatus?.trim().length === 0
		) {
			setProfileEditStep(2);
			return;
		}
		if (
			(props.editMode && forceElementType === 'communicationStyle') ||
			props.profile.communicationStyle === undefined ||
			props.profile.communicationStyle?.trim().length === 0
		) {
			setProfileEditStep(3);
			return;
		}
		if (!props.editMode) {
			setProfileEditStep(4);
		}
	};

	const selectNextQuestions = (): void => {
		let nextQuestion;
		let nextQuestionType;
		if (profileEditStep >= 0 && profileEditStep < 4) {
			nextQuestionType = ProfileQATemplate[profileEditStep].type;
			nextQuestion = ProfileQATemplate[profileEditStep];
		}
		if (nextQuestion !== undefined) {
			if (!fullScreenMode) {
				setChatHistory([...chatHistory, nextQuestion]);
			} else {
				setChatHistory([nextQuestion]);
			}
		}
		setNextQuestion(nextQuestion);
		setNextQuestionType(nextQuestionType);
	};
	const processUserInput = (input: string, type: string): void => {
		const profile = { ...props.profile };
		switch (type) {
			case 'nickname':
				profile.nickname = input;
				break;
			case 'title':
				profile.title = input;
				break;
			case 'employmentStatus':
				profile.employmentStatus = input;
				break;
			default:
				profile.communicationStyle = input;
		}

		setChatHistory([
			...chatHistory,
			{
				messageId: `${(profileEditStep + 1) * 2 + 1}`,
				messageSource: ChatSource.User,
				message: input,
			},
		]);
		props.setProfile(profile, profileEditStep === 4);
	};

	useEffect(() => {
		ProfileQATemplate[0].message = FormatString(
			ProfileQATemplate[0].message ?? '',
			props.profile.name ?? '',
		);
		if (!props.editMode) {
			determineNextStep();
		}

		if (props.editMode) {
			const result = EventBus.getInstance().register(
				EVT_PROFILE_ELEMENT_CLICKED,
				(elementType: ProfileElementType) => {
					setProfileEditStep(-1);
					determineNextStep(elementType);
				},
			);
			return () => {
				result.unregister();
			};
		}
	}, []);

	useEffect(() => {
		if (!props.editMode && profileEditStep === 4) {
			props.setProfile(props.profile, true);
		} else {
			selectNextQuestions();
		}
	}, [profileEditStep]);

	useEffect(() => {
		if (!props.editMode) {
			determineNextStep();
		}
	}, [props.profile]);

	return (
		<ChatPane
			panePerInteraction={fullScreenMode}
			messages={chatHistory}
			disableInput={nextQuestion === undefined}
			onChatUserInput={(input) => {
				if (nextQuestionType !== undefined) {
					processUserInput(input, nextQuestionType);
				}
			}}
		/>
	);
};
