import React from 'react';
import { type IconProps } from './types';

export const ProfileLogo: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<g id="AccountCircleOutlined">
				<path
					id="Vector"
					d="M15.9998 2.66669C8.63984 2.66669 2.6665 8.64002 2.6665 16C2.6665 23.36 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.36 29.3332 16C29.3332 8.64002 23.3598 2.66669 15.9998 2.66669ZM9.4265 24.3734C9.99984 23.1734 13.4932 22 15.9998 22C18.5065 22 22.0132 23.1734 22.5732 24.3734C20.7598 25.8134 18.4798 26.6667 15.9998 26.6667C13.5198 26.6667 11.2398 25.8134 9.4265 24.3734ZM24.4798 22.44C22.5732 20.12 17.9465 19.3334 15.9998 19.3334C14.0532 19.3334 9.4265 20.12 7.51984 22.44C6.15984 20.6534 5.33317 18.4267 5.33317 16C5.33317 10.12 10.1198 5.33335 15.9998 5.33335C21.8798 5.33335 26.6665 10.12 26.6665 16C26.6665 18.4267 25.8398 20.6534 24.4798 22.44ZM15.9998 8.00002C13.4132 8.00002 11.3332 10.08 11.3332 12.6667C11.3332 15.2534 13.4132 17.3334 15.9998 17.3334C18.5865 17.3334 20.6665 15.2534 20.6665 12.6667C20.6665 10.08 18.5865 8.00002 15.9998 8.00002ZM15.9998 14.6667C14.8932 14.6667 13.9998 13.7734 13.9998 12.6667C13.9998 11.56 14.8932 10.6667 15.9998 10.6667C17.1065 10.6667 17.9998 11.56 17.9998 12.6667C17.9998 13.7734 17.1065 14.6667 15.9998 14.6667Z"
					fill={props.fillColor ?? 'white'}
				/>
			</g>
		</svg>
	);
};
