import React from 'react';
import { type IconProps } from './types';

export const RedoIcon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<mask
				id="mask0_190_4826"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill={props.fillColor ?? '#0A070D'} />
			</mask>
			<g mask="url(#mask0_190_4826)">
				<path
					d="M7.76772 15.5833C6.78483 15.5833 5.95258 15.2238 5.27095 14.5048C4.58934 13.7858 4.24854 12.9268 4.24854 11.9279C4.24854 10.929 4.58934 10.0713 5.27095 9.35498C5.95258 8.63865 6.78483 8.28048 7.76772 8.28048H13.4937L11.404 6.19071L12.178 5.41669L15.5834 8.82212L12.178 12.2275L11.404 11.4535L13.4937 9.36377H7.76772C7.07863 9.36377 6.50037 9.61484 6.03295 10.117C5.56554 10.6191 5.33183 11.2228 5.33183 11.9279C5.33183 12.633 5.56554 13.238 6.03295 13.7428C6.50037 14.2476 7.07863 14.5 7.76772 14.5H13.8318V15.5833H7.76772Z"
					fill={props.fillColor ?? '#0A070D'}
				/>
			</g>
		</svg>
	);
};
