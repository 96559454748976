import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	defaultDataIdFromObject,
	from,
	InMemoryCache,
	Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { type ErrorResponse, onError } from '@apollo/client/link/error';
import { parseCookie, parseTokenCookie } from '../../utils/cookieUtils';
import { API_URL, NEST_API, REFRESH_TOKEN_COOKIE_NAME } from '../../config/config';
import { UnauthorizedMessageEnum } from './types';
import logger from '../../utils/logger';
import { getAuthTokenFromRefreshToken, handleLogout, setTokenCookie } from '../../utils/authUtils';

export const NODE_API_NAME = 'nest-js';

export const NODE_API_CONTEXT = {
	// this tells the apollo client to utilize the nest endpoint and auth header setup in apolloClient.ts
	clientName: NODE_API_NAME,
};

const httpLink: ApolloLink = createHttpLink({
	uri: API_URL,
});

const nestLink: ApolloLink = createHttpLink({
	uri: NEST_API,
});

const authLink: ApolloLink = setContext((_, { headers }: { headers: Headers }) => {
	// get the authentication token from local storage if it exists
	const token = parseTokenCookie();

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: `${token}`,
		},
	};
});

const nestAuthLink: ApolloLink = setContext((_, { headers }: { headers: Headers }) => {
	// get the authentication token from local storage if it exists
	const token = parseTokenCookie();

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token !== '' ? `Bearer ${token}` : '',
		},
	};
});

// eslint-disable-next-line consistent-return
const errorLink: ApolloLink = onError((args: ErrorResponse) => {
	const { graphQLErrors, networkError, operation, forward } = args;

	// todo: move this to a `handleGraphQLErrors` function. Good luck with Typescript
	if (graphQLErrors != null) {
		const refreshToken = parseCookie(REFRESH_TOKEN_COOKIE_NAME);
		const hasExpiredToken = graphQLErrors.some(
			({ message }) => message === UnauthorizedMessageEnum.EXPIRED_TOKEN,
		);
		const isUnauthorized = graphQLErrors.some(
			({ message }) => message === UnauthorizedMessageEnum.UNAUTHORIZED,
		);

		graphQLErrors.forEach(({ message, ...rest }) =>
			{ logger.error('GraphQL error:', `Message: ${message}`, 'data: ', rest); },
		);

		if (isUnauthorized) {
			handleLogout();
		}

		// If our JWT expired, refresh it and re-attempt the failed request
		if (hasExpiredToken) {
			if (refreshToken === '') {
				handleLogout();
			} else {
				return new Observable((observer) => {
					const logErrorAndLogout = (error: Error): void => {
						observer.error(error);
						logger.error('Refresh token error:', error);
						// No refresh token available, we force user to login
						handleLogout();
					};

					getAuthTokenFromRefreshToken()
						.then((res) => {
							const { data } = res;
							setTokenCookie(data.id_token ?? '');
							operation.setContext(({ headers }: { headers: Headers }) => ({
								headers: {
									// Re-add old headers
									...headers,
									// Switch out old access token for new one
									authorization: data.id_token,
								},
							}));
						})
						.then(() => {
							const subscriber = {
								next: observer.next.bind(observer),
								error: logErrorAndLogout,
								complete: observer.complete.bind(observer),
							};

							// Retry last failed request
							return forward(operation).subscribe(subscriber);
						})
						.catch(logErrorAndLogout);
				});
			}
		}
	}

	if (networkError !== undefined) {
		if ('statusCode' in networkError && networkError.statusCode === 401) {
			// no valid auth tokens so send to login.
			handleLogout();
		} else {
			logger.error('Network error:', networkError);
		}
	}
});

const client = new ApolloClient({
	link: from([
		errorLink,
		ApolloLink.split(
			(operation) => operation.getContext().clientName === NODE_API_NAME,
			nestAuthLink,
			authLink,
		),
		ApolloLink.split(
			(operation) => operation.getContext().clientName === NODE_API_NAME,
			nestLink,
			httpLink,
		),
	]),
	cache: new InMemoryCache({ 
		dataIdFromObject(responseObject): string | undefined {
			const typename = responseObject.__typename;
			switch (typename) {
				case 'EventRsvp':
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					return `${typename}:${responseObject.memberEventId}`;
				// `Member`
				//   - is returned from the AppSync/Lambda API
				//   - is defined in the membership-serverless repo
				// `PrivateMember` and `PublicMember`
				//   - is returned from the NestJS API
				//   - is defined in member.type.ts
				// ApolloClient also lets you refetch queries after mutations or update
				// the cache directly. Hopefully it doesn't come to that.
				// https://www.apollographql.com/docs/react/data/mutations/#updating-local-data
				case 'Member':
				case 'PrivateMember':
				case 'PublicMember':
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					return `Member:${responseObject.userId}`;
				case 'UserSave':
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					return `${typename}:${responseObject.contentId}`;
				case 'UserSession':
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					return `${typename}:${responseObject.sessionId}`;
				default:
					return defaultDataIdFromObject(responseObject);
			}
		},
	}),
});

export default client;
