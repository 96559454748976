import { type OperationVariables } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
	type GetMemberQueryHookResult,
	type PrivateMember,
	useGetMemberQuery,
} from '../connector/section/graphql';
import { parseCookie } from '../utils/cookieUtils';
import { USER_ID_COOKIE_NAME } from '../config/config';
import { handleLogout } from '../utils/authUtils';

export const useMemberData = (options?: OperationVariables): GetMemberQueryHookResult => {
	const userId = parseCookie(USER_ID_COOKIE_NAME);

	return useGetMemberQuery({
		...(options ?? {}),
		variables: { userId: userId ?? '' },
	});
};

// This hook is used to get the member data for the current user and send it
// to critical third-parties such as LaunchDarkly.
const useMembershipAuthHandler = (
	skipQueries?: boolean,
): ReturnType<typeof useMemberData> => {
	// Authorization handled within apolloClient.ts.
	const [userId, setUserId] = useState(parseCookie(USER_ID_COOKIE_NAME));
	const [member, setMember] = useState<PrivateMember>();

	const memberResult = useGetMemberQuery({
		skip: skipQueries,
		onCompleted: ({ getMember }) => {
			setMember(getMember);

			if (userId !== '') {
				setUserId(getMember?.userId);
			}
		},
		onError: (error) => {
			if (error !== undefined && userId !== '') {
				handleLogout();
			}
		},
	});

	useEffect(() => {
		if (member !== null) {
			// const {
			// 	firstName, lastName, email, customer,
			// 	scholarship, accountType, meetPreference = '',
			// 	status, registrationDate, userType,
			// } = member
		}
	}, [member, userId]);

	return {
		...memberResult,
		loading: memberResult.loading, // some logic is based on loading here and this little lie lets AuthedContentContainer work as intended
	};
};

export default useMembershipAuthHandler;
