import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { type AppStateSlice, createAppStateSlice } from './slices/appStateSlice';
import { createUserSlice, type UserSlice } from './slices/userSlice';
import { createSessionSlice, type SessionSlice } from './slices/sessionSlice';
import { createUISettingsSlice, type UISettingsSlice } from './slices/uiSettingsSlice';
import { type CoursesSlice, createCoursesSlice } from './slices/coursesSlice';

export enum UserOnboardingState {
	RESOLVING,
	FTU,
	ONBOARDING,
	FTU_COMPLETE,
	COMPLETE,
	ONBOARDED,
}

type StoreState = AppStateSlice & UserSlice & SessionSlice & UISettingsSlice & CoursesSlice;
export const useStore = create<StoreState>()(
	persist(
		(...ops) => ({
			...createAppStateSlice(...ops),
			...createUserSlice(...ops),
			...createSessionSlice(...ops),
			...createUISettingsSlice(...ops),
			...createCoursesSlice(...ops),
		}),
		{
			name: 'profAi-Store', // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
			partialize: (state) =>
				Object.fromEntries(Object.entries(state).filter(([key]) => !['courseList', 'selectedCourse', 'historySessions', 'selectedCourse', 'currentSession'].includes(key))),
		},
	),
);
