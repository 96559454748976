import React from 'react';
import styles from './index.module.css';

import { useTranslation } from 'react-i18next';
import { WrongIcon } from '../../../Common/Icons/WrongIcon';
import { CorrectIcon } from '../../../Common/Icons/CorrectIcon';

export interface QuizPaneEntryProps {
	question: string;
	answer?: string;
	correct?: boolean;
}

export const QuizPaneEntry: React.FC<QuizPaneEntryProps> = (props: QuizPaneEntryProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.quizEntryContainer}>
			<div className={styles.title}>
				<b className={styles.question}>
					{t('Q')}: {props.question}
				</b>

				{props.correct !== undefined && (
					<>
						{props.correct ? (
							<CorrectIcon
								className={styles.icon}
								fillColor={'var(--quiz-history-entry-correct)'}
							/>
						) : (
							<WrongIcon
								className={styles.icon}
								fillColor={'var(--quiz-history-entry-wrong)'}
							/>
						)}
					</>
				)}
			</div>

			<div className={`${styles.answer} ${props.correct === false ? styles.wrong : ''}`}>
				{t('A')}: {props.answer}
			</div>
		</div>
	);
};

          					