import React from 'react';
import { type IconProps } from './types';

export const MoonLogo: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="32"
			height="20"
			viewBox="0 0 32 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<mask
				id="mask0_254_2336"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="6"
				y="0"
				width="20"
				height="20"
			>
				<rect x="6" width="20" height="20" fill={props.fillColor ?? 'white'} />
			</mask>
			<g mask="url(#mask0_254_2336)">
				<path
					d="M12.3857 15.7608C14.1254 16.2269 15.7707 16.0119 17.3217 15.1157C18.8726 14.2195 19.8813 12.9009 20.3478 11.16C20.8143 9.41904 20.5996 7.7743 19.7037 6.22578C18.8078 4.67725 17.49 3.66991 15.7504 3.20377C15.5894 3.16063 15.4266 3.1242 15.262 3.09448C15.0974 3.06476 14.9311 3.04175 14.7629 3.02544C15.2979 4.08749 15.6349 5.20436 15.7739 6.37605C15.9129 7.54772 15.8275 8.71149 15.5178 9.86736C15.2081 11.0232 14.7008 12.0711 13.9961 13.011C13.2913 13.9509 12.4397 14.7544 11.4414 15.4215C11.5952 15.4915 11.7508 15.5547 11.9082 15.6113C12.0656 15.6678 12.2247 15.7177 12.3857 15.7608ZM12.0006 17.2105C11.3165 17.0272 10.6711 16.7644 10.0643 16.4221C9.45744 16.0797 8.90562 15.6551 8.40879 15.1481C9.78348 14.74 10.9776 14.0401 11.991 13.0483C13.0044 12.0565 13.6965 10.8688 14.0673 9.48511C14.4381 8.10142 14.4328 6.72582 14.0515 5.35832C13.6702 3.99082 12.9864 2.78668 11.9999 1.7459C12.6837 1.55526 13.3739 1.46341 14.0706 1.47036C14.7673 1.4773 15.4576 1.57242 16.1417 1.7557C17.2129 2.04273 18.1608 2.5124 18.9853 3.1647C19.8098 3.817 20.4799 4.58967 20.9955 5.48271C21.5111 6.37576 21.8448 7.3439 21.9967 8.38713C22.1485 9.43038 22.0819 10.4838 21.7969 11.5473C21.512 12.6108 21.0424 13.5582 20.3883 14.3895C19.7342 15.2207 18.9607 15.8942 18.0676 16.4098C17.1746 16.9253 16.2079 17.2595 15.1677 17.4121C14.1275 17.5647 13.0718 17.4975 12.0006 17.2105Z"
					fill={props.fillColor ?? 'white'}
				/>
			</g>
		</svg>
	);
};
