import React from 'react';
import styles from './index.module.css';
import { PacmanLoader } from 'react-spinners';

export const ProgressIndicator: React.FC = () => {
	return (
		<div className={styles.createProfileContainer}>
			<PacmanLoader color="#36d7b7" />
		</div>
	);
};
