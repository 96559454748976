import React from 'react';
import styles from './index.module.css';
import { type ProfileElementType, type UserProfile } from '../../../../types';

export interface SummaryViewProps {
	onProfileElementClicked?: (element: ProfileElementType) => void;
	profile: UserProfile;
}

export const SummaryView: React.FC<SummaryViewProps> = (props: SummaryViewProps) => {
	const showNickName = props.profile.nickname !== undefined && props.profile.nickname.trim().length !== 0;
	const showTitle = props.profile.title !== undefined && props.profile.title.trim().length !== 0;
	const showEmploymentStatus = props.profile.employmentStatus!== undefined && props.profile.employmentStatus?.trim().length !== 0;
	const showCommunicationStyle = props.profile.communicationStyle !== undefined && props.profile.communicationStyle?.trim().length !== 0;
	return (
		<div className={styles.profileSummaryContainer}>
			<div className={styles.bubbleTitle}>
				<b className={styles.titleLabel}>My Profile</b>
			</div>
			<div className={styles.summaryDescriptionContainer}>
				<span>{`My name is ${props.profile.name}.`}</span>
				{showNickName && (
					<>
						<span>{' But you can call me '}</span>
						<span
							className={styles.clickableSpan}
							onClick={() => props.onProfileElementClicked?.('nickname')}
						>
							{props.profile.nickname}.
						</span>
					</>
				)}
				{showTitle && (
					<>
						<span>{' I’m a '}</span>
						<span
							className={styles.clickableSpan}
							onClick={() => props.onProfileElementClicked?.('title')}
						>
							{props.profile.title}
						</span>
					</>
				)}
				{!showEmploymentStatus && showTitle && <span>{'.'}</span>}
				{showEmploymentStatus && (
					<>
						<span>{' that is '}</span>
						<span
							className={styles.clickableSpan}
							onClick={() => props.onProfileElementClicked?.('employmentStatus')}
						>
							{props.profile.employmentStatus}.
						</span>
					</>
				)}
				{showCommunicationStyle && (
					<>
						<span>{' My communication style can be described as '}</span>
						<span
							className={styles.clickableSpan}
							onClick={() => props.onProfileElementClicked?.('communicationStyle')}
						>
							{props.profile.communicationStyle}.
						</span>
					</>
				)}
			</div>
		</div>
	);
};
