import { type ChatSession, ChatSource, type CourseOfferred } from '../types';

export const mockHistorySession = (): Map<string, ChatSession> => {
	const mockSession = new Map<string, ChatSession>();
	mockSession.set('1', {
		sessionId: '1',
		date: 'THURS OCT 23',
		title: 'ASK PROF AI',
		sessionType: 'ask_profai',
		sessionTitle: 'Business Strategy with Scott Galloway',
		description:
			'We talked about how you might use the framework for your shoe recycling initiative.',
		messages: [],
	});
	mockSession.set('2', {
		sessionId: '2',
		date: 'THURS OCT 23',
		title: 'ASK PROF AI',
		sessionType: 'ask_profai',
		sessionTitle: 'Business Strategy with Scott Galloway',
		messages: [
			{
				messageId: '1',
				messageSource: ChatSource.Assistant,
				message: 'Hello!'
			},
			{
				messageId: '2',
				messageSource: ChatSource.User,
				message: 'Hi! how are you!'
			},
			
		],
	});
	return mockSession;
};

export const mockCourseList = (): CourseOfferred[] => {
	return [
		{
			id: '1',
			title: 'Proving Business Value',
			str_id: 'proving-business-value',
			assistantId: '123',
			description: 'some description here #1'
		},
		{
			id: '2',
			title: 'Problem Solving',
			str_id: 'problem-solving',
			assistantId: '124',
			description: 'some description here #2'
		},
	];
};
