import React from 'react';
import styles from './index.module.css';
import { UpArrowIcon } from '../Icons/UpArrowIcon';


export interface UpArrowButtonProps {
	enabled?: boolean;
	onClick?: () => void;
}

export const UpArrowButton: React.FC<UpArrowButtonProps> = (props: UpArrowButtonProps) => {
	const enabled = props.enabled ?? true;
	return (
		<div
			className={`${styles.arrowContainer} ${enabled ? '' : styles.disabled}`}
			onClick={() => {
				props.onClick?.();
			}}
		>
			<UpArrowIcon className={styles.icon} aria-disabled={!enabled} fillColor={'var(--fill-color-uparrow)' } />
		</div>
	);
};


