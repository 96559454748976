import styles from './index.module.css';
import React, { useEffect, useState } from 'react';
import type { IconProps } from '../../Icons/types';

export enum HeaderIconJustification {
	Left,
	Right,
}

export interface ListPaneHeaderProps {
	expanded?: boolean;
	toggleEnabled?: boolean;
	onToggle?: (expanded: boolean) => void;
	title?: string;
	headerIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | React.FC<IconProps>;
	lazyHeaderIcon?: () => React.ReactNode;
	headerIconJustification: HeaderIconJustification;
}

export const ListPaneHeader: React.FC<ListPaneHeaderProps> = (props: ListPaneHeaderProps) => {
	const expanded = props.expanded === true;
	const [lazyHeaderIcon, setLazyHeaderIcon] = useState<React.ReactNode | undefined>(undefined);
	useEffect(() => {
		if (props.lazyHeaderIcon !== undefined) {
			setLazyHeaderIcon(props.lazyHeaderIcon());
		}
	}, []);

	return (
		<div
			className={styles.header}
			onClick={() => {
				if (typeof props.onToggle !== 'undefined' && props.toggleEnabled === true) {
					props.onToggle(!expanded);
				}
			}}
		>
			{props.headerIconJustification === HeaderIconJustification.Left &&
				props.headerIcon !== undefined && (
					<props.headerIcon
						className={styles.headerIcon}
						onClick={() => {
							if (
								typeof props.onToggle !== 'undefined' &&
								props.toggleEnabled === true
							) {
								props.onToggle(!expanded);
							}
						}}
					/>
				)}
			{props.headerIconJustification === HeaderIconJustification.Left &&
				lazyHeaderIcon !== undefined && <>{lazyHeaderIcon}</>}
			{expanded && typeof props.title !== 'undefined' && (
				<div className={styles.headerLabelContainer}>
					<b className={styles.headerLabel}>{props.title}</b>
				</div>
			)}
			{props.headerIconJustification === HeaderIconJustification.Right &&
				lazyHeaderIcon !== undefined && <>{lazyHeaderIcon}</>}
			{props.headerIconJustification === HeaderIconJustification.Right &&
				props.headerIcon !== undefined && (
					<props.headerIcon
						className={styles.headerIcon}
						onClick={() => {
							if (
								typeof props.onToggle !== 'undefined' &&
								props.toggleEnabled === true
							) {
								props.onToggle(!expanded);
							}
						}}
					/>
				)}
		</div>
	);
};