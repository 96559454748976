import React, { type CSSProperties } from 'react';
import styles from './index.module.css';
import { type Theme } from '../Toggle';
import type { IconProps } from '../Icons/types';
import { type HeaderIconJustification, ListPaneHeader } from './Header';

export interface ListPaneProps {
	expanded?: boolean;
	toggleEnabled?: boolean;
	onToggle?: (expanded: boolean) => void;
	title?: string;
	headerIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | React.FC<IconProps>;
	lazyHeaderIcon?: () => React.ReactNode;
	headerIconJustification: HeaderIconJustification;
	children: React.ReactNode;
	className?: string;
	style?: CSSProperties;
	theme?: Theme;
}

export const ListPane: React.FC<ListPaneProps> = (props: ListPaneProps) => {
	const expanded = props.expanded === true;
	return (
		<div
			className={`${styles.listPaneContainer} ${props.className ?? ''}`}
			style={props.style ?? {}}
		>
			<ListPaneHeader
				lazyHeaderIcon={props.lazyHeaderIcon}
				headerIcon={props.headerIcon}
				headerIconJustification={props.headerIconJustification}
				expanded={props.expanded}
				toggleEnabled={props.toggleEnabled}
				onToggle={props.onToggle}
				title={props.title}
			/>
			{expanded && <>{props.children}</>}
		</div>
	);
};
