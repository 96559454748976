// Dependencies
import React from 'react';
import styles from './index.module.css';
import { MoonLogo } from '../Icons/MoonLogo';
import { SunLogo } from '../Icons/SunLogo';


// Other
export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
}

export enum ThemeToggleType {
	Text,
	Image
}

interface ToggleThemeProps {
	selectedTheme: Theme;
	onChange: (theme: Theme) => void;
	id?: string;
}

// Component
export const ToggleTheme: React.FC<ToggleThemeProps> = ({ selectedTheme, onChange, id }: ToggleThemeProps) => {
	return (
		<>
			<input
				type="checkbox"
				className={styles.toggleSwitch}
				id={`${id ?? 'toggle-theme-switch'}`}
				checked={selectedTheme === Theme.DARK}
				onChange={(e) => {
					const newTheme = e.target.checked
						? Theme.DARK
						: Theme.LIGHT;
					onChange(newTheme);
				}}
			/>

			<label htmlFor={`${id ?? 'toggle-theme-switch'}`}>
				<div className={styles.toggleContainer}>
					<MoonLogo className={styles.moon} fillColor={'var(--nav-header-toggle-icon-color)'}/>
					<div className={styles.circle} />
					<SunLogo className={styles.sun} fillColor={'var(--nav-header-toggle-icon-color)'}/>
				</div>
			</label>
		</>
	);
};
