import { type AlertButton, IonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';

export interface Button {
	title: string;
	type: 'normal' | 'cancel';
}

export interface AlertProps {
	buttons: Button[];
	title: string;
	description?: string;
	isOpen?: boolean;
	onClick?: (button: Button) => void;
	onDismissed?: () => void;
}

export const Alert: React.FC<AlertProps> = (props: AlertProps) => {
	const [alertButtons, setAlertButtons] = useState<AlertButton[]>([]);

	useEffect(() => {
		const alertButtons = props.buttons.map((button) => {
			return {
				text: button.title,
				cssClass: button.type == 'normal' ? 'alert-button-normal' : 'alert-button-cancel',
				role: button.title,
			} as AlertButton;
		});
		setAlertButtons(alertButtons);
	}, []);

	return (
		<IonAlert
			isOpen={props.isOpen}
			header={props.title}
			message={props.description ?? undefined}
			className="custom-alert"
			buttons={alertButtons}
			onDidDismiss={({ detail }) => {
				const result = props.buttons.find(
					(alertButton) => alertButton.title === detail.role,
				);
				if (result) {
					props.onClick?.(result);
				} else {
					props.onDismissed?.();
				}
			}}
		></IonAlert>
	);
};
