import { HistoryEntry } from './HistoryEntry';
import { ListPane } from '../Common/ListPane';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import { type ChatSession } from '../../types';
import { ButtonType, ButtonWithIcons } from '../Common/ButtonWithIcons';
import React from 'react';
import { HistoryIcon } from '../Common/Icons/HistoryIcon';
import { Theme } from '../Common/Toggle';
import { HeaderIconJustification } from '../Common/ListPane/Header';

export interface HistoryPaneProps {
	toggleEnabled?: boolean;
	paneExpanded: boolean;
	onPaneExpanded: (expanded: boolean) => void;
	historySessions: Map<string, ChatSession>;
	onSessionSelected: (chatSession: ChatSession) => void;
	onRequestNewSession: () => void;
	showNewSessionButton: boolean;
	theme: Theme;
}

export const HistoryPane: React.FC<HistoryPaneProps> = (props: HistoryPaneProps) => {
	const { t } = useTranslation();

	return (
		<ListPane
			title={t('recent_sessions')}
			expanded={props.paneExpanded}
			onToggle={(expanded) => {
				props.onPaneExpanded(expanded);
			}}
			headerIconJustification={HeaderIconJustification.Right}
			lazyHeaderIcon={() => <HistoryIcon className={styles.headerIcon}  fillColor={'var(--nav-header-icon-fill)'} onClick={() => {
				if (props.toggleEnabled === true) {
					props.onPaneExpanded(!props.paneExpanded);
				}
			}} />}
			toggleEnabled={props.toggleEnabled}
		>
			<div className={styles.content}>
				{[...props.historySessions.keys()].map((key) => {
					const chatSession = props.historySessions.get(key);
					return chatSession !== undefined ? (
						<HistoryEntry
							key={`he_${chatSession.sessionId}`}
							date={chatSession.date}
							sessionId={chatSession.sessionId}
							sessionType={chatSession.sessionType}
							onClick={(sessionId) => {
								props.onSessionSelected(chatSession);
							}}
							title={chatSession.title}
							sessionTitle={chatSession.sessionTitle}
							description={chatSession.description}
							quizProgress={chatSession.quizProgress}
						/>
					) : null;
				})}
			</div>
			<div className={styles.bottomButtonContainer}>
				{props.showNewSessionButton && (
					<ButtonWithIcons
						labelClassName={styles.headerLabel}
						style={{
							border: '1px solid var(--nav-header-toggle-border-color)',
							backgroundColor: 'transparent',
						}}
						type={props.theme === Theme.DARK ? ButtonType.action : ButtonType.tertiary}
						text={t('start_a_new_conversation')}
						onClick={props.onRequestNewSession}
					/>
				)}
			</div>
		</ListPane>
	);
};
