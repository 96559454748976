import React from 'react';
import { type IconProps } from './types';

export const SectionLogo: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="60"
			style={{ zoom: '1' }}
			width="30"
			fill="none"
			viewBox="0 0 30 60"
			className={props.className ?? ''}
		>
			<g id="Section-Logo" visibility="visible">
				<path
					id="Vector"
					d="M23.0288 26.1345C22.7506 26.1665 22.4743 26.0741 22.2566 25.895L9.99891 15.7211C9.1869 15.0478 7.98309 15.1572 7.30736 15.9643C6.63164 16.7715 6.74142 17.9727 7.55153 18.6459L19.8092 28.8198C20.8124 29.6552 22.0843 30.0437 23.3771 29.9287C24.6756 29.8118 25.851 29.1951 26.6838 28.1994C27.5185 27.1999 27.9141 25.9383 27.7949 24.6447C27.6775 23.351 27.0586 22.1799 26.0573 21.3502L13.7864 11.1612C10.4702 8.40795 5.52057 8.85489 2.75521 12.1607C-0.0101469 15.4665 0.440336 20.3979 3.75839 23.1511L22.4572 38.675C23.3203 39.3916 23.8503 40.3986 23.9525 41.5131C24.0547 42.6276 23.714 43.7139 22.9948 44.5738C22.2774 45.4337 21.2648 45.9617 20.1461 46.0636C19.0256 46.1578 17.9373 45.8241 17.0741 45.1093L4.0442 34.2924C3.2322 33.6211 2.02838 33.7286 1.35266 34.5357C0.676934 35.3447 0.786716 36.5459 1.59683 37.2192L14.6268 48.0361C16.0823 49.243 17.8786 49.888 19.7505 49.888C19.9966 49.888 20.2446 49.8766 20.4925 49.854C22.6276 49.6598 24.5582 48.6509 25.9305 47.0102C27.3028 45.3677 27.952 43.2933 27.757 41.1661C27.5621 39.039 26.5494 37.1136 24.9027 35.7464L6.20576 20.2262C4.50793 18.8157 4.27701 16.2906 5.69092 14.599C6.37611 13.7787 7.34143 13.2733 8.40897 13.1771C9.48217 13.0847 10.5175 13.4034 11.339 14.0861L23.6099 24.2751C23.8276 24.4561 23.9601 24.7088 23.9866 24.9898C24.0112 25.2708 23.926 25.5442 23.7462 25.7592C23.5645 25.976 23.309 26.1099 23.027 26.1345H23.0288Z"
					fill={props.fillColor ?? '#0A070D'}
					visibility="visible"
				></path>
				<path
					id="Vector_4"
					d="M77.331 28.0938C79.1254 28.0938 80.3216 29.3196 80.4882 31.3053H85.3394C84.9741 27.3659 81.9172 24.5183 77.3651 24.5183C72.2148 24.5183 68.8589 28.0278 68.8589 33.457C68.8589 38.8862 72.2148 42.3957 77.3651 42.3957C81.6219 42.3957 84.5709 39.9064 85.2353 36.0915C85.3091 35.6671 84.9532 35.2768 84.5217 35.2768H81.0844C80.7248 35.2768 80.4446 35.5521 80.386 35.9048C80.0793 37.7189 78.9493 38.8202 77.3329 38.8202C75.0729 38.8202 73.712 36.7685 73.712 33.457C73.712 30.1455 75.0748 28.0938 77.3329 28.0938H77.331Z"
					fill={props.fillColor ?? '#0A070D'}
					visibility="hidden"
				></path>
				<path
					id="Vector_5"
					d="M92.8938 20.6788H88.2413V25.0482H85.5176V28.4917H88.2413V41.1567C88.2413 41.549 88.5593 41.8658 88.953 41.8658H92.1802C92.5739 41.8658 92.8919 41.549 92.8919 41.1567V28.4917H96.1153V25.0482H92.8919V20.6788H92.8938Z"
					fill={props.fillColor ?? '#0A070D'}
					visibility="hidden"
				></path>
				<path
					id="Vector_6"
					d="M100.603 17.9954H99.5693C98.3766 17.9954 97.4097 18.9587 97.4097 20.1471V20.4148C97.4097 21.6032 98.3766 22.5665 99.5693 22.5665H100.603C101.796 22.5665 102.762 21.6032 102.762 20.4148V20.1471C102.762 18.9587 101.796 17.9954 100.603 17.9954Z"
					fill={props.fillColor ?? '#0A070D'}
					visibility="hidden"
				></path>
				<path
					d="M112.658,24.5183 C107.375,24.5183 104.02,27.9938 104.02,33.457 C104.02,38.9201 107.375,42.3957 112.658,42.3957 C117.941,42.3957 121.297,38.9201 121.297,33.457 C121.297,27.9938 117.941,24.5183 112.658,24.5183 M112.658,38.8202 C110.366,38.8202 108.903,36.7345 108.903,33.457 C108.903,30.1795 110.364,28.0938 112.658,28.0938 C114.952,28.0938 116.413,30.1795 116.413,33.457 C116.413,36.7345 114.952,38.8202 112.658,38.8202 "
					id="Vector_8"
					fill={props.fillColor ?? '#0A070D'}
					visibility="hidden"
				></path>
				<path
					d="M132.33,24.5768 C130.17,24.5768 128.476,25.6762 127.378,27.166 H127.312 V25.0482 H122.958 V41.1567 C122.958,41.549 123.276,41.8658 123.67,41.8658 H126.897 C127.291,41.8658 127.609,41.549 127.609,41.1567 V32.8611 C127.609,29.8155 129.004,28.2937 130.931,28.2937 C132.759,28.2937 133.887,29.4516 133.887,32.0012 V41.1567 C133.887,41.549 134.205,41.8658 134.599,41.8658 H137.826 C138.22,41.8658 138.538,41.549 138.538,41.1567 V31.5373 C138.538,26.968 136.113,24.5787 132.324,24.5787 L132.33,24.5768 "
					id="Vector_9"
					fill={props.fillColor ?? '#0A070D'}
					visibility="hidden"
				></path>
			</g>
		</svg>
	);
};
