import { UserOnboardingState } from '../profAiStore';
import type { StateCreator } from 'zustand';
import type { CourseOfferred, ChatSession } from '../../types';

export interface AppStateSlice {
	onboardingState: UserOnboardingState;
	setOnboardingState: (onboardingState: UserOnboardingState) => void;
	selectedCourse: CourseOfferred | undefined;
	setSelectedCourse: (selectedCourse: CourseOfferred | undefined) => void;
	currentSession: ChatSession | undefined;
	setCurrentSession: (currentSession: ChatSession | undefined) => void;
}

export const createAppStateSlice: StateCreator<AppStateSlice, [], [], AppStateSlice> = (
	set,
	get,
) => ({
	onboardingState: UserOnboardingState.RESOLVING,

	setOnboardingState: (onboardingState: UserOnboardingState) => {
		set({ onboardingState });
	},
	selectedCourse: undefined,
	currentSession: undefined,
	setCurrentSession: (currentSession: ChatSession | undefined): void => {
		set({ currentSession });
	},
	setSelectedCourse: (selectedCourse: CourseOfferred | undefined) => {
		set({ selectedCourse });
	},
});