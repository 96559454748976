import React from 'react';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../../Common/ContentHeader';
import styles from './index.module.css';

export interface ProfileSetupIntroProps {
	onClick?: () => void;
	onCanceled?:()=>void;
	ftuForComplete: boolean;
}

export const ProfileSetupIntro: React.FC<ProfileSetupIntroProps> = (
	props: ProfileSetupIntroProps,
) => {
	const { t } = useTranslation();
	return (
		<div className={styles.createProfileContainer}>
			<ContentHeader title={t('setup_profile_title')} message={t('setup_profile_message')}>
				<div style={{ maxWidth: '850px', width: '100%', display:'flex', 'gap': '8px'}}>
					{!props.ftuForComplete && (
						<ButtonWithIcons
							style={{ width: '116px' }}
							type={ButtonType.primary}
							text={t('lets_do_it')}
							onClick={() => {
								props.onClick?.();
							}}
						/>
					)}
					{props.ftuForComplete && (
						<>
							<ButtonWithIcons
								style={{ width: '148px' }}
								type={ButtonType.primary}
								text={t('lets_finish_it')}
								onClick={() => {
									props.onClick?.();
								}}
							/>
							<ButtonWithIcons
								style={{ width: '160px', textAlign:'center' }}
								type={ButtonType.tertiary}
								text={t('i_ll_do_it_later')}
								onClick={() => {
									props.onCanceled?.();
								}}
							/>
						</>
					)}
				</div>
			</ContentHeader>
		</div>
	);
};
