import axios, { type AxiosResponse } from 'axios';
import { Auth, Amplify } from 'aws-amplify';

import { deleteCookie, parseCookie, setCookie } from './cookieUtils';
import {
	APP_HOST,
	AUDIENCE_COOKIE_NAME,
	AUTH_REDIRECT_URI,
	AUTH_TOKEN_URL,
	COGNITO_CLIENT_ID,
	COGNITO_SSO_CLIENT_ID,
	COGNITO_SSO_URL,
	COGNITO_SSO_USER_POOL_ID,
	DEFAULT_LOGIN_DAYS,
	REFRESH_TOKEN_COOKIE_NAME,
	SSO_AUTH_TOKEN_URL,
	TOKEN_COOKIE_NAME,
	USER_ID_COOKIE_NAME,
} from '../config/config';

export type CognitoRefreshResponse = AxiosResponse<{
	// eslint-disable-next-line camelcase
	id_token?: string;
}>;

export const OAUTH_ROUTE = '/oauth';

export const deleteAuthCookies = (): void => {
	deleteCookie(TOKEN_COOKIE_NAME);
	deleteCookie(REFRESH_TOKEN_COOKIE_NAME);
	deleteCookie(USER_ID_COOKIE_NAME);
	deleteCookie(AUDIENCE_COOKIE_NAME);
	// S4-549: delete old cookies format on logout to prevent users from getting into login/logout loop.
	deleteCookie(TOKEN_COOKIE_NAME, '');
	deleteCookie(REFRESH_TOKEN_COOKIE_NAME, '');
	deleteCookie(USER_ID_COOKIE_NAME, '');
};

export const handleLogout = (): void => {
	Auth.signOut({ global: true });
	deleteAuthCookies();
};

interface ParamsForRefreshToken {
	clientId: string;

	authTokenUrl: string;
}

export const isSSOLogin = (): boolean =>
	parseCookie(AUDIENCE_COOKIE_NAME) === COGNITO_SSO_CLIENT_ID;

// the audience property of the JWT is the Cognito App client id
// the auth token url is different for SSO and non-SSO
// assumption here is that there are only two Cognito App clients
const getParamsForRefreshToken = (): ParamsForRefreshToken => {
	const clientId = parseCookie(AUDIENCE_COOKIE_NAME) ?? COGNITO_CLIENT_ID;

	const authTokenUrl = clientId === COGNITO_CLIENT_ID ? AUTH_TOKEN_URL : SSO_AUTH_TOKEN_URL;

	return {
		clientId,
		authTokenUrl,
	};
};

export const getAuthTokenFromRefreshToken = async (): Promise<CognitoRefreshResponse> => {
	const { clientId, authTokenUrl } = getParamsForRefreshToken();

	return await axios.post<URLSearchParams, CognitoRefreshResponse>(
		authTokenUrl,
		new URLSearchParams({
			grant_type: 'refresh_token',
			redirect_uri: AUTH_REDIRECT_URI,
			client_id: clientId,
			refresh_token: parseCookie(REFRESH_TOKEN_COOKIE_NAME),
		}),
	);
};

export const setTokenCookie = (val: string): void => {
	setCookie(TOKEN_COOKIE_NAME, val, DEFAULT_LOGIN_DAYS);
};

export const setRefreshTokenCookie = (val: string): void => {
	setCookie(REFRESH_TOKEN_COOKIE_NAME, val, DEFAULT_LOGIN_DAYS);
};

export const configureAmplify = (): void => {
	Amplify.configure({
		aws_project_region: 'us-east-2',
		Auth: {
			userPoolId: COGNITO_SSO_USER_POOL_ID,
			userPoolWebClientId: COGNITO_SSO_CLIENT_ID,
			oauth: {
				domain: new URL(`${COGNITO_SSO_URL}`).hostname,
				clientId: COGNITO_SSO_CLIENT_ID,
				scope: ['email', 'openid', 'profile'],
				redirectSignIn: `${APP_HOST}${OAUTH_ROUTE}`,
				redirectSignOut: `${APP_HOST}`,
				responseType: 'code', // necessary to get both access and refresh tokens
			},
		},
	});
};