import React from 'react';
import { type IconProps } from './types';

export const ProfLogo: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="72"
			height="20"
			viewBox="0 0 72 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<path
				d="M8.37914 0.874195C9.87246 0.874195 11.1445 1.11386 12.1954 1.5932C13.2462 2.07253 14.0482 2.76388 14.6013 3.66724C15.1544 4.5706 15.4309 5.66754 15.4309 6.95806C15.4309 8.24858 15.1544 9.34552 14.6013 10.2489C14.0482 11.1522 13.2462 11.8436 12.1954 12.3229C11.1445 12.8023 9.87246 13.0419 8.37914 13.0419H5.82009C5.23267 13.0419 4.75648 13.5181 4.75648 14.1055V18.0622C4.75648 18.6496 4.28028 19.1258 3.69286 19.1258H1.06361C0.476195 19.1258 0 18.6496 0 18.0622V1.93781C0 1.35039 0.476196 0.874195 1.06361 0.874195H8.37914ZM7.63249 9.47457C8.62803 9.47457 9.37469 9.27177 9.87246 8.86618C10.3887 8.44216 10.6468 7.80612 10.6468 6.95806C10.6468 6.11001 10.3887 5.48318 9.87246 5.07759C9.37469 4.65357 8.62803 4.44155 7.63249 4.44155H5.82009C5.23267 4.44155 4.75648 4.91775 4.75648 5.50517V8.41096C4.75648 8.99837 5.23267 9.47457 5.82009 9.47457H7.63249Z"
				fill={props.fillColor ?? 'white'}
			/>
			<path
				d="M27.183 0.874195C29.3584 0.874195 31.0453 1.3904 32.2436 2.42282C33.4604 3.43679 34.0688 4.79184 34.0688 6.48794C34.0688 8.34998 33.4696 9.76955 32.2713 10.7467C31.12 11.6854 29.5178 12.1732 27.4647 12.21C27.2983 12.213 27.1344 12.2552 26.9894 12.3368C26.8443 12.4183 26.6807 12.4612 26.5143 12.4612H24.2091C23.6217 12.4612 23.1455 12.9374 23.1455 13.5248V18.0622C23.1455 18.6496 22.6693 19.1258 22.0819 19.1258H19.5356C18.9482 19.1258 18.472 18.6496 18.472 18.0622V1.93781C18.472 1.35039 18.9482 0.874195 19.5356 0.874195H27.183ZM26.5193 9.08741C27.4411 9.08741 28.1324 8.90306 28.5933 8.53434C29.0542 8.16562 29.2847 7.57567 29.2847 6.76448C29.2847 5.97174 29.0542 5.39101 28.5933 5.02228C28.1324 4.65357 27.4411 4.46921 26.5193 4.46921H24.2091C23.6217 4.46921 23.1455 4.9454 23.1455 5.53282V8.0238C23.1455 8.61122 23.6217 9.08741 24.2091 9.08741H26.5193ZM28.5698 10.2736C29.0132 10.1598 29.4797 10.3424 29.728 10.7271L34.0889 17.4855C34.5456 18.1933 34.0375 19.1258 33.1952 19.1258H30.5032C30.1253 19.1258 29.7758 18.9253 29.585 18.5991L25.931 12.3504C25.5744 11.7406 25.9004 10.959 26.5846 10.7833L28.5698 10.2736Z"
				fill={props.fillColor ?? 'white'}
			/>
			<path
				d="M45.5346 0.597656C47.3782 0.597656 48.9637 0.975593 50.2911 1.73147C51.6369 2.46891 52.6693 3.53819 53.3883 4.93932C54.1073 6.34046 54.4668 8.02735 54.4668 10C54.4668 11.9726 54.1073 13.6595 53.3883 15.0607C52.6693 16.4618 51.6369 17.5403 50.2911 18.2962C48.9637 19.0336 47.3782 19.4023 45.5346 19.4023C43.691 19.4023 42.0963 19.0336 40.7505 18.2962C39.4046 17.5403 38.3722 16.4618 37.6532 15.0607C36.9342 13.6595 36.5747 11.9726 36.5747 10C36.5747 8.02735 36.9342 6.34046 37.6532 4.93932C38.3722 3.53819 39.4046 2.46891 40.7505 1.73147C42.0963 0.975593 43.691 0.597656 45.5346 0.597656ZM45.5346 4.33094C44.6497 4.33094 43.903 4.54295 43.2946 4.96698C42.6862 5.39101 42.2253 6.02705 41.9119 6.8751C41.5985 7.70472 41.4418 8.74635 41.4418 10C41.4418 11.2352 41.5985 12.2768 41.9119 13.1249C42.2253 13.9729 42.6862 14.609 43.2946 15.033C43.903 15.457 44.6497 15.6691 45.5346 15.6691C46.4195 15.6691 47.157 15.457 47.7469 15.033C48.3553 14.609 48.8162 13.9729 49.1296 13.1249C49.443 12.2768 49.5997 11.2352 49.5997 10C49.5997 8.74635 49.443 7.70472 49.1296 6.8751C48.8162 6.02705 48.3553 5.39101 47.7469 4.96698C47.157 4.54295 46.4195 4.33094 45.5346 4.33094Z"
				fill={props.fillColor ?? 'white'}
			/>
			<path
				d="M71.9249 3.48856C71.9249 4.07597 71.4487 4.55217 70.8613 4.55217H63.5585C62.9711 4.55217 62.4949 5.02837 62.4949 5.61578V7.36011C62.4949 7.94752 62.9711 8.42372 63.5585 8.42372H68.9531C69.5406 8.42372 70.0167 8.89992 70.0167 9.48733V11.0381C70.0167 11.6255 69.5406 12.1017 68.9531 12.1017H63.5585C62.9711 12.1017 62.4949 12.5779 62.4949 13.1653V18.0622C62.4949 18.6496 62.0187 19.1258 61.4313 19.1258H58.802C58.2146 19.1258 57.7384 18.6496 57.7384 18.0622V1.93781C57.7384 1.35039 58.2146 0.874195 58.802 0.874195H70.8613C71.4487 0.874195 71.9249 1.35039 71.9249 1.93781V3.48856Z"
				fill={props.fillColor ?? 'white'}
			/>
		</svg>
	);
};
