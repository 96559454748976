import React from 'react';
import { type IconProps } from './types';

export const CheckmarkIcon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<g id="icon-credentials-white" clipPath="url(#clip0_282_3602)">
				<path
					id="Vector"
					d="M32 13.5793L27.7867 9.45845L26.4533 3.61045L20.6667 3.25685L16 -0.319946L11.3467 3.27045L5.56 3.62405L4.22667 9.47205L0 13.5793L2.6 18.9513L1.93333 24.9217L7.25333 27.3017L10.44 32.3201L16 30.5929L21.56 32.3201L24.7467 27.3017L30.0667 24.9217L29.4 18.9513L32 13.5793ZM27.2 23.2353L22.9467 25.1393L20.4133 29.1241L16 27.7641L11.5867 29.1241L9.05333 25.1393L4.8 23.2353L5.33333 18.4617L3.25333 14.1641L6.62667 10.8593L7.69333 6.20805L12.3067 5.92245L16 3.08005L19.6933 5.92245L24.3067 6.20805L25.3733 10.8593L28.7467 14.1641L26.6667 18.4617L27.2 23.2353Z"
					fill={props.fillColor ?? 'white'}
				/>
				<path
					id="Vector_2"
					d="M19.7733 11.1855L21.6533 13.1167L14.12 20.8143L10.3467 16.9655L12.2267 15.0343L14.12 16.9655L19.7733 11.1855Z"
					fill={props.fillColor ?? 'white'}
				/>
			</g>
			<defs>
				<clipPath id="clip0_282_3602">
					<rect width="32" height="32" fill={props.fillColor ?? 'white'} />
				</clipPath>
			</defs>
		</svg>
	);
};
