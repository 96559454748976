import React, { useEffect, useState } from 'react';
import Label from '../../Common/LabelText/LabelText';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../../Common/ContentHeader';
import styles from './index.module.css';
import { FormatString } from '../../../utils/strings';
import { type ChatSession } from '../../../types';

export interface SessionCompletedPaneProps {
	currentSession: ChatSession;
	onIAmDone?: () => void;
	onLetsDoItAgain?:()=> void;
}

export const SessionCompletePane: React.FC<SessionCompletedPaneProps> = (props: SessionCompletedPaneProps) => {
	const { t } = useTranslation();
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const [buttonTitle, setButtonTitle] = useState('');
	
	useEffect(() => {
		if (props.currentSession.sessionType === 'ask_profai'){
		} else if (props.currentSession.sessionType === 'quiz'){
			const quizProgress = props.currentSession.quizProgress ?? {correct: 0, total:10};
			const percentage = Math.floor((quizProgress?.correct * 100)/quizProgress?.total);
			setTitle(FormatString(t('quiz_title'), props.currentSession.course?.title ?? ''))
			if (percentage <= 50){
				setMessage(FormatString(t('quiz_completed_message_not_so_nice'), `${quizProgress?.correct}`, `${quizProgress?.total}.`, `${percentage}`));
			} else if (percentage === 100){
				setMessage(FormatString(t('quiz_completed_message_perfect'), `${quizProgress?.correct}`, `${quizProgress?.total}.`, `${percentage}`));
			} else {
				setMessage(FormatString(t('quiz_completed_message_nice'), `${quizProgress?.correct}`, `${quizProgress?.total}.`, `${percentage}`));
			}
			
			setButtonTitle(t('i_am_done'));
		}
	}, []);
	return (
		<div className={styles.createProfileContainer}>
			<ContentHeader title={title} message={message} icon={'default'}>
				<Label style={{ maxWidth: '850px', width: '100%', gap:'12px' }}>
					<ButtonWithIcons
						type={ButtonType.primary}
						text={buttonTitle}
						onClick={() => {
							props.onIAmDone?.();
							
						}}
					/>
					<ButtonWithIcons
						type={ButtonType.tertiary}
						text={t('lets_go_again')}
						onClick={() => {
							props.onLetsDoItAgain?.();
						}}
					/>
				</Label>
			</ContentHeader>
		</div>
	);
};
