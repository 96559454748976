import React, { useEffect, useState } from 'react';
import { WelcomeBack } from './WelcomeBack';
import styles from './index.module.css';
import { DropDown, type DropdownOption } from '../../Common/Dropdown';
import Label from '../../Common/LabelText/LabelText';
import { useTranslation } from 'react-i18next';
import { type CourseOfferred } from '../../../types';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';

export interface CourseSelectionPaneProps {
	courseList: CourseOfferred[];
	onReviewTermsClicked?: () => void;
	onCourseSelected?: (course: CourseOfferred) => void;
}

export const CourseSelectionPane: React.FC<CourseSelectionPaneProps> = (
	props: CourseSelectionPaneProps,
) => {
	const { t } = useTranslation();
	const [autofillOptions, setAutofillOptions] = useState<DropdownOption[]>([]);
	const [selectedCourse, setSelectedCourse] = useState<DropdownOption | undefined>(undefined);
	useEffect(() => {
		const autofillOptions = props.courseList.map((course) => ({
			id: course.id,
			value: course.title,
		}));
		setAutofillOptions(autofillOptions);
	}, []);

	const startNewSession = (): void => {
		props.onCourseSelected?.(props.courseList[0]);
	};

	return (
		<div className={styles.courseSelectionContainer}>
			<WelcomeBack onClick={() => props.onReviewTermsClicked?.()} />
			<div className={styles.courseSelectionDropdownContainer}>
				<Label style={{ maxWidth: '850px', width: '100%' }}>
					{t('label_choose_course')}
				</Label>
				<DropDown
					style={{ maxWidth: '850px', width: '100%' }}
					options={autofillOptions}
					onValueSelected={(selectedOption) => {
						const selectedCourse = props.courseList.find(
							(courseEntry) => courseEntry.title === selectedOption.value,
						);
						if (selectedCourse !== undefined) {
							props.onCourseSelected?.(selectedCourse);
						}
						setSelectedCourse(selectedOption);
					}}
				/>
			</div>
			{selectedCourse !== undefined && (
				<Label style={{ maxWidth: '850px', width: '100%' }}>
					<ButtonWithIcons
						type={ButtonType.primary}
						text={t('Let\'s get started')}
						onClick={() => {
							startNewSession();
						}}
					/>
				</Label>
			)}
		</div>
	);
};
