import type { CourseOfferred } from '../../types';
import type { StateCreator } from 'zustand';
import { mockCourseList } from '../mockData';

const useMockData = import.meta.env.VITE_MOCK_APIS === 'true';
export interface CoursesSlice {
	courseList: CourseOfferred[];
}

export const createCoursesSlice: StateCreator<CoursesSlice, [], [], CoursesSlice> = (set, get) => ({
	courseList: useMockData ? mockCourseList() : [],
});
