import React, { type CSSProperties, Fragment, useState } from 'react';
import { Combobox } from '@headlessui/react';
import styles from './index.module.css';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { useTranslation } from 'react-i18next';
import { Float } from '@headlessui-float/react';

export interface DropdownOption {
	id: string;
	value: string;
}

export interface DropdownProps {
	options: DropdownOption[];
	style?: CSSProperties;
	onValueSelected?: (selectedOption: DropdownOption) => void;
}

export const DropDown: React.FC<DropdownProps> = (props: DropdownProps) => {
	const [selectedOption, setSelectedOption] = useState<DropdownOption>(props.options[0]);
	const [query, setQuery] = useState('');
	const { t } = useTranslation();
	const filteredOptions =
		query === ''
			? props.options
			: props.options.filter((option) => {
					return option.value.toLowerCase().includes(query.toLowerCase());
				});

	return (
		<Combobox
			as="div"
			className={styles.ComboBox}
			value={selectedOption}
			onChange={(value) => {
				setSelectedOption(value);
				if (typeof props.onValueSelected !== 'undefined') {
					props.onValueSelected(value);
				}
			}}
			style={props.style ?? {}}
		>
			<Float
				as="div"
				flip={0}
				offset={4}
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				floatingAs={Fragment}
				adaptiveWidth
				onHide={() => {
					setQuery('');
				}}
			>
				<div className={styles.inputContainer}>
					<Combobox.Input
						className={styles.input}
						onChange={(event) => {
							setQuery(event.target.value);
						}}
					/>
					<Combobox.Button className={styles.dropdownIcon}>
						<ArrowDownIcon fillColor={'var(--dropdown-input-text-color)'} />
					</Combobox.Button>
				</div>

				<Combobox.Options className={styles.optionsContainer}>
					{filteredOptions.length === 0 && query !== '' ? (
						<div className={styles.optionItem}>{t('nothing_found')}</div>
					) : (
						filteredOptions.map((option) => (
							<Combobox.Option
								className={styles.optionItem}
								key={option.id}
								value={option}
							>
								{option.value}
							</Combobox.Option>
						))
					)}
				</Combobox.Options>
			</Float>
		</Combobox>
	);
};
