import React, { useState } from 'react';
import styles from './index.module.css';

import KeyboardReturnIcon from '../../../../assets/keyboard_return.svg?react';
import { ButtonType, ButtonWithIcons } from '../../ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import {
	type ChatMessage,
	ChatSource,
	type SolutionChoice,
	type UserAvailableActions,
} from '../../../../types';
import { ProfileLogo } from '../../Icons/ProfileLogo';
import { CopyIcon } from '../../Icons/CopyIcon';
import { RedoIcon } from '../../Icons/RedoIcon';
import { DissatisfiedIcon } from '../../Icons/DissatisfiedIcon';

export interface ChatEntryProps {
	message: ChatMessage;
	onActionClicked?: (messageId: string, action: UserAvailableActions) => void;
	onCopyMessage?: (messageId: string) => void;
	onRedoClicked?: (messageId: string) => void;
	onFeedbackClicked?: (messageId: string) => void;
	onChoiceClicked?: (messageId: string, choice: SolutionChoice) => void;
	askUserInput?: boolean;
	userInputPlaceholder?: string;
	onUserInput?: (messageId: string, input: string) => void;
	showShortcuts?: boolean;
	choiceLabelClassName?: string;
}

export const ChatEntry: React.FC<ChatEntryProps> = (props: ChatEntryProps) => {
	const { t } = useTranslation();

	const askUserInput = props.askUserInput ?? false;
	const thinking = props.message.thinking ?? false;
	const showShortcuts = props.showShortcuts ?? false;
	const showChoices = props.message.choices !== null;
	const hideChoices = props.message.hideChoices ?? false;
	const choicesTitle = props.message.choicesTitle ?? undefined;
	const hasActions = props.message.actions !== undefined;

	const [choicesHidden, setChoicesHidden] = useState(hideChoices);

	const inputPlaceHolder =
		props.userInputPlaceholder != null
			? t(props.userInputPlaceholder)
			: t('answer_placeholder');

	const renderChoices = (): React.ReactNode => {
		return (
			<>
				<div className={styles.chatEntryColumn}>
					{(!choicesHidden || choicesTitle === undefined) && (
						<>
							{props.message.choices?.map((choice, index) => {
								return (
									<ButtonWithIcons
										labelClassName={props.choiceLabelClassName}
										key={`choice${index}`}
										text={`${choice.option}. ${choice.choice}`}
										type={ButtonType.tertiary}
										onClick={() =>
											props.onChoiceClicked?.(props.message.messageId, choice)
										}
									/>
								);
							})}
						</>
					)}
				</div>
				{choicesTitle !== undefined && (
					<div className={styles.chatEntryRow}>
						<ButtonWithIcons
							text={choicesTitle}
							type={ButtonType.textOnly}
							onClick={() => {
								setChoicesHidden(false);
							}}
						/>
					</div>
				)}
			</>
		);
	};

	const renderActions = (): React.ReactNode => {
		return (
			<div className={styles.chatEntryRow}>
				{props.message.actions?.map((action, index) => {
					return (
						<ButtonWithIcons
							key={`action${index}`}
							text={action.title}
							type={action.type}
							labelClassName={props.choiceLabelClassName}
							onClick={() => {
								props.onActionClicked?.(props.message.messageId, action);
							}}
						/>
					);
				})}
			</div>
		);
	};

	const textColor =
		props.message.messageSource === ChatSource.Assistant
			? '--text-color-chat-bot-bubble'
			: '--text-color-chat-user-bubble';

	return (
		<div className={styles.wrapper}>
			<div
				className={`${styles.chatEntryContainer} ${
					props.message.messageSource === ChatSource.Assistant
						? styles.assistant
						: styles.me
				}`}
			>
				<div className={styles.chatEntryRow}>
					<ProfileLogo
						className={styles.accountCircleIcon}
						fillColor={`var(${textColor}`}
					/>
					<b
						className={`${styles.messageSource} ${
							props.message.messageSource === ChatSource.Assistant
								? styles.textBot
								: styles.textUser
						}`}
					>
						{props.message.messageSource === ChatSource.Assistant
							? t('prof_ai')
							: t('you')}
					</b>
					{/* this is empty space as in figma */}
					<div className={styles.accountCircleIcon} />
				</div>

				{
					<div className={styles.chatEntryRow}>
						<span
							className={`${styles.message}  ${
								props.message.messageSource === ChatSource.Assistant
									? styles.textBot
									: styles.textUser
							}`}
						>
							{props.message.message}
						</span>
					</div>
				}

				{askUserInput && (
					<div className={styles.userInputParent}>
						<input
							type="text"
							onKeyDown={(event) => {}}
							onChange={(event) => {}}
							className={styles.userInput}
							placeholder={inputPlaceHolder}
							value={props.message.userInput ?? ''}
						/>

						<KeyboardReturnIcon className={styles.accountCircleIcon} />
					</div>
				)}

				{thinking && (
					<div className={styles.loadingBarContainer}>
						<div className={`${styles.loadingBar}`}>
							<div
								className={`${styles.loadingBarThumb} ${styles.indeterminate}`}
							></div>
						</div>
					</div>
				)}
				{showShortcuts && (
					<div className={styles.chatEntryRow}>
						<div className={styles.fillRemaining}>
							<CopyIcon
								className={styles.contentCopyIcon}
								fillColor={`var(${textColor}`}
								onClick={() => {
									props.onCopyMessage?.(props.message.messageId);
								}}
							/>
							<RedoIcon
								className={styles.contentCopyIcon}
								fillColor={`var(${textColor}`}
								onClick={() => {
									props.onRedoClicked?.(props.message.messageId);
								}}
							/>
						</div>
						<DissatisfiedIcon
							className={styles.contentCopyIcon}
							fillColor={`var(${textColor}`}
							onClick={() => {
								props.onFeedbackClicked?.(props.message.messageId);
							}}
						/>
					</div>
				)}
			</div>
			{showChoices && renderChoices()}
			{hasActions && renderActions()}
		</div>
	);
};
