import React, { useEffect, useState } from 'react';
import {
	type AnswerOptions,
	type ChatMessage,
	type ChatSession,
	ChatSource,
	type SolutionChoice,
	type UserProfile,
} from '../../../types';
import { SessionStartIntro } from '../SessionStartIntro';
import { ChatPane } from '../../Common/ChatPane';
import { useTranslation } from 'react-i18next';
import { SessionCompletePane } from '../SessionCompletedPane';
import { ButtonType } from '../../Common/ButtonWithIcons';
import { FormatString } from '../../../utils/strings';
import { findFirstUnansweredQuestion, findNextUnansweredQuestion } from '../../../store/quizHelper';

export interface ContinueSessionPaneProps {
	me: UserProfile;
	currentSession: ChatSession;
	newMessageInSession: (sessionId: string, userMessage: ChatMessage) => void;
	processQuizAnswer: (
		sessionId: string,
		quizIndex: number,
		choice: AnswerOptions | undefined,
		skipped?: boolean,
	) => void;
	profile: UserProfile;
	sessionComplete: () => void;
	redoSession: () => void;
}

export const ContinueSessionPane: React.FC<ContinueSessionPaneProps> = (
	props: ContinueSessionPaneProps,
): React.ReactNode => {
	const { t } = useTranslation();
	const [introShown, setIntroShown] = useState((props.currentSession.messages?.length ?? 0) > 0);
	const [quizStep, setQuizStep] = useState(-1);
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

	const setupQuiz = (): void => {
		const step = findFirstUnansweredQuestion(props.currentSession);
		setQuizStep(step);
	};

	const loadQuestionForStep = (): ChatMessage => {
		const messageId = (props.currentSession.messages ?? []).length + 1;
		const question: ChatMessage = {
			messageId: `${messageId}`,
			messageSource: ChatSource.Assistant,
			message: '',
		};
		if (props.currentSession.quizQuestions !== undefined) {
			const quizQuestionDb = props.currentSession.quizQuestions[quizStep];
			question.message = quizQuestionDb.question;
			if (quizQuestionDb.choices !== undefined) {
				question.choices = quizQuestionDb.choices;
			}

			// question.actions = [
			// 	{
			// 		type: ButtonType.tertiary,
			// 		title: t('skip_this_one')
			// 	}
			// ];
		}
		return question;
	};

	const loadFirstMessageForProfAi = (): void => {
		const initialProfAiMessage = FormatString(
			t('first_question_prof_ai'),
			props.me.nickname ?? '',
		);
		const userMessage: ChatMessage = {
			messageId: '1',
			messageSource: ChatSource.Assistant,
			message: initialProfAiMessage,
		};
		props.newMessageInSession(props.currentSession.sessionId, userMessage);
	};

	useEffect(() => {
		if (introShown) {
			if (props.currentSession.sessionType === 'quiz') {
				setupQuiz();
			} else if (props.currentSession.sessionType === 'ask_profai') {
				loadFirstMessageForProfAi();
			}
		}
	}, [introShown]);

	useEffect(() => {
		if (quizStep !== -1) {
			const question = loadQuestionForStep();
			props.newMessageInSession(props.currentSession.sessionId, question);
			setChatHistory([question]);
		}
	}, [quizStep]);

	const renderIntroScreen = (): React.ReactNode => {
		return (
			<SessionStartIntro
				currentSession={props.currentSession}
				onClick={() => {
					setIntroShown(true);
				}}
			/>
		);
	};

	const renderSessionComplete = (): React.ReactNode => {
		return (
			<SessionCompletePane
				currentSession={props.currentSession}
				onIAmDone={() => {
					props.sessionComplete();
				}}
				onLetsDoItAgain={() => {
					props.redoSession();
				}}
			/>
		);
	};

	const renderCorrectAnswer = (choice: SolutionChoice): void => {
		const messageId = (props.currentSession.messages ?? []).length + 1;
		const question: ChatMessage = {
			messageId: `${messageId}`,
			messageSource: ChatSource.Assistant,
			message: '',
		};
		if (props.currentSession.quizQuestions !== undefined) {
			const quizQuestionDb = props.currentSession.quizQuestions[quizStep];
			if (quizQuestionDb.answer?.toLowerCase() === choice.option?.toLowerCase()) {
				question.message = FormatString(
					t('correct_answer_description'),
					quizQuestionDb.explanation ?? '',
				);
			} else {
				const correctAnswerChoice = quizQuestionDb.choices?.find(
					(choice) => choice.option === quizQuestionDb.answer,
				);
				const correctAnswer =
					correctAnswerChoice !== undefined ? correctAnswerChoice.choice : choice.choice;
				question.message = FormatString(
					t('wrong_answer_description'),
					choice.choice,
					correctAnswer,
					quizQuestionDb.explanation ?? '',
				);
			}

			question.actions = [
				{
					type: ButtonType.tertiary,
					title: t('lets_move_on'),
				},
			];
		}

		props.newMessageInSession(props.currentSession.sessionId, question);
		setChatHistory([question]);
	};

	const processUserInput = (input: string): void => {
		const messageId = (props.currentSession.messages ?? []).length + 1;
		const userMessage: ChatMessage = {
			messageId: `${messageId}`,
			messageSource: ChatSource.User,
			message: input,
		};
		props.newMessageInSession(props.currentSession.sessionId, userMessage);
	};

	const renderChatScreen = (): React.ReactNode => {
		const chatMessages =
			props.currentSession.sessionType === 'quiz'
				? chatHistory
				: props.currentSession.messages ?? [];
		return (
			<ChatPane
				hideInput={props.currentSession.sessionType === 'quiz'}
				messages={chatMessages}
				panePerInteraction={props.currentSession.sessionType === 'quiz'}
				onActionClicked={(messageId, action) => {
					if (props.currentSession.sessionType === 'quiz') {
						// Since there is only one option for now which is to skip. We should not process this in model
						if (action.title === t('lets_move_on')) {
							setQuizStep(findNextUnansweredQuestion(props.currentSession, quizStep));
						}
					}
				}}
				onChoiceClicked={(messageId, choice) => {
					if (props.currentSession.sessionType === 'quiz') {
						props.processQuizAnswer(
							props.currentSession.sessionId,
							quizStep,
							choice.option,
							false,
						);

						renderCorrectAnswer(choice);
					}
				}}
				onChatUserInput={(input) => {
					processUserInput(input);
				}}
			/>
		);
	};

	return (
		<>
			{!introShown && <>{renderIntroScreen()}</>}
			{introShown && (
				<>
					{(props.currentSession.sessionType === 'ask_profai' ||
						(props.currentSession.sessionType === 'quiz' &&
							props.currentSession.quizProgress?.complete !==
								props.currentSession.quizProgress?.total)) && (
						<>{renderChatScreen()}</>
					)}
					{props.currentSession.sessionType === 'quiz' &&
						props.currentSession.quizProgress?.complete ===
							props.currentSession.quizProgress?.total && (
							<>{renderSessionComplete()}</>
						)}
				</>
			)}
		</>
	);
};