import React from 'react';
import styles from './index.module.css';
import { ContentHeader } from '../../../Common/ContentHeader';
import { useTranslation } from 'react-i18next';

export interface WelcomeBackProps {
	onClick?: () => void;
}

export const WelcomeBack: React.FC<WelcomeBackProps> = (props: WelcomeBackProps) => {
	const { t } = useTranslation();
	const message = (): React.ReactNode => {
		return (
			<>
				I’m designed to help you get the most out of your Section coursework. Remember, I’m
				currently in beta and{' '}
				<span className={styles.clickableSpan} onClick={() => props.onClick?.()}>
					I’m reviewing our chats
				</span>{' '}
				to improve my performance, so don’t enter any sensitive info to our conversations.
				Choose a course below to get started.
			</>
		);
	};

	return <ContentHeader title={t('Welcome back to Section’s ProfAI')} message={message()} />;
};
