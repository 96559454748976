import { type ButtonType } from '../components/Common/ButtonWithIcons';


export type SkillLevel = 'beginner' | 'intermediate' | 'advanced';

export type AnswerOptions = 'A' | 'a' | 'b' | 'B' | 'c' | 'C' | 'd' | 'D' | undefined;

export interface SolutionChoice {
	option: AnswerOptions;
	choice: string;
}

export interface QuizQuestion {
	question: string;
	choices?: SolutionChoice[];
	answer: AnswerOptions;
	explanation?: string;
}

export enum ChatSource {
	Assistant,
	User,
	Feedback
}
export interface UserAvailableActions {
	title: string;
	type: ButtonType;
}

export interface ChatMessage {
	messageId: string;
	messageSource: ChatSource;
	message?: string;
	choices?: SolutionChoice[];
	choiceClicked?: number;
	userInput?: string;
	thinking?: boolean;
	hideChoices?: boolean;
	choicesTitle?: string;
	actions?: UserAvailableActions[];
}

export interface InSessionQuizSummaryEntry  {
	userProvidedAnswer: AnswerOptions | string | undefined;
	correct?: boolean | undefined;
	skipped: boolean;
	
}

export interface QuizProgress {
	complete: number;
	correct: number;
	total: number;
	maxSeen: number;
}
export type SessionType = 'ask_profai' | 'quiz' | 'project_assistant';

export interface ChatSession {
	sessionId: string;
	date: string;
	title?: string;
	sessionTitle: string;
	description?: string;
	
	course?: CourseOfferred;
	sessionType: SessionType;
	messages?: ChatMessage[];
	quizQuestions?: QuizQuestion[];
	quizProgress?: QuizProgress;
	quizSummary?: InSessionQuizSummaryEntry[];
}

export interface CourseSuggestion {
	suggestion: string;
}

export interface ConvSuggestions {
	suggestion: string;
}

export type CourseConvSuggestions = Record<string, ConvSuggestions[]>;


export interface CourseOfferred {
	id: string;
	title: string;
	str_id: string;
	description: string;
	assistantId: string;
}

export type ProfileElementType = 'nickname' | 'title' | 'employmentStatus' | 'communicationStyle';


export interface UserProfile {
	name?: string;
	nickname?: string;
	title?: string;
	employmentStatus?: string;
	communicationStyle?: string;
}

