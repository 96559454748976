import { type ChatSession, type QuizQuestion, type SkillLevel } from '../types';

function createQuiz(
	beginner: QuizQuestion[],
	intermediate: QuizQuestion[],
	advanced: QuizQuestion[],
	studentLevel: SkillLevel,
): QuizQuestion[] {
	const levelArrays: Record<string, QuizQuestion[]> = { beginner, intermediate, advanced };
	const levels = ['beginner', 'intermediate', 'advanced'];
	const studentLevelIndex = levels.indexOf(studentLevel);

	// Calculate the indices for the levels above and below the student's level
	const lowerLevel = levels[Math.max(studentLevelIndex - 1, 0)];
	const upperLevel = levels[Math.min(studentLevelIndex + 1, 2)];
	const otherLevel = levels[2 - studentLevelIndex - (studentLevelIndex === 1 ? 1 : 0)];

	// Function to randomly select n questions from an array
	const selectRandom = (arr: QuizQuestion[], n: number): QuizQuestion[] =>
		arr.sort(() => 0.5 - Math.random()).slice(0, n);

	// Select questions
	const selectedQuestions = [
		...selectRandom(levelArrays[studentLevel], 5), // 50% from student's level
		...selectRandom(levelArrays[studentLevel === 'intermediate' ? lowerLevel : upperLevel], 3), // 30% from adjacent level
		...selectRandom(levelArrays[otherLevel], 2), // 20% from the remaining level
	];

	return selectedQuestions;
}

export async function createQuizOfType(
	topic: string,
	studentLevel: SkillLevel,
): Promise<QuizQuestion[]> {
	const beginner: QuizQuestion[] = (await import(`../assets/quiz/${topic}/beginner.json`))
		.default;
	const intermediate: QuizQuestion[] = (await import(`../assets/quiz/${topic}/intermediate.json`))
		.default;
	const advanced: QuizQuestion[] = (await import(`../assets/quiz/${topic}/advance.json`)).default;
	return createQuiz(beginner, intermediate, advanced, studentLevel);
}

export const findFirstUnansweredQuestion = (session: ChatSession): number => {
	if (session.quizProgress?.complete === session.quizQuestions?.length) {
		return -1;
	}
	const result = session.quizSummary?.findIndex(
		(quizSummaryEntry) =>
			quizSummaryEntry.skipped || quizSummaryEntry.userProvidedAnswer === undefined,
	);
	return result ?? -1;
};

export const findNextUnansweredQuestion = (session: ChatSession, currentStep: number): number => {
	if (
		session.quizQuestions === undefined ||
		session.quizSummary === undefined ||
		session.quizProgress?.complete === session.quizQuestions?.length
	) {
		return -1;
	}
	if (currentStep === session.quizQuestions.length - 1) {
		return findFirstUnansweredQuestion(session);
	}
	let index = currentStep;
	while (index < session.quizQuestions.length) {
		const quizSummaryEntry = session.quizSummary[index];
		if (quizSummaryEntry.skipped || quizSummaryEntry.userProvidedAnswer === undefined) {
			return index;
		}
		index++;
	}
	return -1;
};
