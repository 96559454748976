import React, { useEffect, useState } from 'react';
import Label from '../../Common/LabelText/LabelText';
import { ButtonType, ButtonWithIcons } from '../../Common/ButtonWithIcons';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../../Common/ContentHeader';
import styles from './index.module.css';
import { FormatString } from '../../../utils/strings';
import { type ChatSession } from '../../../types';

export interface SessionStartIntroProps {
	currentSession: ChatSession;
	onClick?: () => void;
}

export const SessionStartIntro: React.FC<SessionStartIntroProps> = (props: SessionStartIntroProps) => {
	const { t } = useTranslation();
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const [buttonTitle, setButtonTitle] = useState('');
	
	useEffect(() => {
		if (props.currentSession.sessionType === 'ask_profai'){
			setTitle(FormatString(t('ask_prof_ai_intro_title'), props.currentSession.course?.title ?? ''));
			setMessage(t('ask_prof_ai_intro_message'));
			setButtonTitle(t('lets_chat'))
		} else if (props.currentSession.sessionType === 'quiz'){
			setTitle(FormatString(t('quiz_title'), props.currentSession.course?.title ?? ''))
			setMessage(t('quiz_message'));
			setButtonTitle(t('lets_do_it'));
		}
	}, []);
	return (
		<div className={styles.createProfileContainer}>
			<ContentHeader title={title} message={message}>
				<Label style={{ maxWidth: '850px', width: '100%' }}>
					<ButtonWithIcons
						type={ButtonType.primary}
						text={buttonTitle}
						onClick={() => {
							props.onClick?.();
						}}
					/>
				</Label>
			</ContentHeader>
		</div>
	);
};
