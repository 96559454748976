import { LOGIN_ROUTE } from '../config/config';

export interface BuildRouteType {
	route: string
	slugs?: Record<string, string>
	params?: Record<string, string>
}
export const addParamsToRoute = (route: string, params: Record<string, string> = {}): string => {
	const hasQuestion = route.includes('?')

	return Object.keys(params).reduce((acc, key, index) => (
		params[key] !== undefined && params[key] !== 'undefined'
			? `${acc}${hasQuestion || index > 0 ? '&' : '?'}${key}=${params[key]}`
			: acc
	), route)
}

export const addSlugsToRoute = (route: string, slugs: Record<string, string> = {}): string => (
	Object.keys(slugs).reduce((acc, key) => (
		acc.replace(`:${key}`, slugs[key])
	), route)
)
export const buildRoute = ({ route, slugs, params }: BuildRouteType): string => (
	addParamsToRoute(addSlugsToRoute(route, slugs), params)
)

export const buildLoginRedirectRoute = (fromPath: string): string => {
	// On the login page, keep the current search params (redirectPath/redirectUrl/...).
	//
	// If you're wondering how fromPath could be login, since users don't log out
	// from the login page: If auth token cookies are in an unusable state, it's
	// possible for authentication to be attempted and fail, and the code to log
	// out (i.e., clear cookies and redirect) gets called.
	//
	// It's silly to redirect to the current path, but things break if we don't,
	// probably due to the timing of authentication and data loading, and this should
	// be an edge case (auth token cookies in an invalid state) anyway.
	if (fromPath.indexOf(LOGIN_ROUTE) === 0) {
		return fromPath
	}

	return buildRoute({
		route: LOGIN_ROUTE,
		params: { redirectUrl: encodeURIComponent(fromPath) },
	})
}
