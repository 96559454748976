import type { UserProfile } from '../../types';
import { type StateCreator } from 'zustand';
import { wait } from '../../utils/process';


export interface UserSlice {
	me: UserProfile;
	setMe: (profile: UserProfile) => Promise<void>;
	bootstrapApp: () => Promise<void>;
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set, get) => ({
	me: { name: '' },
	setMe: async (profile: UserProfile): Promise<void> => {
		const newProfile = { ...profile };
		set({ me: newProfile });
		console.error('setMe called');
		// Send the request to the network
	},
	bootstrapApp: async (): Promise<void> => {
		set({
			me: {
				...get().me,
				name: 'Edmundo',
			},
		});
		await wait(1000);
	},
});