import { TrackJS } from 'trackjs'
import { IS_DEV } from '../config/config';

/* eslint-disable no-console */

const reportError: typeof console.error = (...args) => {
	if (IS_DEV) {
		console.error(...args)
	} else {
		TrackJS.track(args)
	}
}

export default {
	error: reportError,
	log: console.log,
}
