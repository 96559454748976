import React from 'react';
import { type IconProps } from './types';

export const SectionTitle: React.FC<IconProps> = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="139"
			height="60"
			viewBox="0 0 139 60"
			fill="none"
			className={props.className ?? ''}
			style={{ zoom: '1' }}
		>
			<g id="Section-Logo">
				<path
					d="M13.027599,31.4694 C10.468601,30.8074 9.4067,30.509501 9.4067,29.317699 C9.4067,28.3578 10.203602,27.7619 11.698898,27.7619 C13.360802,27.7619 14.3564,28.489799 14.3564,30.2115 H18.808201 C18.808201,26.4701 16.1507,24.518299 11.731098,24.518299 C7.544201,24.518299 4.754299,26.67 4.754299,29.681601 C4.754299,33.587101 7.777,34.249001 10.603001,34.978802 C13.228298,35.674702 14.4907,35.972599 14.4907,37.428501 C14.4907,38.422298 13.659801,39.050301 12.032001,39.050301 C10.404202,39.050301 9.3708,38.333698 9.132301,37.0494 C9.064098,36.689201 8.793499,36.402599 8.4263,36.402599 H5.113899 C4.703098,36.402599 4.353001,36.755299 4.3927,37.162601 C4.737202,40.713501 7.5499,42.393799 11.933601,42.393799 C16.317299,42.393799 19.375999,40.276001 19.375999,37.130501 C19.375999,33.025101 16.054199,32.263302 13.029499,31.4694 H13.027599 "
					id="Vector_2"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M36.274696,30.113501 C35.210899,26.703899 32.354698,24.518299 28.4669,24.518299 C23.1842,24.518299 20.0933,28.3578 20.0933,33.323101 C20.0933,38.686298 23.1842,42.393799 28.4991,42.393799 C32.483398,42.393799 35.237396,40.381599 36.267097,37.773602 C36.458298,37.292702 36.132698,36.764702 35.614098,36.764702 H32.360401 C32.0424,36.764702 31.794498,36.985298 31.679001,37.281399 C31.255001,38.3657 30.115601,39.114399 28.4991,39.114399 C26.506001,39.114399 24.810101,37.890499 24.677601,34.413101 H36.804703 C36.804703,32.559399 36.6381,31.267599 36.272797,30.109699 L36.274696,30.113501 M24.777901,31.5033 C25.1432,28.953699 26.5723,27.795799 28.465099,27.795799 C30.3578,27.795799 31.855,28.953699 32.154099,31.5033 H24.777901 "
					id="Vector_3"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M46.331001,28.0938 C48.125397,28.0938 49.321602,29.319599 49.488197,31.3053 H54.339401 C53.974098,27.3659 50.917198,24.518299 46.365097,24.518299 C41.214798,24.518299 37.858902,28.0278 37.858902,33.457001 C37.858902,38.8862 41.214798,42.395699 46.365097,42.395699 C50.621902,42.395699 53.5709,39.906399 54.235298,36.091499 C54.309097,35.667099 53.953201,35.276798 53.521698,35.276798 H50.084396 C49.7248,35.276798 49.444603,35.552101 49.386002,35.9048 C49.0793,37.718899 47.949303,38.820202 46.332901,38.820202 C44.072899,38.820202 42.711998,36.768501 42.711998,33.457001 C42.711998,30.1455 44.074799,28.0938 46.332901,28.0938 H46.331001 "
					id="Vector_4"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M61.893799,20.678801 H57.241302 V25.048201 H54.517601 V28.491699 H57.241302 V41.1567 C57.241302,41.549 57.559303,41.865799 57.953003,41.865799 H61.180199 C61.573898,41.865799 61.891899,41.549 61.891899,41.1567 V28.491699 H65.115303 V25.048201 H61.891899 V20.678801 H61.893799 "
					id="Vector_5"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M69.602997,17.995399 H68.569298 C67.376602,17.995399 66.409698,18.9587 66.409698,20.1471 V20.414801 C66.409698,21.603201 67.376602,22.5665 68.569298,22.5665 H69.602997 C70.795998,22.5665 71.762001,21.603201 71.762001,20.414801 V20.1471 C71.762001,18.9587 70.795998,17.995399 69.602997,17.995399 "
					id="Vector_6"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M66.759697,41.1549 C66.759697,41.5471 67.077698,41.863899 67.471397,41.863899 H70.698997 C71.092003,41.863899 71.410004,41.5471 71.410004,41.1549 V25.0464 H66.757797 V41.1549 H66.759697 "
					id="Vector_7"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M81.657997,24.518299 C76.375,24.518299 73.019997,27.993799 73.019997,33.457001 C73.019997,38.920101 76.375,42.395699 81.657997,42.395699 C86.941002,42.395699 90.296997,38.920101 90.296997,33.457001 C90.296997,27.993799 86.941002,24.518299 81.657997,24.518299 M81.657997,38.820202 C79.365997,38.820202 77.903,36.734501 77.903,33.457001 C77.903,30.179501 79.363998,28.0938 81.657997,28.0938 C83.952003,28.0938 85.413002,30.179501 85.413002,33.457001 C85.413002,36.734501 83.952003,38.820202 81.657997,38.820202 "
					id="Vector_8"
					fill={props.fillColor ?? '#0A070D'}
				></path>
				<path
					d="M101.330002,24.576799 C99.169998,24.576799 97.475998,25.676201 96.377998,27.166 H96.311996 V25.048201 H91.958 V41.1567 C91.958,41.549 92.276001,41.865799 92.669998,41.865799 H95.897003 C96.291,41.865799 96.609001,41.549 96.609001,41.1567 V32.861099 C96.609001,29.8155 98.003998,28.293699 99.931,28.293699 C101.759003,28.293699 102.886993,29.451599 102.886993,32.001202 V41.1567 C102.886993,41.549 103.205002,41.865799 103.598999,41.865799 H106.826004 C107.220001,41.865799 107.537994,41.549 107.537994,41.1567 V31.5373 C107.537994,26.968 105.113007,24.578699 101.324005,24.578699 L101.330002,24.576799 "
					id="Vector_9"
					fill={props.fillColor ?? '#0A070D'}
				></path>
			</g>
		</svg>
	);
};
